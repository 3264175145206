import React, { useState } from 'react';
import { AppState } from '../reducers/combineReducer';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserData } from '../reducers/userReducer';
import { Item } from './Item';
import allActions from '../actions/allActions';
import { PasswordChangeObject } from '../services/user';
import { PasswordChangeForm } from './PasswordChangeForm';
import Loading from '../components/utils/Loading';
import { selectLoading } from '../reducers/loadingReducer';
import ErrorFetching from '../components/modals/ErrorFetching';
import { getErrorMessage } from '../reducers/errorReducer';
import { useNotify } from '../hooks/useNotify';

export const UserData: React.FC = () => {
  const {
    userEmail,
    userFirstName,
    userLastName,
    userType,
  } = useSelector((state: AppState) => selectUserData(state));

  const isLoading = useSelector((state: AppState) =>
    selectLoading(state, 'changePassword')
  );

  const [changePasswordVisible, setChangePasswordVisible] = useState(false);

  const { message } = useSelector((state: AppState) => getErrorMessage(state));

  const errorMsg = useNotify(message);

  const dispatch = useDispatch();

  const save = (data: PasswordChangeObject) => {
    dispatch(allActions.userActions.startPasswordChange(data));
    setChangePasswordVisible(false);
  };

  return (
    <div className="flex flex-col h-full">
      <Item
        header={'account.user.name'}
        data={`${userFirstName} ${userLastName}`}
      />
      <Item header={'account.user.email'} data={userEmail} />
      <Item header={'account.user.type'} data={userType} />
      {isLoading ? (
        <Loading styles={'items-center my-auto'} />
      ) : (
        <PasswordChangeForm
          formVisible={changePasswordVisible}
          toggleFormVisible={() =>
            setChangePasswordVisible(!changePasswordVisible)
          }
          save={save}
        />
      )}
      <ErrorFetching
        styles={
          'absolute z-10 border shadow-xl bg-brand-white p-20 top-1/2 rounded'
        }
        message={errorMsg}
      />
    </div>
  );
};
