import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  summary: string | null;
  currentPage: number;
  maxPage: number;
};
export const SummaryDiv: React.FC<Props> = ({
  summary,
  currentPage,
  maxPage,
}) => {
  return summary !== null && currentPage === maxPage ? (
    <div className="text-center m-4  font-semibold text-lg">
      <span className="text-brand-blue m-2">
        <FormattedMessage id="form.product.summary" />
      </span>
      <div>
        <span className="text-brand-dark_blue">{summary}€</span>
        <br></br>
        <span className="italic text-brand-dark_blue normal-case">
          <FormattedMessage id="form.product.tax" />
        </span>
      </div>
    </div>
  ) : null;
};
