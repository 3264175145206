import React from 'react';
import { AppState } from '../reducers/combineReducer';
import { useSelector } from 'react-redux';
import { selectCustomerData } from '../reducers/userReducer';
import { Item } from './Item';

export const CustomerData: React.FC = () => {
  const {
    customerBusinessId,
    customerBillingAddressCity,
    customerBillingAddressCountry,
    customerBillingAddressStreet,
    customerBillingOvt,
    customerBillingAddressZip,
    customerBillingOvtCarrier,
    customerContactPersonName,
    customerContactPersonEmail,
    customerContactPersonPhoneNumber,
    customerName,
  } = useSelector((state: AppState) => selectCustomerData(state));

  return (
    <div className="flex flex-col">
      <Item header={'account.customer.businessid'} data={customerBusinessId} />
      <Item header={'account.customer.name'} data={customerName} />
      <Item
        header={'account.customer.contactperson'}
        data={customerContactPersonName}
      />
      <Item
        header={'account.customer.email'}
        data={customerContactPersonEmail}
      />
      <Item
        header={'account.customer.phone'}
        data={customerContactPersonPhoneNumber}
      />
      <Item
        header={'account.customer.address'}
        data={customerBillingAddressStreet}
      />
      <Item header={'account.customer.zip'} data={customerBillingAddressZip} />
      <Item
        header={'account.customer.city'}
        data={customerBillingAddressCity}
      />
      <Item
        header={'account.customer.country'}
        data={customerBillingAddressCountry}
      />
      <Item header={'account.customer.ovt'} data={customerBillingOvt} />
      <Item
        header={'account.customer.ovtoperator'}
        data={customerBillingOvtCarrier}
      />
    </div>
  );
};
