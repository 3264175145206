import React from 'react';
import { FormattedMessage } from 'react-intl';
import Paragraph from '../../components/utils/Paragrahp';
import { FormattedMessageProps } from '../../types/types';

type Props = {
  onClick: Array<() => void>;
  header: FormattedMessageProps;
  paragraph: FormattedMessageProps;
  buttonText: Array<FormattedMessageProps>;
  disabled: boolean;
};

export const PreviewCard: React.FC<Props> = ({
  onClick,
  header,
  paragraph,
  buttonText,
  disabled,
}) => {
  const buttonColor = disabled
    ? 'bg-gray-500 cursor-default'
    : 'bg-blue-400 hover:bg-brand_blue';

  const lineBreak = { lineBreak: <br /> };

  return (
    <div className="rounded w-full flex flex-col justify-center m-4 text-center">
      <Paragraph message={header} header={true} />
      <Paragraph message={paragraph} header={false} values={lineBreak} />
      <div className="flex">
        {onClick.map((fn, i) => (
          <button
            key={`button_${i}`}
            className={`m-4 p-4 mx-auto rounded text-white font-thin ${buttonColor}`}
            onClick={fn}
            disabled={disabled}
          >
            <FormattedMessage {...buttonText[i]} />
          </button>
        ))}
      </div>
    </div>
  );
};
