import React from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormInputInfo } from '../../../../types/types';
import FormInput from '../../../../components/form/FormInput';
import { FormSelect } from '../../../../components/form/FormSelect';
import { messages } from '../../translations/dependencyModalTranslations';
import { extractId } from '../../../../utils/functions';

interface DependencyModalProps {
  styles?: string;
  modalId: string;
  item: FormInputInfo;
  options: { id: string; name: string }[];
  amountOptions: { id: string; name: string }[];
  modalAction: (item: FormInputInfo, modalId: string) => void;
  modalCancel: (modalId: string) => void;
}

export const DependencyModal: React.FC<DependencyModalProps> = ({
  styles,
  modalId,
  item,
  options,
  amountOptions,
  modalAction,
  modalCancel,
}) => {
  const [state, setState] = useState({
    ...item,
    repeatable: item.repeatable
      ? {
          key: findValue(item.repeatable.key),
          after: findValue(item.repeatable.after),
          value: item.repeatable.value,
          amount: findValue(item.repeatable.amount) || item.repeatable.amount,
          header: item.repeatable.header,
        }
      : {
          key: '',
          after: '',
          amount: '',
          value: '',
          header: '',
        },
  });
  const style =
    styles !== undefined ? styles : 'text-center shadow-xl overflow-y scroller';
  const optionsName = ['', '*'].concat(
    options.map((item) => `${item.name} :: ${item.id}`)
  );
  const amountOptionsName: string[] = [''].concat(
    amountOptions.map((item) => `${item.name} :: ${item.id}`)
  );

  const { formatMessage } = useIntl();

  const localInputChange = (e: any) => {
    setState({
      ...state,
      repeatable: {
        ...state.repeatable,
        [e.target.id]: e.target.value,
      },
    });
  };

  function findValue(key: string) {
    if (key === '*') {
      return key;
    }
    const item = options.find((item) => item.id === key);
    return item !== undefined ? `${item.name} :: ${item.id}` : '';
  }

  function findId(key: string) {
    if (key === '*') {
      return key;
    }
    const id = extractId(key);
    const item = options.find((item) => item.id === id);
    return item !== undefined ? item.id : '';
  }

  return (
    <div className={style}>
      <div className="flex flex-col">
        <div className="flex flex-col">
          <p className=" font-semibold m-4 text-brand-dark_blue">
            <FormattedMessage {...messages.header} />
          </p>
          <p className="m-4 text-brand-dark_blue">
            <FormattedMessage {...messages.paragraph1} />
          </p>
          <p className="m-4 text-brand-dark_blue">
            <FormattedMessage {...messages.paragraph2} />
          </p>
          <FormSelect
            id="key"
            question={formatMessage({ ...messages.keyquestion })}
            options={optionsName}
            value={state.repeatable.key}
            handleInputChange={localInputChange}
            cursiveLabel={formatMessage({ ...messages.keydescription })}
          />
          <FormInput
            id="value"
            question={formatMessage({ ...messages.valuequestion })}
            value={state.repeatable.value}
            type="text"
            handleInputChange={localInputChange}
            cursiveLabel={formatMessage({ ...messages.valuedescription })}
          />
          <FormSelect
            id="amount"
            question={formatMessage({ ...messages.amountquestion })}
            options={amountOptionsName}
            value={state.repeatable.amount}
            handleInputChange={localInputChange}
            cursiveLabel={formatMessage({ ...messages.amountdescription })}
          />
          <FormInput
            id="header"
            question={formatMessage({ ...messages.repeatheaderquestion })}
            value={state.repeatable.header}
            type="text"
            handleInputChange={localInputChange}
            cursiveLabel={formatMessage({
              ...messages.repeatheaderdescription,
            })}
          />
        </div>
        <div className="flex flex-row justify-around">
          <div className="pb-6">
            <button
              onClick={() =>
                modalAction(
                  {
                    ...state,
                    repeatable: {
                      key: findId(state.repeatable.key),
                      value: state.repeatable.value,
                      amount: findId(state.repeatable.amount),
                      after: findId(state.repeatable.after),
                      header: state.repeatable.header,
                    },
                  },
                  modalId
                )
              }
              className="bg-brand-white hover:bg-blue-200 text-brand-blue m-4 rounded w-20 h-10"
            >
              <FormattedMessage {...messages.save} />
            </button>
          </div>
          <div className="pb-6">
            <button
              onClick={() =>
                modalAction(
                  {
                    ...state,
                    repeatable: {
                      key: '',
                      value: '',
                      amount: '',
                      after: '',
                      header: '',
                    },
                  },
                  modalId
                )
              }
              className="bg-red-300 hover:bg-red-400 text-brand-white m-4 rounded w-20 h-10"
            >
              <FormattedMessage {...messages.remove} />
            </button>
          </div>
          <div className="pb-6">
            <button
              onClick={() => modalCancel(modalId)}
              className="bg-brand-white hover:bg-blue-200 text-brand-blue m-4 rounded w-20 h-10"
            >
              <FormattedMessage {...messages.cancel} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DependencyModal;
