import React from 'react';
import { Control, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { HiOutlineCalendar, HiOutlineX } from 'react-icons/hi';
import { IconWrapper } from '../utils/IconWrapper';
import { RepeatableHeader } from './divs/RepatableHeader';
import { LabelContainer } from './labels/LabelContainer';
import { ErrorDiv } from './divs/ErrorDiv';
import { RepeatableButtons } from './buttons/RepeatableButtons';
import { HoverAttributes } from '../utils/HoverMessage';

interface Props {
  id: string;
  value?: Date | undefined | null;
  question?: string;
  containerStyle?: string;
  divider?: string;
  repeatable?: boolean;
  inputStyle?: string;
  divStyle?: string;
  labelStyle?: string;
  dividerHeaderStyle?: string;
  register?: any;
  errors?: Record<string, Object>;
  control: Control<Record<string, any>>;
  link?: string | boolean;
  cursiveLabel?: string;
  repeatableHeader?: string;
  repeatableKey?: { key: string; last: boolean; amount: number };
  required?: boolean;
  overlayProps?: HoverAttributes;
  reset: (id: string) => void;
  changeAmount?: (key: string, direction: number) => void;
  handleInputChange: (date: Date | [Date, Date], type?: string) => void;
}

interface InputProps {
  value: Date | undefined | null;
  onClick: any;
  reset: (id: string) => void;
}

export const FormDateDate: React.FC<Props> = ({
  id,
  handleInputChange,
  question,
  value,
  containerStyle,
  divider,
  repeatable,
  link,
  repeatableHeader,
  changeAmount,
  reset,
  labelStyle,
  divStyle,
  dividerHeaderStyle,
  register,
  errors,
  control,
  cursiveLabel,
  repeatableKey,
  inputStyle,
  overlayProps,
}) => {
  const styles = inputStyle
    ? inputStyle
    : 'flex h-10 w-64 bg-white border border-gray-300 rounded-lg relative';

  const divStyles = divStyle ? divStyle : 'flex flex-col mb-4';

  const participantId = repeatable ? id.split('_')[1] : ''; //expected id is 'BLABLABLA_2' => 2

  const dividerHeaderStyles = dividerHeaderStyle
    ? dividerHeaderStyle
    : 'm-2  font-bold text-lg text-brand-blue';

  const dividerStyles = divider ? divider : '';
  const containerStyles = containerStyle ? containerStyle : '';

  const ref = React.createRef<HTMLInputElement>();

  const repeatIndex = participantId ? participantId : 1;

  const InputWithIcons = React.forwardRef(
    (
      { value, onClick, reset }: InputProps,
      ref: React.Ref<HTMLInputElement>
    ) => (
      <div className={styles} ref={ref} onClick={onClick}>
        <IconWrapper styles="ml-2 mt-1">
          <HiOutlineCalendar size={30} />
        </IconWrapper>
        <span className="mt-2 ml-4 font-semibold text-brand-dark_blue">
          {value}
        </span>
        {value && (
          <IconWrapper styles="cursor-pointer ml-auto mt-2 hover:text-red-400">
            <HiOutlineX onClick={() => reset(id)} size={24} />
          </IconWrapper>
        )}
      </div>
    )
  );

  return (
    <div className={`${divStyles} ${containerStyles} ${dividerStyles}`}>
      <RepeatableHeader
        divider={divider}
        repeatable={repeatable}
        label={`${repeatableHeader} ${repeatIndex}`}
        style={dividerHeaderStyles}
      />
      <LabelContainer
        link={link}
        question={question}
        labelStyle={labelStyle}
        register={register}
        cursiveLabel={cursiveLabel}
        overlayProps={overlayProps}
      />
      <Controller
        name={id}
        control={control}
        rules={register}
        defaultValue={new Date()}
        render={({ name, onChange }) => (
          <DatePicker
            selected={value}
            dateFormat="dd.MM.yyyy"
            onFocus={(e) => e.target.blur()}
            name={name}
            onChange={(value) => {
              handleInputChange(value, name);
            }}
            customInput={
              <InputWithIcons
                value={value}
                onClick={onChange}
                reset={reset}
                ref={ref}
              />
            }
          />
        )}
      />
      <ErrorDiv errors={errors} id={id} />
      <RepeatableButtons
        repeatableKey={repeatableKey}
        changeAmount={changeAmount}
        repeatableHeader={repeatableHeader}
      />
    </div>
  );
};

export default FormDateDate;
