import React from 'react';
import { RepeatableButtons } from './buttons/RepeatableButtons';
import { ErrorDiv } from './divs/ErrorDiv';
import { LabelContainer } from './labels/LabelContainer';
import { RepeatableHeader } from './divs/RepatableHeader';
import { HoverAttributes } from '../utils/HoverMessage';

interface FormTextAreaProps {
  id: string;
  placeholder?: string;
  question: string;
  divider?: string;
  value?: string;
  inputStyle?: string;
  divStyle?: string;
  labelStyle?: string;
  dividerHeaderStyle?: string;
  repeatable?: boolean;
  containerStyle?: string;
  register?: any;
  errors?: Record<string, Object>;
  link?: string | boolean;
  cursiveLabel?: string;
  repeatableHeader?: string;
  repeatableKey?: { key: string; last: boolean; amount: number };
  index?: number;
  disabled?: boolean;
  overlayProps?: HoverAttributes;
  changeAmount?: (key: string, direction: number) => void;
  validate?: (id: string) => void;
  handleInputChange: (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    id: string,
    type?: string
  ) => void;
}

export const FormTextArea: React.FC<FormTextAreaProps> = ({
  id,
  placeholder,
  handleInputChange,
  question,
  divider,
  value,
  inputStyle,
  divStyle,
  repeatableHeader,
  changeAmount,
  index,
  labelStyle,
  containerStyle,
  dividerHeaderStyle,
  repeatable,
  register,
  errors,
  link,
  cursiveLabel,
  repeatableKey,
  validate,
  disabled,
  overlayProps,
}) => {
  const styles = inputStyle
    ? inputStyle
    : 'font-semibold text-brand-dark_blue focus:outline-none focus:bg-blue-100 focus:shadow-inner border border-gray-300 rounded-lg py-2 px-4 m-4 appearance-none leading-normal cursor-pointer';

  const divStyles = divStyle
    ? divStyle
    : 'flex flex-col mb-4 xl:w-11/12 xl:m-auto';

  const participantId = repeatable ? id.split('_')[1] : ''; //expected id is 'BLABLABLA_2' => 2

  const dividerHeaderStyles = dividerHeaderStyle
    ? dividerHeaderStyle
    : 'm-2  font-bold text-lg text-brand-blue';

  const dividerStyles = divider ? divider : '';
  const containerStyles = containerStyle ? containerStyle : '';

  const repeatIndex = participantId ? participantId : 1;

  return (
    <div
      className={`${divStyles} ${dividerStyles} ${containerStyles}`}
      tabIndex={index}
    >
      <RepeatableHeader
        divider={divider}
        repeatable={repeatable}
        label={`${repeatableHeader} ${repeatIndex}`}
        style={dividerHeaderStyles}
      />
      <LabelContainer
        link={link}
        question={question}
        labelStyle={labelStyle}
        register={register}
        cursiveLabel={cursiveLabel}
        overlayProps={overlayProps}
      />
      <textarea
        value={value}
        className={`${styles} ${disabled ? 'bg-gray-200' : ''}`}
        name={id}
        placeholder={placeholder}
        onChange={(e) => handleInputChange(e, id)}
        ref={register}
        onBlur={validate ? () => validate(id) : null}
        disabled={disabled ? true : false}
      />
      <ErrorDiv errors={errors} id={id} />
      <RepeatableButtons
        repeatableKey={repeatableKey}
        changeAmount={changeAmount}
        repeatableHeader={repeatableHeader}
      />
    </div>
  );
};

export default FormTextArea;
