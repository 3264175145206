import { LoginObject } from '../types/types';

const startLogin = (logObj: LoginObject) => {
  return { type: 'START_LOGIN', payload: logObj };
};

const logout = () => {
  return { type: 'LOGOUT' };
};

export const loginActions = {
  startLogin,
  logout,
};
