import { IntlMessageID } from '..';
import { WysiwygEmailMapper } from '../types/types';

export const emailMappings: Array<WysiwygEmailMapper> = [
  {
    isHardCoded: true,
    isOverWriteDisabled: false,
    value: '',
    id: 'emailSubject',
    required: true,
    type: 'text',
  },
  {
    isHardCoded: false,
    isOverWriteDisabled: false,
    value: '',
    id: 'emailTo',
    required: true,
    type: 'email',
  },
  {
    isHardCoded: false,
    isOverWriteDisabled: true,
    value: '',
    id: 'emailSender',
    required: true,
    type: 'senderEmail',
  },
  {
    isHardCoded: false,
    isOverWriteDisabled: false,
    value: '',
    id: 'emailCc',
    required: false,
    type: 'email',
  },
  {
    isHardCoded: false,
    isOverWriteDisabled: false,
    value: '',
    id: 'emailBcc',
    required: false,
    type: 'email',
  },
  {
    isHardCoded: false,
    isOverWriteDisabled: false,
    value: '',
    id: 'emailRespondTo',
    required: false,
    type: 'email',
  },
];

export const emailMappingLabels: Record<string, IntlMessageID> = {
  emailTo: 'certs.wysiwygEmailMapping.emailTo',
  emailSender: 'certs.wysiwygEmailMapping.emailSender',
  emailCc: 'certs.wysiwygEmailMapping.emailCc',
  emailBcc: 'certs.wysiwygEmailMapping.emailBcc',
  emailRespondTo: 'certs.wysiwygEmailMapping.emailRespondTo',
  emailSubject: 'certs.wysiwygEmailMapping.emailSubject',
};

export const emailErrors: Record<string, IntlMessageID> = {
  missing: 'cert.create.emailerror.missing',
  notvalidemail: 'cert.create.emailerror.notvalidemail',
};

type EmailTemplate =
  | 'Email'
  | 'Email and Attachment'
  | 'Sähköposti'
  | 'Sähköposti ja liite';

export const emailTemplateTranslations: Record<EmailTemplate, IntlMessageID> = {
  Email: 'cert.create.email',
  Sähköposti: 'cert.create.email',
  'Email and Attachment': 'cert.create.emailandaattachment',
  'Sähköposti ja liite': 'cert.create.emailandaattachment',
};

export const emailTemplates = Object.keys(emailTemplateTranslations);

export const onlyEmail = ['Email', 'Sähköposti'];
