import React from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from '../translations';

type Props = {
  missing: string[];
};

export const ErrorsMissingList: React.FC<Props> = ({ missing }) => {
  const message =
    missing.length > 0 ? messages.errorsmissing : messages.errorsok;
  return (
    <div>
      <span className="text-xl text-brand-dark_blue py-2">
        1. <FormattedMessage {...message} />
      </span>
      {missing.map((m, i) => (
        <div className="py-1" key={`missing_${m}_${i}`}>
          <span className="mx-2 text-red-500 truncate">❗{m}</span>
        </div>
      ))}
    </div>
  );
};
