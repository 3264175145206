import { IntlMessageID } from '..';
import { InvoiceOperator, FennoaProduct } from '../reducers/invoiceReducer';

export type Maptype = 'Mappable' | 'Date' | 'NotesBefore';
export type Inheritance = 'Hardcoded' | 'Inherited';
export type HardcodeType = 'text' | 'number' | 'longtext' | 'date';
export type InputValidationType = 'Warning' | 'Error';
export type DeliveryMethods = 'finvoice' | 'email';
export type InputValidation = {
  type: InputValidationType;
  message: IntlMessageID;
  validation: 'Vatnumber' | 'Number' | 'NotEmpty' | 'ZipCode' | 'Email';
};
type ProductInfo = {
  type: 'row_product_no' | 'row_account_code' | 'row_name' | 'row_price';
  counterPart: keyof FennoaProduct | 'tuoteKustannuspaikka';
  editable: boolean;
};
export type InputDetails = {
  name: IntlMessageID;
  id: string;
  type: Maptype;
  mapping: Inheritance;
  hardcodeType: HardcodeType;
  rowData: boolean;
  extract?: RegExp;
  required: boolean | string;
  productCode?: ProductInfo;
  validation?: InputValidation;
  options?: string[];
  showMethod?: DeliveryMethods;
};

export type DestinationMaps = {
  [K in InvoiceOperator]: {
    [key: string]: InputDetails;
  };
};

export const destinationMaps: DestinationMaps = {
  Fennoa: {
    name: {
      name: 'invoicing.fennoa.custname',
      id: 'name',
      type: 'Mappable',
      mapping: 'Inherited',
      hardcodeType: 'text',
      rowData: false,
      required: true,
      validation: {
        type: 'Error',
        message: 'errors.invoicing.notempty',
        validation: 'NotEmpty',
      },
    },
    address: {
      name: 'invoicing.fennoa.address',
      id: 'address',
      type: 'Mappable',
      mapping: 'Inherited',
      hardcodeType: 'text',
      rowData: false,
      required: true,
      validation: {
        type: 'Error',
        message: 'errors.invoicing.notempty',
        validation: 'NotEmpty',
      },
    },
    postalcode: {
      name: 'invoicing.fennoa.postalcode',
      id: 'postalcode',
      type: 'Mappable',
      mapping: 'Inherited',
      hardcodeType: 'text',
      rowData: false,
      required: true,
      extract: /^\d{5}/, //eslint-disable-line no-useless-escape
      validation: {
        type: 'Error',
        message: 'errors.invoicing.notfinnishpostalcode',
        validation: 'ZipCode',
      },
    },
    city: {
      name: 'invoicing.fennoa.city',
      id: 'city',
      type: 'Mappable',
      mapping: 'Inherited',
      hardcodeType: 'text',
      rowData: false,
      required: true,
      validation: {
        type: 'Error',
        message: 'errors.invoicing.notempty',
        validation: 'NotEmpty',
      },
    },
    vat_number: {
      name: 'invoicing.fennoa.vat_number',
      id: 'vat_number',
      type: 'Mappable',
      mapping: 'Inherited',
      hardcodeType: 'text',
      rowData: false,
      required: false,
      validation: {
        type: 'Warning',
        message: 'errors.invoicing.vatnumber',
        validation: 'Vatnumber',
      },
    },
    your_reference: {
      name: 'invoicing.fennoa.your_reference',
      id: 'your_reference',
      type: 'Mappable',
      mapping: 'Inherited',
      hardcodeType: 'text',
      rowData: false,
      required: false,
    },
    einvoice_address: {
      name: 'invoicing.fennoa.einvoice_address',
      id: 'einvoice_address',
      type: 'Mappable',
      mapping: 'Inherited',
      hardcodeType: 'text',
      rowData: false,
      required: 'delivery_method:email',
      showMethod: 'email',
      validation: {
        type: 'Error',
        message: 'errors.invoicing.notemail',
        validation: 'Email',
      },
    },
    notes_internal: {
      name: 'invoicing.fennoa.notes_internal',
      id: 'notes_internal',
      type: 'Mappable',
      mapping: 'Hardcoded',
      hardcodeType: 'text',
      rowData: false,
      required: false,
    },
    notes_before: {
      name: 'invoicing.fennoa.notes_before',
      id: 'notes_before',
      type: 'NotesBefore',
      mapping: 'Hardcoded',
      hardcodeType: 'text',
      rowData: false,
      required: false,
    },
    row_product_no: {
      name: 'invoicing.fennoa.product_no',
      id: 'product_no',
      type: 'Mappable',
      mapping: 'Inherited',
      hardcodeType: 'text',
      rowData: true,
      required: false,
      productCode: {
        type: 'row_product_no',
        counterPart: 'tuoteKoodi',
        editable: true,
      },
    },
    row_name: {
      name: 'invoicing.fennoa.row_name',
      id: 'name',
      type: 'Mappable',
      mapping: 'Hardcoded',
      hardcodeType: 'text',
      rowData: true,
      required: false,
      productCode: {
        type: 'row_name',
        counterPart: 'tuoteNimiSuomeksi',
        editable: true,
      },
    },
    row_description: {
      name: 'invoicing.fennoa.description',
      id: 'description',
      type: 'Mappable',
      mapping: 'Hardcoded',
      hardcodeType: 'text',
      rowData: true,
      required: false,
    },
    row_price: {
      name: 'invoicing.fennoa.price',
      id: 'price',
      type: 'Mappable',
      mapping: 'Hardcoded',
      hardcodeType: 'number',
      rowData: true,
      required: false,
      extract: /(\d*\,.\d+)$/, //eslint-disable-line no-useless-escape
      validation: {
        type: 'Warning',
        message: 'errors.invoicing.notnumber',
        validation: 'Number',
      },
      productCode: {
        type: 'row_price',
        counterPart: 'tuoteOletusmMyntiHinta',
        editable: true,
      },
    },
    row_quantity: {
      name: 'invoicing.fennoa.quantity',
      id: 'quantity',
      type: 'Mappable',
      mapping: 'Hardcoded',
      hardcodeType: 'number',
      rowData: true,
      required: false,
      validation: {
        type: 'Warning',
        message: 'errors.invoicing.notnumber',
        validation: 'Number',
      },
    },
    row_vatpercent: {
      name: 'invoicing.fennoa.vatpercent',
      type: 'Mappable',
      id: 'vatpercent',
      mapping: 'Inherited',
      hardcodeType: 'number',
      rowData: true,
      required: false,
      options: ['0', '10', '14', '24'],
      validation: {
        type: 'Warning',
        message: 'errors.invoicing.notnumber',
        validation: 'Number',
      },
    },
    invoice_date: {
      name: 'invoicing.fennoa.invoice_date',
      id: 'invoice_date',
      type: 'Date',
      mapping: 'Hardcoded',
      hardcodeType: 'date',
      required: true,
      rowData: false,
    },
    due_date: {
      name: 'invoicing.fennoa.due_date',
      id: 'due_date',
      type: 'Date',
      mapping: 'Hardcoded',
      hardcodeType: 'date',
      required: true,
      rowData: false,
    },
  },
};
