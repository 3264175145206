import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TemplateQuestion } from '../../types/types';
import * as fState from '../../reducers/formReducer';
import * as cState from '../certReducer';
import FormInput from '../../components/form/FormInput';
import FormTextArea from '../../components/form/FormTextArea';
import { FormSelect } from '../../components/form/FormSelect';
import { useForm } from 'react-hook-form';
import { returnRequiredRule } from '../../utils/functions';
import allActions from '../../actions/allActions';
import * as toggleState from '../../reducers/toggleReducer';
import { Crop } from 'react-image-crop';
import { FileUploadInput } from './FileUpload';
import { messages } from '../translations/newcert';
import FormRadio from '../../components/form/FormRadio';

interface TemplateFormProps {
  questions: TemplateQuestion[];
  handleInputChange: (e: any, id: string) => void;
  upload?: (
    crop: Crop,
    blob: HTMLCanvasElement,
    id: string,
    toggleId: string
  ) => void;
}

export const Form = ({
  questions,
  handleInputChange,
  upload,
}: TemplateFormProps) => {
  const values = useSelector(fState.getNewCertTemplateFormValues);
  const { sendMultiple, personQuestionIds } = useSelector(
    cState.selectSendMultiple
  );
  const { register, trigger, errors } = useForm();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const toggles = useSelector(toggleState.getToggleState);

  function validate(id: string) {
    trigger(id);
  }

  function focusToTop() {
    formRef.current.scrollIntoView();
  }

  const toggleImageQuestion = (id: string) => {
    dispatch(allActions.toggleActions.toggle(id));
    focusToTop();
  };

  return (
    <div className="flex flex-wrap relative p-2" ref={formRef}>
      {questions.map((q) =>
        sendMultiple && q.person ? (
          <FormSelect
            divStyle="flex flex-col w-1/2"
            key={q.id}
            id={q.id}
            cursiveLabel={q.description}
            question={q.question}
            value={q.readOnly ? q.defaultValue : values[q.id] || ''}
            options={personQuestionIds}
            register={q.required ? returnRequiredRule('text', register) : null}
            validate={q.required ? validate : null}
            errors={errors}
            handleInputChange={handleInputChange}
            disabled={q.readOnly ? true : false}
          />
        ) : q.type === 'text' || q.type === 'email' ? (
          <FormInput
            divStyle="flex flex-col w-1/2"
            key={q.id}
            id={q.id}
            cursiveLabel={q.description}
            question={q.question}
            type="text"
            value={q.readOnly ? q.defaultValue : values[q.id] || ''}
            register={q.required ? returnRequiredRule(q.type, register) : null}
            validate={q.required ? validate : null}
            errors={errors}
            disabled={q.readOnly ? true : false}
            handleInputChange={handleInputChange}
          />
        ) : q.type === 'radio' ? (
          <FormRadio
            divStyle="flex flex-col w-1/2"
            key={q.id}
            id={q.id}
            cursiveLabel={q.description}
            question={q.question}
            checked={q.readOnly ? q.defaultValue : values[q.id] || ''}
            register={q.required ? returnRequiredRule('text', register) : null}
            validate={q.required ? validate : null}
            errors={errors}
            disabled={q.readOnly ? true : false}
            handleInputChange={handleInputChange}
            options={q.options ?? []}
          />
        ) : q.type === 'dropdown' ? (
          <FormSelect
            divStyle="flex flex-col w-1/2"
            key={q.id}
            id={q.id}
            cursiveLabel={q.description}
            question={q.question}
            value={q.readOnly ? q.defaultValue : values[q.id] || ''}
            register={q.required ? returnRequiredRule('text', register) : null}
            validate={q.required ? validate : null}
            errors={errors}
            disabled={q.readOnly ? true : false}
            handleInputChange={handleInputChange}
            options={q.options ?? []}
          />
        ) : q.type === 'picture' ? (
          <FileUploadInput
            key={q.id}
            q={q}
            showMsg={messages.showcrop}
            hideMsg={messages.hidecrop}
            visible={toggles[`TOGGLE_${q.question}_CROP_VIEW`]}
            toggleId={`TOGGLE_${q.question}_CROP_VIEW`}
            upload={upload}
            toggleImageQuestion={toggleImageQuestion}
            imageUrl={q.pictureProps.preview}
          />
        ) : (
          <FormTextArea
            divStyle="flex flex-col w-full"
            inputStyle="font-semibold text-brand-dark_blue focus:outline-none focus:bg-blue-100 focus:shadow-inner border border-gray-300 rounded-lg py-2 px-4 m-4 appearance-none leading-normal cursor-pointer w-11/12"
            key={q.id}
            id={q.id}
            cursiveLabel={q.description}
            question={q.question}
            value={q.readOnly ? q.defaultValue : values[q.id] || ''}
            register={q.required ? returnRequiredRule('text', register) : null}
            validate={q.required ? validate : null}
            errors={errors}
            disabled={q.readOnly ? true : false}
            handleInputChange={handleInputChange}
          />
        )
      )}
    </div>
  );
};
