import React from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from './translations';

type Props = {
  cancelPasswordChange: () => void;
};

export const PasswordChangeButtons: React.FC<Props> = ({
  cancelPasswordChange,
}) => {
  return (
    <div className="flex justify-center">
      <button
        onClick={cancelPasswordChange}
        className="bg-red-400 hover:bg-brand-blue font-thin text-brand-white py-2 px-4 rounded m-4"
      >
        <FormattedMessage {...messages.cancelbutton} />
      </button>
      <button
        type="submit"
        className="bg-blue-400 hover:bg-brand-blue font-thin text-brand-white py-2 px-4 rounded m-4"
      >
        <FormattedMessage {...messages.changebutton} />
      </button>
    </div>
  );
};
