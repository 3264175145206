import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../actions/allActions';
import FormInput from '../../components/form/FormInput';
import FormSelect from '../../components/form/FormSelect';
import FormTextArea from '../../components/form/FormTextArea';
import { Switch } from '../../components/utils/Switch';
import { AppState } from '../../reducers/combineReducer';
import { getInheritance } from '../../reducers/invoiceReducer';
import { InputDetails } from '../invoiceMappings';
import { messages } from '../translations';

type Props = {
  input: InputDetails;
  value: string;
  id: string;
  handleInputChange: (
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    id: string,
    type?: 'ProductInfo' | 'Normal'
  ) => void;
  options: string[];
  last: boolean;
  register: any;
};

export const InvoiceMultiInput: React.FC<Props> = ({
  input,
  id,
  value,
  handleInputChange,
  options,
  last,
  register,
}) => {
  const dispatch = useDispatch();
  const inheritance = useSelector((state: AppState) =>
    getInheritance(state, id)
  );

  const toggleInheritance = () => {
    dispatch(allActions.invoiceActions.changeInheritance(id));
  };

  const { formatMessage } = useIntl();

  const name = formatMessage({ id: input.name, defaultMessage: input.id });
  const hardCodedInput =
    input.hardcodeType === 'longtext' ? 'textarea' : 'text';

  const selectType =
    input.productCode && input.productCode.type === 'row_product_no'
      ? 'ProductInfo'
      : 'Normal';

  const disabled = input.productCode && !input.productCode.editable;
  return (
    <div className={`flex w-1/2 ${last ? 'pb-4' : ''}`}>
      {inheritance === 'Hardcoded' && hardCodedInput === 'text' ? (
        <FormInput
          value={value}
          id={id}
          handleInputChange={(e) => handleInputChange(e, id)}
          question={name}
          type={input.hardcodeType}
          register={input.required ? register({ required: `any` }) : null}
          disabled={disabled}
        />
      ) : inheritance === 'Hardcoded' && input.hardcodeType === 'longtext' ? (
        <FormTextArea
          value={value}
          id={id}
          handleInputChange={(e) => handleInputChange(e, id)}
          question={name}
          register={input.required ? register({ required: `any` }) : null}
        />
      ) : (
        <FormSelect
          value={value}
          id={input.id}
          handleInputChange={(e) => handleInputChange(e, id, selectType)}
          options={options}
          question={name}
          register={input.required ? register({ required: `any` }) : null}
        />
      )}

      <Switch
        labelStyle={'text-sm  font-semibold text-brand-dark_blue'}
        switchAction={toggleInheritance}
        header={formatMessage(messages.hardcoded)}
        id={`${id}_type_toggle`}
        show={inheritance === 'Hardcoded'}
      />
    </div>
  );
};
