import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedMessageProps } from '../types/types';
import { BiChevronRight, BiChevronDown } from 'react-icons/bi';

type Props = {
  onClick: () => void;
  message: FormattedMessageProps;
  styleStr?: string;
  expanded: boolean;
};

export const NavHeader: React.FC<Props> = ({
  onClick,
  message,
  styleStr,
  expanded,
}) => {
  const styles = styleStr
    ? styleStr
    : 'text-lg lg:mt-0 text-blue-200 hover:text-yellow-300 mr-4 mt-4 right-0';
  const iconNavClass = 'text-white hover:text-blue-300 m-1';
  return (
    <div
      className="flex w-full justify-between cursor-pointer"
      onClick={onClick}
    >
      <div>
        <span className={styles}>
          <FormattedMessage {...message} />
        </span>
      </div>
      {expanded ? (
        <BiChevronDown size={24} className={iconNavClass} />
      ) : (
        <BiChevronRight size={24} className={iconNavClass} />
      )}
    </div>
  );
};
