import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { LabelContainer } from './labels/LabelContainer';
import { RepeatableHeader } from './divs/RepatableHeader';
import { ErrorDiv } from './divs/ErrorDiv';
import { RepeatableButtons } from './buttons/RepeatableButtons';
import { HoverAttributes } from '../utils/HoverMessage';

interface FormProps {
  id: string;
  placeholder?: string;
  value?: string;
  question?: string;
  containerStyle?: string;
  divider?: string;
  repeatable?: boolean;
  inputStyle?: string;
  divStyle?: string;
  labelStyle?: string;
  dividerHeaderStyle?: string;
  register?: any;
  errors?: Record<string, Object>;
  link?: string | boolean;
  cursiveLabel?: string;
  repeatableHeader?: string;
  repeatableKey?: { key: string; last: boolean; amount: number };
  required?: boolean;
  changeAmount?: (key: string, direction: number) => void;
  handleInputChange: (color: string, id: string, type?: string) => void;
  overlayProps?: HoverAttributes;
}

export const FormColor: React.FC<FormProps> = ({
  id,
  placeholder,
  handleInputChange,
  question,
  value,
  containerStyle,
  errors,
  link,
  repeatableKey,
  changeAmount,
  divider,
  repeatable,
  inputStyle,
  labelStyle,
  divStyle,
  dividerHeaderStyle,
  register,
  cursiveLabel,
  repeatableHeader,
  required,
  overlayProps,
}) => {
  //TODO: Tänne pitää luoda sisäinen tila komponentille, jolla tiedetään milloin näytetään pickeriä
  // Aluksi näytetään vain väriä, ja kun sitä klikataan, avajutuu pikkeri.

  const [open, setOpen] = useState(false);

  const styles = inputStyle ? inputStyle : 'self-center z-30';

  const textValueStyle = 'self-center text-lg';

  const divStyles = divStyle ? divStyle : 'flex flex-col mb-4 ';

  const participantId = repeatable ? id.split('_')[1] : ''; //expected id is 'BLABLABLA_2' => 2

  const dividerHeaderStyles = dividerHeaderStyle
    ? dividerHeaderStyle
    : 'm-2  font-bold text-lg text-brand-blue';

  const dividerStyles = divider ? divider : '';
  const containerStyles = containerStyle ? containerStyle : '';

  const repeatIndex = participantId ? participantId : 1;

  const ElementToShow = open ? (
    <SketchPicker
      className={styles}
      id={id}
      name={id}
      ref={register}
      onChangeComplete={(c) =>
        handleInputChange(
          `rgba(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b}, ${c.rgb.a})`,
          id
        )
      }
      color={value}
    />
  ) : (
    <div
      onClick={() => setOpen(true)}
      style={{
        backgroundColor: value,
        borderRadius: '12px',
        height: '60px',
        width: '60px',
        margin: 'auto',
      }}
    />
  );

  return (
    <div className={`${divStyles} ${containerStyles} ${dividerStyles}`}>
      {open && (
        <div
          className="absolute inset-0 bg-gray-700 opacity-80 z-10"
          onClick={() => setOpen(!open)}
        />
      )}
      <RepeatableHeader
        divider={divider}
        repeatable={repeatable}
        label={`${repeatableHeader} ${repeatIndex}`}
        style={dividerHeaderStyles}
      />
      <LabelContainer
        link={link}
        question={question}
        labelStyle={labelStyle}
        register={register}
        cursiveLabel={cursiveLabel}
        overlayProps={overlayProps}
      />
      {ElementToShow}
      <span className={textValueStyle}>{value}</span>
      <ErrorDiv errors={errors} id={id} />
      <RepeatableButtons
        repeatableKey={repeatableKey}
        changeAmount={changeAmount}
        repeatableHeader={repeatableHeader}
      />
    </div>
  );
};

export default FormColor;
