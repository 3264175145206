import React from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from '../translations';

type Props = {
  state: 'Errors' | 'Warnings' | 'OK';
  sendButton: string;
  sendInvoice: () => void;
};
export const InvoiceErrorsBottom: React.FC<Props> = ({
  state,
  sendButton,
  sendInvoice,
}) => {
  return (
    <div className="flex flex-col">
      {state === 'Errors' ? (
        <span className="text-brand-dark_blue self-center">
          <FormattedMessage
            {...messages.errorsdesciption}
            values={{
              red: (...chunks: string[]) => (
                <span className="text-red-500">{chunks}</span>
              ),
              yellow: (...chunks: string[]) => (
                <span className="text-yellow-700">{chunks}</span>
              ),
              lineBreak: <br />,
            }}
          />
        </span>
      ) : state === 'Warnings' ? (
        <span className="text-brand-dark_blue self-center">
          <FormattedMessage
            {...messages.errorsdescriptionwarnings}
            values={{
              yellow: (...chunks: string[]) => (
                <span className="text-yellow-700">{chunks}</span>
              ),
            }}
          />
        </span>
      ) : null}
      <button
        className={`w-1/6 mx-auto py-2 m-5 rounded text-brand-white ${sendButton}`}
        onClick={sendInvoice}
        disabled={state === 'Errors'}
      >
        <FormattedMessage {...messages.sendbutton} />
      </button>
    </div>
  );
};
