import React from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormInput } from '../../../../components/form/FormInput';
import { FormSelect } from '../../../../components/form/FormSelect';
import { FormInputInfo } from '../../../../types/types';
import * as t from '../../translations/productModalTranslations';

interface ProductModalProps {
  styles?: string;
  modalId: string;
  item: FormInputInfo;
  options: { id: string; name: string }[];
  modalAction: (item: FormInputInfo, modalId: string) => void;
  modalCancel: (modalId: string) => void;
}

export const ProductModal: React.FC<ProductModalProps> = ({
  styles,
  modalId,
  item,
  options,
  modalAction,
  modalCancel,
}) => {
  const [state, setState] = useState({
    ...item,
    product: item.product
      ? {
          multiplier: findValue(item.product.multiplier),
          tax: item.product.tax,
        }
      : {
          multiplier: '',
          tax: '',
        },
  });
  const [message, setMessage] = useState<string>('');
  const style = styles !== undefined ? styles : 'text-center shadow-xl';
  const confirMationButton = !message
    ? 'bg-brand-white hover:bg-blue-200 text-brand-blue m-4 rounded w-20 h-10'
    : 'bg-red-300 text-brand-white m-4 rounded w-20 h-10';
  const { formatMessage } = useIntl();

  const optionsName = [''].concat(
    options.map((item) => item.name),
    ['Self']
  );

  const localInputChange = (e: any) => {
    setState({
      ...state,
      product: {
        ...state.product,
        [e.target.id]: e.target.value,
      },
    });
  };

  function findValue(key: string) {
    const item = options.find((item) => item.id === key);
    return item !== undefined ? item.name : '';
  }

  function findId(key: string) {
    const item = options.find((item) => item.name === key);
    return item !== undefined ? item.id : '';
  }

  function validate(str: string) {
    const convertDecimal = str.replace(',', '.');
    if (isNaN(Number(convertDecimal))) {
      setMessage('Tax must be number');
      return false;
    } else if (Number(convertDecimal) < 0 || Number(convertDecimal) > 100) {
      setMessage('Tax must be a number between 0-100');
      return false;
    }
    setMessage('');
    return true;
  }

  return (
    <div className={style}>
      <div className="flex flex-col">
        <div className="flex flex-col">
          <div className=" font-semibold m-4 text-brand-dark_blue">
            <FormattedMessage {...t.messages.header} />
          </div>
          <div className="m-4 text-brand-dark_blue">
            <FormattedMessage {...t.messages.paragraph} />
          </div>
          <FormSelect
            id="multiplier"
            question={formatMessage({ ...t.messages.multiplierquestion })}
            options={optionsName}
            value={state.product.multiplier}
            handleInputChange={localInputChange}
            cursiveLabel={formatMessage({
              ...t.messages.multiplierdescription,
            })}
          />
          <FormInput
            id="tax"
            question={formatMessage({ ...t.messages.taxquestion })}
            value={state.product.tax}
            type="text"
            handleInputChange={localInputChange}
            cursiveLabel={formatMessage({ ...t.messages.taxlabel })}
            onBlur={(e) => validate(e.target.value)}
          />
          {message ? <span className="text-red-300">{message}</span> : null}
        </div>
        <div className="flex flex-row justify-around">
          <div className="pb-6">
            <button
              onClick={() =>
                modalAction(
                  {
                    ...state,
                    product: {
                      multiplier:
                        findId(state.product.multiplier) ||
                        state.product.multiplier,
                      tax: state.product.tax,
                    },
                  },
                  modalId
                )
              }
              className={confirMationButton}
              disabled={message.length > 0}
            >
              <FormattedMessage {...t.messages.confirm} />
            </button>
          </div>
          <button
            onClick={() =>
              modalAction(
                {
                  ...state,
                  product: {
                    multiplier: '',
                    tax: '',
                  },
                },
                modalId
              )
            }
            className="bg-red-300 hover:bg-red-400 text-brand-white m-4 rounded w-20 h-10"
          >
            <FormattedMessage {...t.messages.remove} />
          </button>
          <div className="pb-6">
            <button
              onClick={() => modalCancel(modalId)}
              className="bg-brand-white hover:bg-blue-200 text-brand-blue m-4 rounded w-20 h-10"
            >
              <FormattedMessage {...t.messages.cancel} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
