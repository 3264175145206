import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LinkListObject } from '../../eventReducer';
import { messages } from '../translations/linkTranslations';
import { LinkListItem } from './LinkListItem';

interface Props {
  list: LinkListObject[];
}

export const LinkList: React.FC<Props> = ({ list }) => {
  const links = list.filter((l) => l.linkStr.length > 0);
  const message =
    links.length > 0 ? messages.existinglinksheader : messages.noexistinglinks;
  return (
    <div className="w-full h-2/3 mb-2 border overflow-auto scroller shadow-lg">
      <p className="text-brand-dark_blue text-3xl pt-4">
        <FormattedMessage {...message} />
      </p>
      <span className="semibold font-sm  text-lg text-brand-dark_blue ml-1"></span>
      <ul className="flex flex-wrap m-2 justify-center">
        {links.map((l, i) => (
          <LinkListItem key={`${l}${i}`} item={l} />
        ))}
      </ul>
    </div>
  );
};
