import { useIntl } from 'react-intl';
import {
  EventParticipant,
  TableParticipant,
  SubEventObject,
  FormInputInfo,
  SubEventStateData,
} from '../types/types';

export const arrayToObject = (array: { [key: string]: any }[], key: string) =>
  array.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});

export const arrayToObjectVariation = (
  array: { [key: string]: any }[],
  key: string,
  value: string
) =>
  array.reduce((obj, item) => {
    obj[item[key]] = item[value];
    return obj;
  }, {});

export const concatNarrays = (arr: string[][]) => {
  const newArr = arr.reduce((prev, next) => {
    return prev.concat(next);
  });
  return newArr;
};

export const searchArrayByValue = (
  array: any[],
  searchWord: string,
  filterKeys: string[]
) => {
  const result = array.filter((a) =>
    filterKeys.length === 0
      ? includesSearchWord(a, searchWord)
      : filterByKey(filterKeys, searchWord, a)
  );
  return result;
};

const filterByKey = (
  filterKey: string[],
  searchWord: string,
  obj: { [key: string]: string | string[] | number }
) => {
  for (const key of Object.keys(obj)) {
    if (filterKey.includes(key)) {
      if (
        obj[key] &&
        obj[key].toString().toLowerCase().includes(searchWord.toLowerCase())
      ) {
        return true;
      }
    }
  }
};

const includesSearchWord = (
  obj: { [key: string]: string | string[] | number },
  searchWord: string
) => {
  for (const value of Object.values(obj)) {
    if (
      value &&
      value.toString().toLowerCase().includes(searchWord.toLowerCase())
    ) {
      return true;
    }
  }
};

export const getArrays = (arr: string[], baskets: any) => {
  const arrayOfArrays: string[][] = [];
  for (const basket of arr) {
    if (Number(basket) !== arr.length) {
      arrayOfArrays.push(baskets[basket].questionIds);
    }
  }
  return arrayOfArrays;
};

export const useFormatMessage = (messageId: string) => {
  return useIntl().formatMessage({ id: messageId });
};

export function formatLink(link: string) {
  if (link.substr(0, 4) === 'http') {
    return link;
  } else {
    return `http://${link}`;
  }
}

export const getPageList = (page: number, limit: number, list: any[]) => {
  const offset = (page - 1) * limit;
  return list.slice(offset, offset + limit);
};

export const toggleKey = (key: string, keys: string[]) => {
  return keys.includes(key) ? keys.filter((f) => f !== key) : keys.concat(key);
};

export const sortByKey = (key: string, ascending: boolean, list: any[]) => {
  return ascending
    ? list.sort((a, b) =>
        !(key in a) || !(key in b)
          ? 0
          : Number.isInteger(a[key]) && Number.isInteger(b[key])
          ? Number(b[key]) - Number(a[key])
          : b[key].localeCompare(a[key])
      )
    : list.sort((a, b) =>
        !(key in a) || !(key in b)
          ? 0
          : Number.isInteger(a[key]) && Number.isInteger(b[key])
          ? Number(a[key]) - Number(b[key])
          : a[key].localeCompare(b[key])
      );
};

export function convertParticipantToListFormat(
  original: EventParticipant
): TableParticipant {
  const converted = {
    uuid: original.partypantsUuid,
    groupUuid: original.partypantsGroupUuid,
    subEventId: original.partypantsSubEventId,
    role: original.partypantsEventRole,
    qrCode: original.partypantsQrCode,
    registertime: original.partypantsRegisterTime,
    registrationState: original.partypantsRegisterState,
    invoiceState: original.partypantsRegistrarInvoiceState,
    comment: original.partypantsRegistrarComment,
    customState: original.partypantsRegistrarCustomState,
  };
  const person = original.partypantsAnswer
    ? original.partypantsAnswer.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.id]: curr.answer,
        };
      }, {})
    : {};

  const common = original.partypantsAnswerCommon
    ? original.partypantsAnswerCommon.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.id]: curr.answer,
        };
      }, {})
    : {};

  return { ...converted, ...person, ...common };
}

export function getEventName(
  searchId: string,
  subEvents: SubEventObject[],
  eventName: string
) {
  return subEvents.length
    ? subEvents.find((s) => s.subEventId === searchId).subEventName
    : eventName;
}

export function getParticipantTableStartingHeaders(
  EventParticipants: EventParticipant[],
  table: TableParticipant[]
): string[] {
  const uniqueDbAnswerKeys = Array.from(
    new Set(
      EventParticipants.map((ep) =>
        ep.partypantsAnswer
          .filter((answer) => answer.dbKeys)
          .map((answer) => answer.id)
          .concat(
            ep.partypantsAnswerCommon
              .filter((answer) => answer.dbKeys)
              .map((answer) => answer.id)
          )
      ).flat()
    )
  );

  const firstRow = Object.keys(table[0]);
  if (uniqueDbAnswerKeys.length === 0) {
    return table.length > 0
      ? firstRow.length > 5
        ? firstRow.slice(0, 5)
        : firstRow
      : [];
  }
  if (uniqueDbAnswerKeys.length >= 5) {
    return uniqueDbAnswerKeys.slice(0, 5);
  }
  return uniqueDbAnswerKeys.concat(firstRow).slice(0, 5);
}

export function convertParticipantToPartypants(
  updated: TableParticipant,
  participant: EventParticipant
): EventParticipant {
  const final = {
    partypantsUuid: updated.uuid,
    partypantsGroupUuid: participant.partypantsGroupUuid,
    partypantsSubEventId: updated.subEventId,
    partypantsQrCode: updated.qrCode,
    partypantsRegisterState: updated.registrationState,
    partypantsRegisterTime: updated.registertime,
    partypantsEventRole: updated.role,
    cancelToken: participant.cancelToken,
    modifyToken: participant.modifyToken,
    modifyTokenExpiresTime: participant.modifyTokenExpiresTime,
    partypantsOverview: participant.partypantsOverview,
    partypantsRegistrarInvoiceState: updated.invoiceState,
    partypantsRegistrarCustomState: updated.customState,
    partypantsRegistrarComment: updated.comment,
  };

  const { partypantsAnswer, partypantsAnswerCommon } = participant;

  const personAnswer = partypantsAnswer
    ? partypantsAnswer.map((a) => {
        return {
          ...a,
          answer: updated[a.id],
        };
      })
    : [];
  const commonAnswer = partypantsAnswerCommon
    ? partypantsAnswerCommon.map((a) => {
        return {
          ...a,
          answer: updated[a.id],
        };
      })
    : [];
  return {
    ...final,
    partypantsAnswer: personAnswer,
    partypantsAnswerCommon: commonAnswer,
  };
}

export function getUniqueKeysForTable(arr: { [key: string]: any }[]) {
  const uniqueKeys = [];
  for (const obj of arr) {
    for (const key of Object.keys(obj)) {
      if (
        !uniqueKeys.includes(key) &&
        obj[key] &&
        obj[key].constructor !== Object
      ) {
        uniqueKeys.push(key);
      }
    }
  }
  return uniqueKeys;
}

export function move(input: any[], from: number, to: number) {
  let numberOfDeletedElm = 1;
  const copy = [...input];
  const elm = copy.splice(from, numberOfDeletedElm)[0];

  numberOfDeletedElm = 0;
  copy.splice(to, numberOfDeletedElm, elm);
  return [...copy];
}

export const insert = (arr: any[], index: number, ...newItems: any) => [
  ...arr.slice(0, index),
  ...newItems,
  ...arr.slice(index),
];

export const convertToAlvNumber = (str: string) => {
  if (str.indexOf('FI') !== -1) {
    const stripped = str.replace('FI', '');
    const splitted = stripped.split('');
    const dashAdded = insert(splitted, splitted.length - 1, '-');
    return dashAdded.join('');
  }
  return str;
};
export const convertToBusinessId = (str: string) => {
  return `FI${str.replace('-', '')}`;
};

export const customKeySubEventStats = (
  subs: SubEventStateData[],
  key: string
): number =>
  subs
    .map((sub) => sub.subEventStats)
    .reduce((prev, curr) => {
      return curr[key] ? prev + curr[key] : prev;
    }, 0);

export const mapFromKeys = (array: any[], keys: string[]) =>
  array.map((o) =>
    keys.reduce((acc, curr) => {
      const valueStr = o[curr]
        ? typeof o[curr] !== 'string' && typeof o[curr] !== 'number'
          ? o[curr].join(', ')
          : o[curr]
        : '';
      acc[curr] = valueStr;
      return acc;
    }, {})
  );

export function returnRequiredRule(
  type: string,
  register: any,
  test?: boolean
) {
  if (type === 'email') {
    return register({
      required: `any`,
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, //eslint-disable-line
        message: `email`,
      },
    });
  }
  if (type === 'phone') {
    return register({
      required: `any`,
      pattern: {
        value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, //eslint-disable-line
        message: `phone`,
      },
    });
  }
  if (type === 'passwordminlength') {
    return register({
      required: 'any',
      minLength: { value: 8, message: 'passwordminlength' },
    });
  }
  if (type === 'passwordmatch') {
    return register({
      validate: () => {
        return test || 'passwordmatch';
      },
    });
  }
  return register({ required: `any` });
}

export function getEmptyFormInputObject(): FormInputInfo {
  return {
    id: '',
    question: '',
    type: '',
    show: false,
    group: 1,
    page: 1,
    hide: {
      key: '',
      value: '',
      type: '',
    },
    product: {
      multiplier: '',
      tax: '',
    },
    dbKeys: {
      key: '',
      leadKey: false,
      dbKey: false,
    },
    mailKeys: {
      key: '',
    },
  };
}

export const extractId = (idStr: string) => {
  const splitted = idStr.split(' :: ');
  return splitted.length === 2 ? splitted[1] : '';
};

export const highestCommonFactor = (x: number, y: number) => {
  while (Math.max(x, y) % Math.min(x, y) !== 0) {
    if (x > y) {
      x = x % y;
    } else {
      y = y % x;
    }
  }
  return Math.min(x, y);
};

export const formatDate = (date: Date) => date.toISOString().split('T')[0];
