import { DefinedMessages } from '../../types/types';

export const messages: DefinedMessages = {
  pageheader: {
    id: 'cert.create.preview.header',
    defaultMessage: 'Great, everything is ready!',
  },
  previewheader: {
    id: 'cert.create.preview.previewcard.header',
    defaultMessage: 'Preview',
  },
  previewtext: {
    id: 'cert.create.preview.previewcard.paragraph',
    defaultMessage:
      'Preview will generate the PDF - eDiploma, and open it in a new tab. If sending to multiple receivers, preview will display first receiver.',
  },
  previewbutton: {
    id: 'cert.create.preview.button',
    defaultMessage: 'Send',
  },
  sendheader: {
    id: 'cert.create.preview.confirmcard.header',
    defaultMessage: 'Send',
  },
  sendtext: {
    id: 'cert.create.preview.confirmcard.paragraph',
    defaultMessage:
      'eDiplomas will be sent to selected participants via email.',
  },
  sendbutton: {
    id: 'cert.create.send.button',
    defaultMessage: 'Send',
  },
  multipreviewheader: {
    id: 'cert.create.preview.multipreview.header',
    defaultMessage: 'Archive',
  },
  multipreviewtext: {
    id: 'cert.create.preview.multipreview.paragraph',
    defaultMessage: 'Archive text...',
  },
  multipreviewbutton: {
    id: 'cert.create.preview.multipreview.button',
    defaultMessage: 'Archive',
  },
  errorsmissing: {
    id: 'cert.create.preview.validation.missingerrors',
    defaultMessage: 'Missing required fields',
  },
  errorsok: {
    id: 'cert.create.preview.validation.missingok',
    defaultMessage: 'All required fields are filled',
  },
  noreceivers: {
    id: 'cert.create.preview.validation.noreceivers',
    defaultMessage: 'No receivers selected!',
  },
  receivers: {
    id: 'cert.create.preview.validation.receivers',
    defaultMessage: 'Creating certs',
  },
  validationerrors: {
    id: 'cert.create.preview.validation.header',
    defaultMessage: 'Validation errors',
  },
  novalidationerrors: {
    id: 'cert.create.preview.validation.valok',
    defaultMessage: 'No validation errors',
  },
  validationstatusheader: {
    id: 'cert.create.preview.validation.validationstatus',
    defaultMessage: 'Validation status',
  },
  validationstatuserrors: {
    id: 'cert.create.preview.validation.validationstatuserrors',
    defaultMessage: 'Resolve errors',
  },
  validationstatusok: {
    id: 'cert.create.preview.validation.validationstatusok',
    defaultMessage: 'All ready',
  },
  emailmappingheader: {
    id: 'cert.create.preview.emailmapping.header',
    defaultMessage: 'Email information',
  },
  emailpreview: {
    id: 'cert.create.preview.emailpreview.header',
    defaultMessage: 'Preview sending',
  },
  emailreceivers: {
    id: 'cert.create.preview.emailreceivers',
    defaultMessage: 'Sending {amount} messages',
  },
  prevprev: {
    id: 'preview.invoicing.prev',
    defaultMessage: 'Previous',
  },
  prevnext: {
    id: 'preview.invoicing.next',
    defaultMessage: 'Next',
  },
  mappedemails: {
    id: 'cert.create.preview.mappedemails',
    defaultMessage: 'Mapped data',
  },
  emailerrors: {
    id: 'cert.create.preview.emailerrors',
    defaultMessage: 'Errors',
  },
  numberofemailerrors: {
    id: 'cert.create.preview.amountoferrors',
    defaultMessage: 'Errors',
  },
  emailerror: {
    id: 'cert.create.preview.errorkey',
    defaultMessage: 'Error: {errorKey}',
  },
  emailerrorindexes: {
    id: 'cert.create.preview.errorindexes',
    defaultMessage: 'Error in emails {indexes}',
  },
  emailerrormessage: {
    id: 'cert.create.preview.errorvalue',
    defaultMessage: 'Message: {message}',
  },
  emailsend: {
    id: 'cert.create.send.emailok',
    defaultMessage: 'Everything is OK',
  },
  emailcantsend: {
    id: 'cert.create.send.emailerror',
    defaultMessage: 'Cannot send emails before all errors are resolved',
  },
  emailsending: {
    id: 'cert.create.preview.send',
    defaultMessage: 'Sending',
  },
};
