import { RequestHeader } from '../types/types';
import * as config from './config';
import { axiosInstance } from './interceptors';
import storeService from './storage';

export type PasswordChangeObject = {
  oldPassword: string;
  newPassword: string;
};

export type PasswordChangeResponse = { status: 'error' | 'success' };

const changePassword = async (
  data: PasswordChangeObject
): Promise<{ data: PasswordChangeResponse; status: number }> => {
  const url = `${config.baseUrl}/user/changepassword`;
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  return await axiosInstance({ method: 'PATCH', url, data, headers });
};

const userService = {
  changePassword,
};

export default userService;
