import { DefinedMessages } from '../types/types';

export const messages: DefinedMessages = {
  userheader: { id: 'account.user.header', defaultMessage: 'User data' },
  username: { id: 'account.user.name', defaultMessage: 'Name' },
  useremail: { id: 'account.user.email', defaultMessage: 'email' },
  usertype: { id: 'account.user.type', defaultMessage: 'type' },
  changepassword: {
    id: 'account.user.changepassword',
    defaultMessage: 'Change password',
  },
  oldpassword: {
    id: 'account.user.oldpassword',
    defaultMessage: 'Old password',
  },
  newpassword1: {
    id: 'account.user.newpassword1',
    defaultMessage: 'New password',
  },
  newpassword2: {
    id: 'account.user.newpassword2',
    defaultMessage: 'New password again',
  },
  changebutton: { id: 'account.user.change.button', defaultMessage: 'Change' },
  cancelbutton: { id: 'account.user.cancel.button', defaultMessage: 'Cancel' },
  customerheader: {
    id: 'account.customer.header',
    defaultMessage: 'Customer data',
  },
  customername: { id: 'account.customer.name', defaultMessage: 'Name' },
  customercontactperson: {
    id: 'account.customer.contactperson',
    defaultMessage: 'Contact person',
  },
  customeremail: { id: 'account.customer.email', defaultMessage: 'Email' },
  customerphone: { id: 'account.customer.phone', defaultMessage: 'Phone' },
  customeraddress: {
    id: 'account.customer.address',
    defaultMessage: 'Address',
  },
  customercity: { id: 'account.customer.city', defaultMessage: 'City' },
  customerzip: { id: 'account.customer.zip', defaultMessage: 'Postalcode' },
  customercountry: {
    id: 'account.customer.country',
    defaultMessage: 'Country',
  },
  customerovt: { id: 'account.customer.ovt', defaultMessage: 'EDI' },
  ovtoperator: {
    id: 'account.customer.ovtoperator',
    defaultMessage: 'EDI-Operator',
  },
};
