import React from 'react';
import { VscAccount, VscSignOut } from 'react-icons/vsc';
import { NavGroup } from './NavGroup';
import { NavItem } from './NavItem';
import { NavItemWithButton } from './NavItemWithButton';
import { messages } from './navigationTranslations';
import * as img from '../img/images';
import { useSelector } from 'react-redux';
import * as uState from '../reducers/userReducer';

type Props = {
  toggleElement: (id: string) => void;
  logout: () => void;
};

export const ExpandedNavigationBar: React.FC<Props> = ({
  toggleElement,
  logout,
}) => {
  const services = useSelector(uState.selectServices);
  const iconNavClass = 'text-white hover:text-blue-300 m-1';
  const bottomOfNavigation = 'flex flex-col w-full mb-5';
  return (
    <>
      <div className="flex flex-col w-full">
        <div className="w-24 self-center pt-8">
          <img className="shadow-asl" src={`/${img.LOGOSQUARE}`} alt="logo" />
        </div>
        <NavItem
          link="/"
          message={messages.main}
          styleStr={'text-lg lg:mt-0 text-white hover:text-blue-300 mr-4 mt-4'}
          divStyle={'w-full pt-8'}
        />
        {services['events'] && (
          <NavGroup toggleElement={toggleElement} group={'events'} />
        )}
        {services['leadCollection'] && (
          <NavGroup toggleElement={toggleElement} group={'leads'} />
        )}
        {services['certificates'] && (
          <NavGroup toggleElement={toggleElement} group={'certs'} />
        )}
        {services['invoicing'] && (
          <NavGroup toggleElement={toggleElement} group={'invoicing'} />
        )}
      </div>
      <div className={bottomOfNavigation}>
        <NavItem link="/account" message={messages.account}>
          <VscAccount size={20} className={iconNavClass} />
        </NavItem>
        <NavItemWithButton onClick={logout} message={messages.logout}>
          <VscSignOut size={20} className={iconNavClass} />
        </NavItemWithButton>
      </div>
    </>
  );
};
