import { MappedEmailData } from '../certs/certReducer';
import { EmailKey, EmailKeyHtml, RequestHeader } from '../types/types';
import * as config from './config';
import { axiosInstance } from './interceptors';
import storeService from './storage';

const toApi = (
  data: Partial<Record<EmailKey, MappedEmailData>>,
  htmlData: HtmlData
) =>
  Object.keys(data).reduce<Partial<Record<EmailKey | EmailKeyHtml, string>>>(
    (emailData, emailKey) => {
      return { ...emailData, [emailKey]: data[emailKey].value };
    },
    htmlData
  );

export type MessageSendResponse = {
  status: 'sent';
  sentMail: number;
  certOperationId: string;
};

export type HtmlData = {
  emailBody: string;
  emailAttachmentBody?: string;
};
const sendMessage = async (
  data: Array<Partial<Record<EmailKey, MappedEmailData>>>,
  htmlData: HtmlData
): Promise<{ data: MessageSendResponse; status: number }> => {
  const mapped = data.map((m) => toApi(m, htmlData));
  const url = `${config.baseUrl}/comms/sendmail`;
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  return await axiosInstance({ method: 'POST', url, data: mapped, headers });
};

const messageService = {
  sendMessage,
};

export default messageService;
