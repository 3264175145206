import { useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import storeService from '../services/storage';
import { HeaderObject } from '../types/types';
import {
  getPageList,
  getUniqueKeysForTable,
  insert,
  mapFromKeys,
  move,
  searchArrayByValue,
  sortByKey,
  toggleKey,
} from '../utils/functions';

export interface TableStateProps {
  tableName: string;
  currentPage: number;
  totalPages: number;
  pageLimit: number;
  totalRecords: number;
  pageNeighbours: number;
  tableList: { [key: string]: any }[];
  filteredList: { [key: string]: any }[];
  currentList: { [key: string]: any }[];
  filterKeys: string[];
  searchWord: string;
  sortKey: string;
  ascending: boolean;
  possibleSearchKeys: string[];
  incomingHeaders: string[];
  currentHeaders: string[];
  headers: {
    droppableColumnsSelected: string[];
    droppableColumnsAll: string[];
  };
  export: {
    exportAll: {
      data: { [key: string]: any }[];
      filename: string;
      label: string;
    };
    exportView: {
      data: { [key: string]: any }[];
      filename: string;
      label: string;
    };
  };
}

export function useTable({ ...p }: TableStateProps) {
  const [state, setState] = useState(p);

  const onDragEnd = (dragObject: DropResult) => {
    if (dragObject.destination) {
      if (
        dragObject.source.droppableId === dragObject.destination.droppableId
      ) {
        const updated = move(
          state.headers[dragObject.destination.droppableId],
          dragObject.source.index,
          dragObject.destination.index
        );
        const updatedHeaders: HeaderObject = {
          ...state.headers,
          [dragObject.destination.droppableId]: updated,
        };
        storeService.setHeaders(
          p.tableName,
          updatedHeaders.droppableColumnsSelected
        );
        const newExportedView =
          dragObject.destination.droppableId === 'droppableColumnsSelected'
            ? mapFromKeys(state.filteredList, updated)
            : state.export.exportView.data;
        setState({
          ...state,
          headers: updatedHeaders,
          export: {
            ...state.export,
            exportView: {
              ...state.export.exportView,
              data: newExportedView,
            },
          },
        });
      } else {
        const updatedSource: string[] = state.headers[
          dragObject.source.droppableId
        ].filter((header: string) => header !== dragObject.draggableId);
        const updatedDestination: string[] = insert(
          state.headers[dragObject.destination.droppableId],
          dragObject.destination.index,
          dragObject.draggableId
        );
        const updatedHeaders: HeaderObject = ({
          [dragObject.source.droppableId]: updatedSource,
          [dragObject.destination.droppableId]: updatedDestination,
        } as unknown) as HeaderObject;
        storeService.setHeaders(
          p.tableName,
          updatedHeaders.droppableColumnsSelected
        );
        const newExportedView = mapFromKeys(
          state.filteredList,
          updatedHeaders.droppableColumnsSelected
        );
        setState({
          ...state,
          headers: updatedHeaders,
          export: {
            ...state.export,
            exportView: {
              ...state.export.exportView,
              data: newExportedView,
            },
          },
        });
      }
    }
  };

  const gotoPage = (page: number) => {
    const currentList = getPageList(page, state.pageLimit, state.filteredList);
    const updated = {
      ...state,
      currentPage: page,
      currentList,
    };
    setState(updated);
  };

  const toggleFilter = (key: string) => {
    const updated = {
      ...state,
      filterKeys: toggleKey(key, state.filterKeys),
    };
    setState(updated);
  };

  const sort = (key: string) => {
    const offset = (state.currentPage - 1) * state.pageLimit;
    const sorted = sortByKey(key, state.ascending, state.filteredList);
    const newExportedView = mapFromKeys(
      sorted,
      state.headers.droppableColumnsSelected
    );
    const updated = {
      ...state,
      ascending: !state.ascending,
      sortKey: key,
      filteredList: sorted,
      currentList: sorted.slice(offset, offset + state.pageLimit),
      export: {
        ...state.export,
        exportView: {
          ...state.export.exportView,
          data: newExportedView,
        },
      },
    };
    setState(updated);
  };

  const clearFilters = () => {
    const offset = (state.currentPage - 1) * state.pageLimit;
    const filteredList = searchArrayByValue(p.tableList, '', []);
    const newExportedView = mapFromKeys(
      filteredList,
      state.headers.droppableColumnsSelected
    );
    const updated = {
      ...state,
      filteredList,
      searchWord: '',
      currentList: filteredList.slice(offset, offset + state.pageLimit),
      totalPages: Math.ceil(filteredList.length / state.pageLimit),
      filterKeys: [],
      export: {
        ...state.export,
        exportView: {
          ...state.export.exportView,
          data: newExportedView,
        },
      },
    };
    setState(updated);
  };

  const onSearchWordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const offset = (state.currentPage - 1) * state.pageLimit;
    const filteredList = searchArrayByValue(
      p.tableList,
      event.target.value,
      state.filterKeys
    );
    const newExportedView = mapFromKeys(
      filteredList,
      state.headers.droppableColumnsSelected
    );
    const updated = {
      ...state,
      searchWord: event.target.value,
      currentPage: 1,
      currentList: filteredList.slice(offset, offset + state.pageLimit),
      filteredList: filteredList,
      totalRecords: filteredList.length,
      totalPages: Math.ceil(filteredList.length / state.pageLimit),
      export: {
        ...state.export,
        exportView: {
          ...state.export.exportView,
          data: newExportedView,
        },
      },
    };
    setState(updated);
  };

  const changeHeadersAndTable = (
    tableList: { [key: string]: any }[],
    incomingHeaders: string[]
  ) => {
    const offset = (state.currentPage - 1) * state.pageLimit;

    const filteredList =
      state.filterKeys.length > 0 || state.searchWord.length > 0
        ? sortByKey(
            state.sortKey,
            !state.ascending,
            searchArrayByValue(tableList, state.searchWord, state.filterKeys)
          )
        : sortByKey(state.sortKey, !state.ascending, tableList);

    return setState({
      ...state,
      tableList,
      filteredList,
      totalRecords: tableList.length,
      possibleSearchKeys: getUniqueKeysForTable(filteredList),
      currentHeaders: incomingHeaders,
      currentList: filteredList.slice(offset, offset + state.pageLimit),
      totalPages: Math.ceil(filteredList.length / state.pageLimit),
      headers: {
        droppableColumnsSelected: incomingHeaders,
        droppableColumnsAll: getUniqueKeysForTable(tableList).filter(
          (item) => !p.currentHeaders.includes(item)
        ),
      },
      export: {
        exportAll: {
          ...state.export.exportAll,
          data: mapFromKeys(tableList, getUniqueKeysForTable(tableList)),
        },
        exportView: {
          ...state.export.exportView,
          data: mapFromKeys(filteredList, incomingHeaders),
        },
      },
    });
  };

  return {
    state,
    onDragEnd,
    onSearchWordChange,
    sort,
    clearFilters,
    toggleFilter,
    gotoPage,
    changeHeadersAndTable,
  };
}
