import { PasswordChangeObject } from '../services/user';

const startPasswordChange = (payload: PasswordChangeObject) => {
  return {
    type: 'START_PASSWORD_CHANGE',
    payload,
  };
};

const userActions = {
  startPasswordChange,
};

export default userActions;
