import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InvoiceErrorsPreview } from '../../reducers/invoiceReducer';
import { messages } from '../translations';

type Props = {
  validations: InvoiceErrorsPreview[];
};

export const ErrorsValidationList: React.FC<Props> = ({ validations }) => {
  const message =
    validations.length > 0 ? messages.errorsvalidation : messages.errorsvalok;
  const { formatMessage } = useIntl();
  return (
    <div>
      <span className="text-xl text-brand-dark_blue py-2">
        2. <FormattedMessage {...message} />
      </span>
      {validations.map((v, index) => {
        const emoji = v.type === 'Warning' ? '⚠️ ' : '❗';
        const textColor =
          v.type === 'Warning' ? 'text-yellow-700' : 'text-red-500';
        return (
          <div key={`errorsvalidation_${index}`}>
            <span
              className={`mx-2 
              ${textColor}
            `}
            >
              {emoji}
              <FormattedMessage
                id={v.message}
                values={{
                  count: v.count,
                  name: formatMessage({ id: v.name }),
                  bold: (name: string) => <b>{name}</b>,
                }}
              />
            </span>
          </div>
        );
      })}
    </div>
  );
};
