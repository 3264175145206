import { Loop, Cmd, loop, LoopReducer } from 'redux-loop';
import { ActionType, createAsyncAction, getType } from 'typesafe-actions';
import { loginAsync, logout } from '../login/loginReducer';
import userService, {
  PasswordChangeResponse,
  PasswordChangeObject,
} from '../services/user';
import { UserState, User, Services, Customer } from '../types/types';
import { AppState } from './combineReducer';

const initialState: UserState = {
  user: {
    customerId: '',
    userEmail: '',
    userFirstName: '',
    userLastName: '',
    userId: '',
    userStatus: '',
    userType: '',
  },
  eregUrl: '',
  customer: {
    customerBillingAddressCity: '',
    customerBillingAddressCountry: '',
    customerBillingAddressStreet: '',
    customerBillingAddressZip: '',
    customerBillingOvt: '',
    customerBillingOvtCarrier: '',
    customerBusinessId: '',
    customerContactPersonEmail: '',
    customerContactPersonName: '',
    customerContactPersonPhoneNumber: '',
    customerId: '',
    customerName: '',
    customerSettings: {
      general: {
        enabledServices: {
          events: false,
          leadCollection: false,
          certificates: false,
          invoicing: false,
          email: false,
          emailWithAttachments: false,
        },
        allowedSenderEmails: [],
        someOtherSetting: null,
      },
      eventRegistrar: {
        invoicingStateOptions: [],
        customStateOptions: [],
      },
      eventCreation: {
        linkCustomerPrefix: null,
        pageStyling: {
          backgroundColor: null,
          logoUrl: null,
        },
      },
      postmark: {
        asetus1: 1,
      },
    },
  },
};

export const changePassword = createAsyncAction(
  'START_PASSWORD_CHANGE',
  'PASSWORD_CHANGE_COMPLETE',
  'PASSWORD_CHANGE_FAIL'
)<
  PasswordChangeObject,
  { data: PasswordChangeResponse; status: number },
  Error
>();

type Action =
  | ActionType<typeof loginAsync>
  | ActionType<typeof logout>
  | ActionType<typeof changePassword>;

export const userReducer: LoopReducer<UserState, Action> = (
  state: UserState = initialState,
  action: Action
): UserState | Loop<UserState> => {
  switch (action.type) {
    case getType(loginAsync.success):
      if (action.payload.status === 200) {
        return {
          ...state,
          user: action.payload.data.user,
          customer: action.payload.data.customer,
          eregUrl: action.payload.data.eregUrl,
          lastLogin: action.payload.data.lastLogin,
        };
      }
      return state;

    case getType(changePassword.request):
      return loop(
        state,
        Cmd.run(userService.changePassword, {
          successActionCreator: changePassword.success,
          failActionCreator: changePassword.failure,
          args: [action.payload],
        })
      );

    case getType(changePassword.success):
      if (action.payload.status === 200) {
        return state;
      }
      return state;

    case getType(changePassword.failure):
      return state;

    //logout
    case getType(logout):
      return initialState;

    default:
      return state;
  }
};

export function getUser(state: AppState): string {
  return `${state.userState.user.userFirstName} ${state.userState.user.userLastName}`;
}

export function selectUserData(state: AppState): User {
  return state.userState.user;
}

export const selectServices = (state: AppState): Services => {
  return state.userState.customer.customerSettings.general.enabledServices;
};

export const selectCustomerData = (state: AppState): Customer => {
  return state.userState.customer;
};
