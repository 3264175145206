import React from 'react';
import { CertErrorsPreview, MissingValidationObject } from '../certReducer';
import { Receivers } from './Receivers';
import { CertSendStatus } from './CertSendStatus';
import { ErrorsMissingList } from './ErrorsMissingList';
import { ErrorsValidationList } from './ErrorsValidationList';

type Props = {
  errors: {
    missing: MissingValidationObject[];
    validations: CertErrorsPreview[];
  };
  amountOfReceivers: number;
};
export const CertErrorsCard: React.FC<Props> = ({
  errors,
  amountOfReceivers,
}) => {
  const err =
    errors.missing.length + errors.validations.length > 0 ||
    amountOfReceivers === 0;
  return (
    <div className="rounded w-full h-5/6 flex flex-col justify-around m-4 text-center overflow-auto scroller">
      <Receivers amountOfReceivers={amountOfReceivers} type="cert" />
      <ErrorsMissingList missing={errors.missing} />
      <ErrorsValidationList validations={errors.validations} />
      <CertSendStatus errors={err} />
    </div>
  );
};
