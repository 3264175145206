import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../actions/allActions';
import * as eState from '../../eventReducer';
import * as t from '../translations/stylingTranslations';

import { FormColor } from '../../../components/form/FormColor';
import { FormInput } from '../../../components/form/FormInput';
import { FormSelect } from '../../../components/form/FormSelect';
import { FormTextArea } from '../../../components/form/FormTextArea';
import { AiOutlineMail } from 'react-icons/ai';
import { Toggle } from '../../../components/utils/Toggle';
import { BackDrop } from '../../../components/tableSearchBar/BackDrop';
import { MailerTemplateModal } from './MailerTemplateModal';

interface PageStylingProps {}

export const PageStylingForm: React.FC<PageStylingProps> = () => {
  const {
    pageDescription,
    pageHeader,
    pageRegistrationFullHeader,
    pageRegistrationFullText,
    pageThankYouHeader,
    pageRegistrationNotYetOpenText,
    pageThankYouText,
    pageRegistrationEndedHeader,
    pageRegistrationEndedText,
    pageRegistrationNotYetOpenHeader,
    backgroundColor,
    ticketOptions,
    mailerOptions,
    ticketDescription,
    mailDescription,
    mailerQuestions,
    selectedTicket,
    selectedMailer,
  } = useSelector(eState.selectRegistrationPageStylingData);
  const dispatch = useDispatch();
  const rowDiv = 'flex justify divide-x';
  const inputStyle =
    'text-brand-dark_blue focus:outline-none focus:bg-blue-100 focus:shadow-inner border border-gray-300 rounded-lg py-2 px-4 m-4 appearance-none leading-normal cursor-pointer';

  const { formatMessage } = useIntl();
  const handleNestedItemChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | string,
    id: string
  ) => {
    const value = typeof event === 'string' ? event : event.target.value;
    const itemValues = id.split(':');
    dispatch(
      allActions.eventActions.changePageStyling(
        itemValues[1],
        value,
        itemValues[0]
      )
    );
  };

  const changeTemplate = (
    event: React.ChangeEvent<HTMLSelectElement>,
    id: 'ticketTemplates' | 'mailerTemplates'
  ) => {
    dispatch(
      allActions.eventActions.setRegistrationTemplate(id, event.target.value)
    );
  };
  const mailerOptionsButtonVisibility =
    mailerQuestions.length > 0 ? 'visible' : 'invisible';

  const mailerModalId = 'REGISTRATION_MAILER_TEMPLATE_MODAL';
  const selectDiv = 'flex flex-col mb-4 w-full';

  const toggleMailerModal = () =>
    dispatch(allActions.toggleActions.toggle(mailerModalId));

  return (
    <>
      <BackDrop modalIds={[mailerModalId]} growIndex={false} />
      <div className="flex flex-col justify-evenly w-full divide-y">
        <Toggle id={mailerModalId}>
          <MailerTemplateModal />
        </Toggle>
        <div className="flex justify divide-x">
          <div className="w-full">
            <FormSelect
              question="lippu"
              cursiveLabel={ticketDescription}
              divStyle={selectDiv}
              options={ticketOptions}
              id="ticketTemplates"
              value={selectedTicket?.ticketTemplateId}
              handleInputChange={changeTemplate}
            />
          </div>
          <div className="w-full flex">
            <FormSelect
              question="sahkoposti"
              cursiveLabel={mailDescription}
              divStyle={selectDiv}
              options={mailerOptions}
              id="mailerTemplates"
              value={selectedMailer?.mailTemplateId}
              handleInputChange={changeTemplate}
            />
            <div
              className={`${mailerOptionsButtonVisibility} self-center hover:text-brand-blue cursor-pointer pr-2`}
            >
              <AiOutlineMail size={22} onClick={toggleMailerModal} />
            </div>
          </div>
        </div>
        <div className="flex justify divide-x">
          <FormInput
            value={pageHeader || ''}
            inputStyle={inputStyle}
            question={formatMessage({ ...t.messages.pageheader })}
            handleInputChange={handleNestedItemChange}
            id="pageHeader:pageMessages"
            type="text"
          />
          <FormTextArea
            value={pageDescription || ''}
            inputStyle={inputStyle}
            question={formatMessage({ ...t.messages.pagedescription })}
            handleInputChange={handleNestedItemChange}
            id="pageDescription:pageMessages"
          />
        </div>
        <div className={rowDiv}>
          <FormInput
            value={pageThankYouHeader || ''}
            inputStyle={inputStyle}
            question={formatMessage({ ...t.messages.PageThankYouHeader })}
            handleInputChange={handleNestedItemChange}
            id="pageThankYouHeader:pageMessages"
            type="text"
          />
          <FormTextArea
            value={pageThankYouText || ''}
            inputStyle={inputStyle}
            question={formatMessage({ ...t.messages.pagethankyoutext })}
            handleInputChange={handleNestedItemChange}
            id="pageThankYouText:pageMessages"
          />
        </div>
        <div className={rowDiv}>
          <FormInput
            value={pageRegistrationFullHeader || ''}
            inputStyle={inputStyle}
            question={formatMessage({
              ...t.messages.pageregistrationfullheader,
            })}
            handleInputChange={handleNestedItemChange}
            id="pageRegistrationFullHeader:pageMessages"
            type="text"
          />
          <FormTextArea
            value={pageRegistrationFullText || ''}
            inputStyle={inputStyle}
            question={formatMessage({ ...t.messages.PageRegistrationFullText })}
            handleInputChange={handleNestedItemChange}
            id="pageRegistrationFullText:pageMessages"
          />
        </div>
        <div className={rowDiv}>
          <FormInput
            value={pageRegistrationNotYetOpenHeader || ''}
            inputStyle={inputStyle}
            question={formatMessage({
              ...t.messages.PageRegistrationNotYetOpenHeader,
            })}
            handleInputChange={handleNestedItemChange}
            id="pageRegistrationNotYetOpenHeader:pageMessages"
            type="text"
          />
          <FormTextArea
            value={pageRegistrationNotYetOpenText || ''}
            inputStyle={inputStyle}
            question={formatMessage({
              ...t.messages.PageRegistrationNotYetOpenText,
            })}
            handleInputChange={handleNestedItemChange}
            id="pageRegistrationNotYetOpenText:pageMessages"
          />
        </div>
        <div className={rowDiv}>
          <FormInput
            value={pageRegistrationEndedHeader || ''}
            inputStyle={inputStyle}
            question={formatMessage({
              ...t.messages.PageRegistrationEndedHeader,
            })}
            handleInputChange={handleNestedItemChange}
            id="pageRegistrationEndedHeader:pageMessages"
            type="text"
          />
          <FormTextArea
            value={pageRegistrationEndedText || ''}
            inputStyle={inputStyle}
            question={formatMessage({
              ...t.messages.PageRegistrationEndedText,
            })}
            handleInputChange={handleNestedItemChange}
            id="pageRegistrationEndedText:pageMessages"
          />
        </div>
        <div className={rowDiv}>
          <FormColor
            value={backgroundColor || '#ff0000'}
            question={formatMessage({ ...t.messages.backgroundcolor })}
            handleInputChange={handleNestedItemChange}
            id="backgroundColor:pageStyling"
          />
        </div>
      </div>
    </>
  );
};

export default PageStylingForm;
