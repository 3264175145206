import React from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { EventParamTypes, FormattedMessageProps } from '../../../types/types';
import { SubEventTable } from '../components/subEventTable/SubEventTable';
import { selectSubEvents } from '../../listEvents/listEventsReducer';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../history/history';
import BreadCrumb from '../../../components/breadcrumbs/BreadCrumb';
import { BreadRoute } from '../../../components/breadcrumbs/BreadRoute';
import allActions from '../../../actions/allActions';
import { selectLoadingMultiple } from '../../../reducers/loadingReducer';
import * as uState from '../../../reducers/userReducer';
import storeService from '../../../services/storage';
import { AppState } from '../../../reducers/combineReducer';
import { CreateSubEventModal } from '../components/CreateSubEventModal';
import { Toggle } from '../../../components/utils/Toggle';
import { SubEventDeleteDialog } from '../components/SubEventDeleteDialog';

interface Props {}

const alertMessage: FormattedMessageProps = {
  id: 'subevent.dialog.delete.message',
  defaultMessage: 'Are you sure you want to delete?',
};

export const SubEvents: React.FC<Props> = () => {
  const { id } = useParams<EventParamTypes>();
  const { list, headerRow } = useSelector((state: AppState) =>
    selectSubEvents(state, id)
  );
  const isLoading = useSelector((state: AppState) =>
    selectLoadingMultiple(state, [
      'createSubEvent',
      'getEvents',
      'deleteSubEvent',
    ])
  );
  const { userId } = useSelector(uState.selectUserData);
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const tableName = `subevents_${id}_${userId}`;
  const savedHeaders = storeService.getHeaders(tableName);
  const headers = savedHeaders.length > 0 ? savedHeaders : headerRow;
  const newSubEventModalToggleId = 'CREATE_SUBEVENT_MODAL';
  const subEventDeleteModal = 'SUBEVENT_DELETE_MODAL';
  const [selectedSubEvent, setSelectedSubEvent] = React.useState('');

  const openSubEvent = (subEventId: string) => {
    history.push(`/events/${id}/subevents/${subEventId}`);
  };

  const createSubEvent = (_type: 'frontPage' | 'normal') => {
    dispatch(allActions.eventActions.createSubEvent(id));
  };

  const toggleCreateSubEventModal = () => {
    dispatch(allActions.toggleActions.toggle(newSubEventModalToggleId));
  };

  const toggleDeleteModal = (subEventId: string) => {
    if (subEventId && subEventId !== subEventDeleteModal) {
      setSelectedSubEvent(subEventId);
    }
    dispatch(allActions.toggleActions.toggle(subEventDeleteModal));
  };

  const deleteSubEvent = (subEventId: string) => {
    dispatch(allActions.toggleActions.toggle(subEventDeleteModal));
    dispatch(allActions.eventActions.deleteSubEvent(subEventId));
  };

  return (
    <div className="w-11/12 h-screen mx-auto pr-4">
      <BreadRoute>
        <BreadCrumb label={'event-menu'} link={'/eventmenu'} />
        <BreadCrumb label={'events'} link={'/events'} />
        <BreadCrumb label={`event ${id}`} link={`/events/${id}`} />
        <BreadCrumb label={`subevents`} link={`${url}`} lastChild={true} />
      </BreadRoute>
      <SubEventTable
        open={openSubEvent}
        createSubEvent={toggleCreateSubEventModal}
        headerRow={headers}
        tableList={list}
        loading={isLoading}
        tableName={tableName}
        newSubEventModalToggleId={newSubEventModalToggleId}
        subEventDeleteModal={subEventDeleteModal}
        toggleDeleteModal={toggleDeleteModal}
      />
      <Toggle id={newSubEventModalToggleId}>
        <CreateSubEventModal
          createSubEvent={createSubEvent}
          closeModal={toggleCreateSubEventModal}
        />
      </Toggle>
      <Toggle id={subEventDeleteModal}>
        <SubEventDeleteDialog
          message={alertMessage}
          alertId={subEventDeleteModal}
          itemId={selectedSubEvent}
          alertAction={deleteSubEvent}
          alertCancel={toggleDeleteModal}
        />
      </Toggle>
    </div>
  );
};

export default SubEvents;
