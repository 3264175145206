import React from 'react';
import { FormattedMessage } from 'react-intl';
import { destinationMaps } from '../invoiceMappings';

type Props = {
  rowData: { [key: string]: string };
};

export const InvoicePreviewRowItem: React.FC<Props> = ({ rowData }) => {
  const map = destinationMaps.Fennoa;
  const keyStyle = 'text-xl font-semibold text-brand-dark_blue m-2';
  const valueStyle = 'text-xl text-brand-dark_blue m-2';
  const noPreview = (k: string) => !k.startsWith('dim') && k !== 'uuid';
  return (
    <>
      {Object.keys(rowData)
        .filter(noPreview)
        .map((kv) => {
          const correctKey = `row_${kv}`;
          const name = map[correctKey].name;
          return (
            <div key={`invoiceitemrowvalue_${kv}`}>
              <span className={keyStyle}>
                <FormattedMessage {...{ id: name, defaultMessage: kv }} />
              </span>
              <span className={valueStyle}>{rowData[kv]}</span>
            </div>
          );
        })}
    </>
  );
};
