import React from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { EventParamTypes } from '../../../types/types';
import { BreadRoute } from '../../../components/breadcrumbs/BreadRoute';
import { BreadCrumb } from '../../../components/breadcrumbs/BreadCrumb';
import Card from '../../../components/utils/Card';
import { messages } from '../translations/eventDetailsCards';
import * as imgs from '../../../img/images';

export const EventDetailsPage: React.FC = () => {
  const { id } = useParams<EventParamTypes>();
  let { url } = useRouteMatch();

  return (
    <div className="w-11/12 h-screen mx-auto pr-4">
      <BreadRoute>
        <BreadCrumb label={'event-menu'} link={'/eventmenu'} />
        <BreadCrumb label={'events'} link={'/events'} />
        <BreadCrumb
          label={`event ${id}`}
          link={`/events/${id}`}
          lastChild={true}
        />
      </BreadRoute>
      <div className="h-full max-h-11/12 w-full flex justify-center">
        <Card
          header={{ ...messages.subeventsheader }}
          paragraph={{ ...messages.subeventstext }}
          linkMessage={{ ...messages.subeventslink }}
          link={`${url}/subevents`}
          image={imgs.EVENT_CARD_SUBEVENTS}
          canClick={true}
        />
        <Card
          header={{ ...messages.participantheader }}
          paragraph={{ ...messages.participanttext }}
          link={`${url}/participants`}
          linkMessage={{ ...messages.participantlink }}
          image={imgs.EVENT_CARD_PARTICIPANTS}
          canClick={true}
        />
        <Card
          header={{ ...messages.detailsheader }}
          paragraph={{ ...messages.detailstext }}
          link={`${url}/details`}
          linkMessage={{ ...messages.detaillink }}
          image={imgs.EVENT_CARD_DETAILS}
          canClick={true}
        />
      </div>
    </div>
  );
};
