import React from 'react';
import allActions from '../actions/allActions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../reducers/combineReducer';
import { Toggle } from '../components/utils/Toggle';
import { MinimizedNavigation } from './MinimizedNavBar';
import { ExpandedNavigationBar } from './ExpandedNavigationBar';
import { NavBallToggler } from './NavBallToggler';

export const Navbar: React.FC = () => {
  const navToggle = 'NAVBAR_TOGGLE';
  const toggleState = useSelector((state: AppState) => state.toggleState);
  const navWidth = toggleState[navToggle] ? 'pr-8' : '';
  const navTogglerPosition = 'absolute -right-4 z-10 top-16';
  const dispatch = useDispatch();

  const toggleElement = (id: string) => {
    if (toggleState[id]) {
      dispatch(allActions.toggleActions.hideToggle(id));
    } else {
      dispatch(allActions.toggleActions.showToggle(id));
    }
  };

  const logOut = () => {
    sessionStorage.clear();
    dispatch(allActions.loginActions.logout());
  };

  return (
    <div className={`${navWidth} flex flex-row`}>
      <nav
        className={`p-2 transition transform bg-brand-dark_blue pr-6 pt-2 pl-3 h-screen flex flex-col shadow-r justify-between items-center`}
        style={{
          transition: 'all 120ms ease-out',
          width: toggleState[navToggle] ? '230px' : '60px',
        }}
      >
        <Toggle id={navToggle} reverse={true}>
          <MinimizedNavigation logout={logOut} />
        </Toggle>
        <Toggle id={navToggle}>
          <ExpandedNavigationBar
            toggleElement={toggleElement}
            logout={logOut}
          />
        </Toggle>
        <NavBallToggler
          position={navTogglerPosition}
          expanded={toggleState[navToggle]}
          toggleElement={toggleElement}
          toggleId={navToggle}
        />
      </nav>
    </div>
  );
};

export default Navbar;
