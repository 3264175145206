import React from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from '../translations/confirmsend';

type Props = {
  sendEmail: () => void;
  hasErrors: boolean;
};

export const EmailSend: React.FC<Props> = ({ sendEmail, hasErrors }) => {
  const buttonColor = hasErrors
    ? 'bg-gray-500 cursor-default'
    : 'bg-blue-400 hover:bg-brand_blue';

  const textColor = 'text-brand-dark_blue';

  const emoji = hasErrors ? '❗' : '✅';

  const sendMessage = hasErrors ? messages.emailcantsend : messages.emailsend;

  return (
    <div className="p-4">
      <span className={`text-xl py-2 ${textColor}`}>
        4. <FormattedMessage {...messages.emailsending} /> {emoji}
      </span>
      <div className={`p-2 ${textColor}`}>
        <FormattedMessage {...sendMessage} />
      </div>
      <div>
        <button
          className={`m-4 p-4 mx-auto rounded text-white font-thin ${buttonColor}`}
          disabled={hasErrors}
          onClick={sendEmail}
        >
          <FormattedMessage {...messages.sendbutton} />
        </button>
      </div>
    </div>
  );
};
