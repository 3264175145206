import { LoopReducer, loop, Cmd } from 'redux-loop';
import {
  ActionType,
  getType,
  createAction,
  createAsyncAction,
} from 'typesafe-actions';
import { convertParticipantToListFormat, extractId } from '../utils/functions';
import { AppState } from './combineReducer';
import { getParticipants } from './participantReducer';
import {
  DeliveryMethods,
  destinationMaps,
  Inheritance,
  InputDetails,
  InputValidation,
} from '../invoicing/invoiceMappings';
import invoiceService from '../services/invoicing';
import { InvoiceValues } from '../invoicing/components/InvoicePreviewItem';
import { historyService } from '../services/historyService';
import { ImportCSVData } from '../types/types';
import { v4 as uuidv4 } from 'uuid';
import { IntlMessageID } from '..';
import { convertToAlvNumber, convertToBusinessId } from '../utils/functions';

export type FennoaProduct = {
  tuoteKoodi: string;
  tuoteNimiSuomeksi: string;
  tuoteSelite: string;
  tuoteYksikkoSuomeksi: string;
  tuoteOletusmMyntiHinta: string;
  tuoteAlv: string;
  tuoteDim: {
    dimType: string;
    dimValue: string;
  };
};
export type SalesInvoice = {
  SalesInvoice: {
    id: string;
    invoice_no: string;
    invoice_type_id: string;
    auxiliary_name_id: string;
    customer_id: string;
    name: string;
    name2: string;
    address: string;
    postalcode: string;
    city: string;
    total_net: string;
    total_vat: string;
    total_paid: string;
    total_due: string;
    banking_reference: string;
    invoice_date: string;
    due_date: string;
    created: string;
    approved: string;
  };
};
export type SourceOption = '' | 'ContactMate' | 'CSV-import';
export type InvoiceOperator = 'Fennoa';
export type InvoiceState = {
  selectedSource: SourceOption;
  destination: InvoiceOperator;
  selectedEvent: string;
  eventParticipants: any[];
  selectedParticipants: string[];
  destinationMapping: { [key: string]: InputDetails };
  multiSend: boolean;
  fennoaProducts?: FennoaProduct[];
  fennoaInvoices?: SalesInvoice[];
};
const initialState: InvoiceState = {
  selectedSource: '',
  destination: 'Fennoa',
  selectedEvent: '',
  eventParticipants: [],
  selectedParticipants: [],
  multiSend: false,
  destinationMapping: destinationMaps.Fennoa,
};

const changeEventSource = createAction(
  'INVOICE_CHANGE_EVENT_SOURCE'
)<SourceOption>();

const toggleMultisend = createAction('INVOICING_TOGGLE_MULTISEND')<undefined>();

const setDefaultFennoaProductValues = createAction(
  'SET_DEFAULT_FENNOA_PRODUCT_VALUES'
)<string>();

const toggleRecipient = createAction('TOGGLE_INVOICE_RECIPIENT')<string>();

const importReceivers = createAction('IMPORT_INVOICE_RECEIVERS')<{
  data: ImportCSVData[];
  headers: boolean;
}>();

const toggleAllRecipients = createAction('TOGGLE_ALL_INVOICE_RECIPIENTS')<
  string[]
>();

const setMapping = createAction('SET_INVOICE_MAPPING')<{
  mapping: { [key: string]: InputDetails };
  destination: InvoiceOperator;
}>();

const changeInheritance = createAction(
  'CHANGE_INVOICE_MAPPING_INHERITANCE'
)<string>();

const changeEvent = createAction('CHANGE_EVENT_INVOICE')<{
  eventId: string;
  eventSource: SourceOption;
  eventName: string;
}>();

export const invoiceSend = createAsyncAction(
  'START_INVOICE_SEND',
  'INVOICE_SEND_COMPLETE',
  'INVOICE_SEND_FAIL'
)<InvoiceValues[], { data: any; status: number }, Error>();

export const getFennoaInvoices = createAsyncAction(
  'START_GET_FENNOA_INVOICES',
  'GET_FENNOA_INVOICES_COMPLETE',
  'GET_FENNOA_INVOICES_FAIL'
)<
  undefined,
  { data: { data: SalesInvoice[]; status: string }; status: number },
  Error
>();

export const getFennoaProducts = createAsyncAction(
  'START_GET_FENNOA_PRODUCTS',
  'GET_FENNOA_PRODUCTS_COMPLETE',
  'GET_FENNOA_PRODUCTS_FAIL'
)<undefined, { data: FennoaProduct[]; status: number }, Error>();

type Action =
  | ActionType<typeof changeEventSource>
  | ActionType<typeof getParticipants>
  | ActionType<typeof changeEvent>
  | ActionType<typeof toggleRecipient>
  | ActionType<typeof toggleAllRecipients>
  | ActionType<typeof setMapping>
  | ActionType<typeof changeInheritance>
  | ActionType<typeof invoiceSend>
  | ActionType<typeof importReceivers>
  | ActionType<typeof getFennoaProducts>
  | ActionType<typeof setDefaultFennoaProductValues>
  | ActionType<typeof getFennoaInvoices>
  | ActionType<typeof toggleMultisend>;

export const invoiceReducer: LoopReducer<InvoiceState, Action> = (
  state: InvoiceState = initialState,
  action: Action
) => {
  switch (action.type) {
    case getType(changeEventSource):
      return {
        ...state,
        selectedSource: action.payload,
        multiSend: action.payload === '' ? false : true,
      };

    case getType(changeEvent):
      return loop(
        state,
        Cmd.action({
          type: 'GET_EVENT_PARTICIPANTS',
          payload: {
            id: action.payload.eventId,
            name: action.payload.eventName,
          },
        })
      );

    case getType(getParticipants.request):
      const name = action.payload.name ? action.payload.name : null;
      return {
        ...state,
        selectedEvent: name,
      };

    case getType(getParticipants.success):
      if (!state.selectedEvent) {
        return {
          ...state,
          selectedParticipants: initialState.selectedParticipants,
          eventParticipants: initialState.eventParticipants,
        };
      }

      const eventParticipants = action.payload.data.map((p) =>
        convertParticipantToListFormat(p)
      );
      const selectedEventParticipants = eventParticipants.map((p) => p.uuid);
      return {
        ...state,
        eventParticipants: eventParticipants,
        selectedParticipants: selectedEventParticipants,
      };

    case getType(getParticipants.failure):
      return {
        ...state,
        eventParticipants: initialState.eventParticipants,
        selectedParticipants: initialState.selectedParticipants,
      };

    case getType(toggleMultisend):
      return {
        ...state,
        multiSend: !state.multiSend,
      };

    case getType(setDefaultFennoaProductValues):
      const emptyProduct = {
        tuoteKoodi: '',
        tuoteNimiSuomeksi: '',
        tuoteSelite: '',
        tuoteYksikkoSuomeksi: '',
        tuoteOletusmMyyntiHinta: '',
        tuoteAlv: '',
        tuoteDim: {
          dimType: '',
          dimValue: '',
        },
      };

      const productCode = extractId(action.payload);
      const fennoaProduct = state.fennoaProducts
        ? state.fennoaProducts.find((p) => p.tuoteKoodi === productCode)
        : emptyProduct;

      const defaultDims = {
        ...(fennoaProduct.tuoteDim && {
          dim_type: fennoaProduct.tuoteDim.dimType,
        }),
        ...(fennoaProduct.tuoteDim && {
          dim_value: fennoaProduct.tuoteDim.dimValue,
        }),
      };

      const productMappings = Object.keys(state.destinationMapping)
        .map((k) => state.destinationMapping[k])
        .filter((inp) => inp.productCode);

      const defaultProductValues = productMappings.reduce((acc, curr) => {
        const v =
          curr.productCode.counterPart === 'tuoteKoodi'
            ? action.payload
            : curr.productCode.counterPart === 'tuoteKustannuspaikka'
            ? fennoaProduct.tuoteKoodi.substring(0, 3)
            : fennoaProduct[curr.productCode.counterPart]
            ? fennoaProduct[curr.productCode.counterPart]
            : '';
        return {
          ...acc,
          [curr.productCode.type]: v,
        };
      }, defaultDims);

      return loop(
        state,
        Cmd.action({
          type: 'SET_AND_PRESERVE_FORM_VALUES',
          payload: { id: 'newInvoice', data: defaultProductValues },
        })
      );

    case getType(toggleRecipient):
      return {
        ...state,
        selectedParticipants: state.selectedParticipants.includes(
          action.payload
        )
          ? state.selectedParticipants.filter((uuid) => uuid !== action.payload)
          : state.selectedParticipants.concat(action.payload),
      };

    case getType(toggleAllRecipients):
      const uuids = state.eventParticipants.map((p) => p.uuid);
      const selected = uuids.filter((p) => action.payload.includes(p));
      const reverseSelected = uuids.filter((p) => !action.payload.includes(p));
      return {
        ...state,
        selectedParticipants:
          state.selectedParticipants.length > 0 ? reverseSelected : selected,
      };

    case getType(setMapping):
      const updatedState = {
        ...state,
        destinationMapping: action.payload.mapping,
        destination: action.payload.destination,
      };
      if (action.payload.destination === 'Fennoa') {
        return loop(
          updatedState,
          Cmd.action({ type: 'START_GET_FENNOA_PRODUCTS' })
        );
      }
      return updatedState;

    case getType(changeInheritance):
      const inheritance: Inheritance =
        state.destinationMapping[action.payload].mapping === 'Hardcoded'
          ? 'Inherited'
          : 'Hardcoded';
      return {
        ...state,
        destinationMapping: {
          ...state.destinationMapping,
          [action.payload]: {
            ...state.destinationMapping[action.payload],
            mapping: inheritance,
          },
        },
      };

    case getType(importReceivers):
      const dataToMap = action.payload.headers
        ? action.payload.data.slice(1)
        : action.payload.data;
      const receivers = dataToMap.map((imported) => {
        return Object.assign(
          {},
          Object.assign(
            { uuid: uuidv4() },
            ...imported.data.map((value: any, i: number) => {
              const key = action.payload.headers
                ? action.payload.data[0].data[i]
                : `Column_${i}`;
              return { [key]: value.trim() };
            })
          )
        );
      });
      return {
        ...state,
        sendMultiple: true,
        eventParticipants: receivers,
        selectedParticipants: [],
      };

    case getType(invoiceSend.request):
      const addedBusinessIds = action.payload.map((invoice) => {
        return {
          ...invoice,
          ...(invoice.vat_number && {
            vat_number: convertToBusinessId(
              convertToAlvNumber(invoice.vat_number)
            ),
          }),
          ...(invoice.vat_number && {
            business_id: convertToAlvNumber(invoice.vat_number),
          }),
        };
      }) as unknown as InvoiceValues[];
      return loop(
        state,
        Cmd.run(invoiceService.newInvoiceSend, {
          successActionCreator: invoiceSend.success,
          failActionCreator: invoiceSend.failure,
          args: [addedBusinessIds],
        })
      );

    case getType(invoiceSend.success):
      return loop(
        state,
        Cmd.run(historyService.goto, {
          args: ['/invoices'],
        })
      );

    case getType(invoiceSend.failure):
      return state;

    case getType(getFennoaProducts.request):
      return loop(
        state,
        Cmd.run(invoiceService.getFennoaProducts, {
          successActionCreator: getFennoaProducts.success,
          failActionCreator: getFennoaProducts.failure,
        })
      );

    case getType(getFennoaProducts.success):
      if (action.payload.status === 200) {
        return { ...state, fennoaProducts: action.payload.data };
      }
      return state;

    case getType(getFennoaProducts.failure):
      return state;

    case getType(getFennoaInvoices.request):
      return loop(
        state,
        Cmd.run(invoiceService.getAllFennoaInvoices, {
          successActionCreator: getFennoaInvoices.success,
          failActionCreator: getFennoaInvoices.failure,
        })
      );

    case getType(getFennoaInvoices.success):
      if (action.payload.status === 200) {
        return { ...state, fennoaInvoices: action.payload.data.data };
      }
      return state;

    case getType(getFennoaInvoices.failure):
      return state;

    default:
      return state;
  }
};

type MappingData = {
  personsQuestions: string[];
  productOptions: string[];
};
export const getSendMultipleData = (state: AppState): MappingData => {
  const selected =
    state.invoiceState.selectedParticipants.length > 0
      ? state.invoiceState.eventParticipants.filter((p) =>
          state.invoiceState.selectedParticipants.includes(p.uuid)
        )
      : state.invoiceState.eventParticipants;
  const personsQuestions = [''].concat(
    Object.keys(
      selected.reduce((result, obj) => {
        return Object.assign(result, obj);
      }, {})
    )
  );
  const productOptions = state.invoiceState.fennoaProducts
    ? state.invoiceState.fennoaProducts.map(
        (p) => `${p.tuoteNimiSuomeksi} :: ${p.tuoteKoodi}`
      )
    : [];
  return { personsQuestions, productOptions };
};

export const getSelectedParticipants = (state: AppState): string[] => {
  return state.invoiceState.selectedParticipants;
};

export const getInheritance = (
  state: AppState,
  question: string
): Inheritance => {
  return state.invoiceState.destinationMapping[question].mapping;
};

export type InvoicingPageData = InvoiceState & {
  events: { name: string; id: string }[];
  participantHeaders: string[];
};
export const selectInvoicePageData = (state: AppState): InvoicingPageData => {
  const events =
    state.listEventsState.events.length > 0
      ? state.listEventsState.events.map((e) => {
          return { name: `${e.eventName} :: ${e.eventId}`, id: e.eventId };
        })
      : [];
  const eventParticipants = state.invoiceState.eventParticipants;
  const participantHeaders =
    eventParticipants.length > 0
      ? Object.keys(eventParticipants[0]).length > 5
        ? Object.keys(eventParticipants[0]).slice(0, 5)
        : Object.keys(eventParticipants[0])
      : [];
  const values = state.formState.input.newInvoice;
  const deliveryMethod = values.delivery_method as unknown as DeliveryMethods;

  const filterByDeliveryMethod = (
    input: InputDetails,
    deliveryMethod: DeliveryMethods
  ) => {
    return (
      !input.showMethod ||
      (input.showMethod && input.showMethod === deliveryMethod)
    );
  };

  const filteredMapping = Object.keys(state.invoiceState.destinationMapping)
    .filter((key) =>
      filterByDeliveryMethod(
        state.invoiceState.destinationMapping[key],
        deliveryMethod
      )
    )
    .reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: state.invoiceState.destinationMapping[curr],
      };
    }, {});
  return {
    selectedSource: state.invoiceState.selectedSource,
    destination: state.invoiceState.destination,
    selectedEvent: state.invoiceState.selectedEvent,
    selectedParticipants: state.invoiceState.selectedParticipants,
    multiSend: state.invoiceState.multiSend,
    destinationMapping: filteredMapping,
    eventParticipants,
    participantHeaders,
    events,
  };
};

export type InvoiceErrors = {
  missing: string[];
  validations: InvoiceErrorsPreview[];
};
type InvoicePreviewData = {
  data: InvoiceValues[];
  errors: InvoiceErrors;
};
export const selectSendInvoiceData = (state: AppState): InvoicePreviewData => {
  const receivers = state.invoiceState.multiSend
    ? state.invoiceState.eventParticipants.filter((r) =>
        state.invoiceState.selectedParticipants.includes(r.uuid)
      )
    : [{}];

  const mapping = state.invoiceState.destinationMapping;

  const inputs = state.formState.input.newInvoice;

  const checkIfMissing = (
    value: string,
    inputs: { [key: string]: string | string[] }
  ) => {
    if (value.length === 0) {
      return true;
    }
    const strArr = value.split(':');
    const toCheck = inputs[strArr[0]];
    return strArr[1] === toCheck;
  };

  const missing = Object.keys(mapping).filter((key) => {
    const req = mapping[key].required;
    return typeof req === 'boolean'
      ? req && !inputs[key]
      : checkIfMissing(req, inputs) && !inputs[key];
  });

  const extractName = (r: Record<string, any>) => {
    return r.RegistrationFirstName && r.RegistrationLastName
      ? `${r.RegistrationFirstName} ${r.RegistrationLastName}`
      : '';
  };
  const getNotesBefore = (
    all: Array<Record<string, any>>,
    r: Record<string, any>,
    isPartOfGroup: boolean
  ) => {
    if (isPartOfGroup) {
      return all
        .filter((x) => x.groupUuid && x.groupUuid === r.groupUuid)
        .map((groupMember) => extractName(groupMember))
        .join(', ');
    }
    return extractName(r);
  };

  const data = receivers.map((r) => {
    const hasGroupUUid = 'groupUuid' in r;
    const isPartOfGroup = hasGroupUUid ? r.groupUuid.length > 0 : false;
    const groupid = isPartOfGroup ? r.groupUuid : null;
    const notesBefore = getNotesBefore(receivers, r, isPartOfGroup);
    return Object.keys(inputs)
      .filter((k) => !k.startsWith('dim_') && k !== 'delivery_method')
      .reduce(
        (acc, curr) => {
          const key = inputs[curr] as string;
          const req = mapping[curr].required;
          const reqStr = typeof req === 'boolean' ? '' : req;
          const shouldBe = checkIfMissing(reqStr, inputs);
          const v = r[key] ? r[key] : '';
          const value =
            mapping[curr].mapping === 'Hardcoded' ||
            mapping[curr].options ||
            !state.invoiceState.multiSend
              ? key.trim()
              : mapping[curr].productCode &&
                mapping[curr].productCode.type === 'row_product_no'
              ? extractId(key)
              : mapping[curr].extract && mapping[curr].extract.test(v)
              ? v.match(mapping[curr].extract)[0]
              : v.trim();
          return mapping[curr].rowData
            ? {
                ...acc,
                row: {
                  ...acc.row,
                  '1': {
                    ...acc.row['1'],
                    uuid: r.uuid ? r.uuid : uuidv4(),
                    ...(inputs['dim_value'] && {
                      dim: {
                        dim_value: inputs['dim_value'],
                        dim_type: inputs['dim_type'] || '',
                      },
                    }),
                    [mapping[curr].id]: value,
                  },
                },
              }
            : {
                ...acc,
                ...(inputs.delivery_method === 'email' && {
                  delivery_method: inputs.delivery_method,
                }),
                ...(notesBefore &&
                  notesBefore.length > 0 && { notes_before: notesBefore }),
                ...(shouldBe
                  ? {
                      [mapping[curr].id]: value,
                    }
                  : {}),
              };
        },
        { row: {}, groupid }
      );
  });

  const invoicesWithGroup = data.filter((d) => d.groupid);

  const groupedDataTable = invoicesWithGroup.reduce((acc, curr) => {
    return acc[curr.groupid] && curr.row['1']
      ? {
          ...acc,
          [curr.groupid]: {
            ...acc[curr.groupid],
            row: {
              ...acc[curr.groupid].row,
              [Object.keys(acc[curr.groupid].row).length + 1]: curr.row['1'],
            },
          },
        }
      : { ...acc, [curr.groupid]: curr };
  }, {});

  const singleInvoices = data.filter((d) => !d.groupid);

  const groupedInvoices = Object.keys(groupedDataTable).map(
    (key) => groupedDataTable[key]
  );

  const all = groupedInvoices.concat(
    singleInvoices
  ) as unknown as InvoiceValues[];

  const withValidation = Object.keys(mapping)
    .filter((key) => mapping[key].validation)
    .map((k) => mapping[k]);

  const rowDatas = all.map((d) => d.row);
  const rowDataErrors = withValidation
    .map((curr) => {
      const rows = rowDatas
        .map((item) => {
          return Object.keys(item)
            .filter((rownum) => {
              return checkInvoiceInputValue(
                item[rownum][curr.id],
                curr.validation
              );
            })
            .map((_e) => {
              return {
                message: curr.validation.message,
                type: curr.validation.type,
                name: curr.name,
              };
            });
        })
        .filter((errorArr) => errorArr.length > 0);
      return rows.flat();
    })
    .flat();

  const restData = all.map((d) => {
    const { row, groupid, ...rest } = d;
    return rest;
  });

  const restErrors = withValidation
    .map((curr) => {
      return restData
        .filter((invoice) =>
          checkInvoiceInputValue(invoice[curr.id], curr.validation)
        )
        .map((_e) => {
          return {
            message: curr.validation.message,
            type: curr.validation.type,
            name: curr.name,
          };
        })
        .flat();
    })
    .flat();

  const groupedErrors = restErrors.concat(rowDataErrors).reduce((acc, curr) => {
    const found = acc.find((itm) => findSimilarError(itm, curr));
    if (found) {
      const filtered = acc.filter((itm) => !findSimilarError(itm, curr));
      const updated = { ...found, count: found.count + 1 };
      return filtered.concat(updated);
    }
    return acc.concat({ ...curr, count: 1 });
  }, []);

  return { data: all, errors: { missing, validations: groupedErrors } };
};

export type InvoiceErrorsPreview = Omit<InputValidation, 'validation'> & {
  name: IntlMessageID;
  count?: number;
};
export const findSimilarError = (
  x: InvoiceErrorsPreview,
  y: InvoiceErrorsPreview
) => {
  return x.type === y.type && x.message === y.message && x.name === y.name;
};

export const checkInvoiceInputValue = (
  value: string,
  validation: InputValidation
) => {
  if (value === undefined) {
    return false;
  }
  if (validation.validation === 'Number') {
    return value ? isNaN(Number(value.replace(',', '.'))) : true;
  }
  if (validation.validation === 'Vatnumber') {
    return value ? !checkFinnishVatNumber(value) : true;
  }
  if (validation.validation === 'NotEmpty') {
    return value ? value.length === 0 : true;
  }
  if (validation.validation === 'ZipCode') {
    return value ? !/^\d{5}/.test(value) : true;
  }
  if (validation.validation === 'Email') {
    return value
      ? !/^[a-zA-Z0-9!#$%&'*+\-/=?^_`.{|}~]{1,64}@[a-z0-9.-]+\.[a-z]{2,64}$/.test(
          value
        )
      : true;
  }
  return false;
};

const checkFinnishVatNumber = (value: string) => {
  const stripped = value.replaceAll('-', '').replaceAll('FI', '').trim();
  const isNum = isNaN(Number(stripped)) ? false : true;
  if (isNum) {
    const nums = stripped.split('').map((num) => +num);
    const safenums = nums.length === 8 ? nums : [0].concat(nums);
    const factors = [7, 9, 10, 5, 8, 4, 2, 0];
    const summedWithFactors = safenums.reduce((acc, curr, index) => {
      return curr * factors[index] + acc;
    }, 0);
    const remainder = summedWithFactors % 11;
    const checkNum =
      remainder === 0 ? 0 : remainder === 1 ? -1 : 11 - remainder;
    return safenums[7] === checkNum;
  }
  return false;
};

export const selectInvoicesTableData = (state: AppState) => {
  const invoices = state.invoiceState.fennoaInvoices
    ? state.invoiceState.fennoaInvoices.map((f) => f.SalesInvoice)
    : [];

  const headerRow =
    invoices.length > 0
      ? Object.keys(invoices[0]).length > 5
        ? Object.keys(invoices[0]).slice(0, 5)
        : Object.keys(invoices[0])
      : [];
  return { data: invoices, headerRow };
};
