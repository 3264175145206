import React from 'react';
import { IconWrapper } from '../utils/IconWrapper';
import { AiFillCaretDown } from 'react-icons/ai';
import { RepeatableHeader } from './divs/RepatableHeader';
import { LabelContainer } from './labels/LabelContainer';
import { ErrorDiv } from './divs/ErrorDiv';
import { RepeatableButtons } from './buttons/RepeatableButtons';
import { HoverAttributes } from '../utils/HoverMessage';

interface FormSelectProps {
  id: string;
  options?: string[];
  dictionaryOptions?: { [key: string]: string };
  question?: string;
  type?: string;
  itemAttribute?: string;
  value?: string;
  divider?: string;
  containerStyle?: string;
  inputStyle?: string;
  styleInner?: string;
  divStyle?: string;
  labelStyle?: string;
  repeatable?: boolean;
  dividerHeaderStyle?: string;
  register?: any;
  errors?: Record<string, Object>;
  link?: string | boolean;
  cursiveLabel?: string;
  repeatableHeader?: string;
  repeatableKey?: { key: string; last: boolean; amount: number };
  required?: boolean;
  index?: number;
  overlayProps?: HoverAttributes;
  disabled?: boolean;
  changeAmount?: (key: string, direction: number) => void;
  validate?: (id: string) => void;
  handleInputChange: (
    event: React.ChangeEvent<HTMLSelectElement>,
    id: string,
    type?: string
  ) => void;
}

export const FormSelect: React.FC<FormSelectProps> = ({
  id,
  options,
  handleInputChange,
  question,
  value,
  containerStyle,
  divider,
  errors,
  itemAttribute,
  repeatableHeader,
  required,
  dictionaryOptions,
  validate,
  disabled,
  inputStyle,
  styleInner,
  divStyle,
  labelStyle,
  dividerHeaderStyle,
  repeatable,
  register,
  link,
  cursiveLabel,
  repeatableKey,
  changeAmount,
  index,
  overlayProps,
}) => {
  const styles = inputStyle
    ? inputStyle
    : 'border border-gray-300 rounded-lg h-10 m-4 leading-normal cursor-pointer';

  const stylesInner = styleInner
    ? styleInner
    : 'font-semibold text-brand-dark_blue focus:outline-none focus:bg-blue-100 focus:shadow-inner h-full w-full rounded-lg pl-4 pr-8 truncate leading-normal cursor-pointer';

  const divStyles = divStyle
    ? divStyle
    : 'flex flex-col mb-4 xl:w-11/12 xl:m-auto';

  const participantId = repeatable ? id.split('_')[1] : ''; //expected id is 'BLABLABLA_2' => 2

  const dividerHeaderStyles = dividerHeaderStyle
    ? dividerHeaderStyle
    : 'm-2 ml-4  font-bold text-lg text-brand-dark_blue';

  const dividerStyles = divider ? divider : '';
  const containerStyles = containerStyle ? containerStyle : '';

  const opts = dictionaryOptions ? Object.keys(dictionaryOptions) : options;

  const repeatIndex = participantId ? participantId : 1;

  return (
    <div
      className={`relative ${divStyles} ${containerStyles} ${dividerStyles}`}
      tabIndex={index}
    >
      <RepeatableHeader
        divider={divider}
        repeatable={repeatable}
        label={`${repeatableHeader} ${repeatIndex}`}
        style={dividerHeaderStyles}
      />
      <LabelContainer
        link={link}
        question={question}
        labelStyle={labelStyle}
        register={register}
        cursiveLabel={cursiveLabel}
        overlayProps={overlayProps}
      />
      <div className={`relative ${styles}`}>
        <select
          className={`appearance-none ${stylesInner} ${
            disabled ? 'bg-gray-200' : ''
          }`}
          id={id}
          value={value}
          onChange={(e) => handleInputChange(e, id, itemAttribute)}
          name={id}
          ref={register}
          onBlur={validate ? () => validate(id) : null}
          disabled={disabled ? true : false}
        >
          {opts?.map((option) => (
            <option
              key={option}
              value={dictionaryOptions ? dictionaryOptions[option] : option}
            >
              {option}
            </option>
          ))}
        </select>
        <IconWrapper styles="text-brand-dark_blue absolute right-0 mr-5 mt-2 pointer-events-none">
          <AiFillCaretDown size={20} />
        </IconWrapper>
      </div>
      <ErrorDiv errors={errors} id={id} />
      <RepeatableButtons
        repeatableKey={repeatableKey}
        changeAmount={changeAmount}
        repeatableHeader={repeatableHeader}
      />
    </div>
  );
};

export default FormSelect;
