import React from 'react';
import { InvoicePreviewHeader } from './InvoicePreviewHeader';
import { InvoicePreviewCommon } from './InvoicePreviewCommon';
import { InvoicePreviewRows } from './InvoicePreviewRows';
import { InvoicePreviewButtons } from './InvoicePreviewButtons';

interface InvoiceDefinitorValues {
  row: { [key: string]: { [key: string]: string } };
  groupid: string | null;
}

export type InvoiceValues = InvoiceDefinitorValues & { [key: string]: string };

type Props = {
  amountOfItems: number;
  currentItem: number;
  data: InvoiceValues;
  changeItem: (step: number) => void;
  container: string;
};

export const InvoicePreviewCard: React.FC<Props> = ({
  data,
  changeItem,
  amountOfItems,
  currentItem,
  container,
}) => {
  return (
    <div className={container}>
      <InvoicePreviewHeader amountOfItems={amountOfItems} />
      <div>
        <InvoicePreviewCommon data={data} />
        <InvoicePreviewRows data={data} />
      </div>
      <InvoicePreviewButtons
        amountOfItems={amountOfItems}
        currentItem={currentItem}
        changeItem={changeItem}
      />
    </div>
  );
};
