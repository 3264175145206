import React, { FormEvent } from 'react';
import { FcPrevious, FcNext } from 'react-icons/fc';
import { FormattedMessage } from 'react-intl';
import { ButtonInfo } from '../../types/types';

type Props = {
  currentPage: number;
  changePage: (e: FormEvent, page: number) => void;
  buttonInfo: ButtonInfo;
  maxPage: number;
  pages: Array<number>;
};
export const FormNavigation: React.FC<Props> = ({
  changePage,
  currentPage,
  maxPage,
  buttonInfo,
  pages,
}) => {
  const submitHidden =
    currentPage === maxPage
      ? 'visible'
      : 'invisible focus:outline-none outline-none';
  const continueHidden =
    currentPage === maxPage
      ? 'invisible focus:outline-none outline-none'
      : 'visible';
  const previousHidden =
    currentPage === pages[0]
      ? 'invisible focus:outline-none outline-none'
      : 'visible';
  const iconStyles = 'cursor-pointer';
  const buttonColors = buttonInfo.style;

  return (
    <div className="flex flex-row justify-between">
      <button
        className={`flex items-center ${previousHidden} m-2 cursor-pointer`}
        onClick={(e) => changePage(e, currentPage - 1)}
      >
        <FcPrevious className={`${iconStyles}`} size={20} color={`#009ece`} />
        <span className="text-brand-blue">
          <FormattedMessage
            id="form.previouspage.button"
            defaultMessage="Previous"
          />
        </span>
      </button>
      <button
        className={`${buttonColors} py-2 px-4 lg:m-5 m-2 w-auto rounded ${submitHidden}`}
        type="submit"
      >
        <FormattedMessage id={buttonInfo.format_id} />
      </button>
      <button
        className={`flex items-center ${continueHidden} m-2 cursor-pointer`}
        onClick={(e) => changePage(e, currentPage + 1)}
      >
        <span className="text-brand-blue">
          <FormattedMessage
            id="form.nextpage.button"
            defaultMessage="Continue"
          />
        </span>
        <FcNext className={`${iconStyles}`} size={20} color={`#009ece`} />
      </button>
    </div>
  );
};
