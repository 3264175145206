import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import allActions from '../../../actions/allActions';
import { EventParamTypes } from '../../../types/types';
import * as fState from '../../../reducers/formReducer';
import * as errorState from '../../../reducers/errorReducer';
import ParticipantFormHeader from './ParticipantFormHeader';
import history from '../../../history/history';
import { selectFormInputValues } from '../../../reducers/formReducer';
import { AppState } from '../../../reducers/combineReducer';
import { selectLoadingMultiple } from '../../../reducers/loadingReducer';
import Loading from '../../../components/utils/Loading';
import { useNotify } from '../../../hooks/useNotify';
import { selectSubEventLinks } from '../../eventReducer';
import ParticipantForm from './ParticipantForm';

export const NewParticipant: React.FC = () => {
  const dispatch = useDispatch();
  const { id, subEventId } = useParams<EventParamTypes>();

  const form = 'newParticipant';
  const { message } = useSelector(errorState.getErrorMessage);
  const error = useNotify(message);
  const items = useSelector(fState.selectCreateParticipantFormItems);
  const values = useSelector((state: AppState) =>
    selectFormInputValues(state, form)
  );
  const isLoading = useSelector((state: AppState) =>
    selectLoadingMultiple(state, [
      'createNewParticipant',
      'getNewParticipantForm',
    ])
  );
  const subEventLinks = useSelector((state: AppState) =>
    selectSubEventLinks(state, id, subEventId)
  );

  const getFormData = useCallback(() => {
    dispatch(allActions.eventActions.getNewParticipantForm(subEventId));
  }, [subEventId, dispatch]);

  useEffect(() => {
    getFormData();
  }, [getFormData]);

  const goback = () => {
    history.goBack();
  };

  const createParticipant = () => {
    dispatch(
      allActions.participantActions.newParticipant(
        subEventId,
        id,
        items,
        values,
        false
      )
    );
  };

  const createAndSendParticipant = () => {
    dispatch(
      allActions.participantActions.newParticipant(
        subEventId,
        id,
        items,
        values,
        true,
        subEventLinks[0].linkStr || null
      )
    );
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    array: boolean
  ) => {
    if (!array) {
      return dispatch(
        allActions.formActions.updateValue(e.target.id, e.target.value, form)
      );
    }
    const currArray = values[e.target.id]
      ? (values[e.target.id] as string[])
      : [];
    return dispatch(
      allActions.formActions.addToValue(
        e.target.id,
        currArray,
        e.target.value,
        form
      )
    );
  };

  return (
    <div className="w-11/12 h-11/12 mx-auto">
      <div className="flex flex-col h-full w-full">
        <ParticipantFormHeader
          goback={goback}
          save={createParticipant}
          saveandsend={createAndSendParticipant}
        />
        {isLoading ? (
          <Loading styles={'items-center my-auto'} />
        ) : (
          <ParticipantForm
            items={items}
            error={error}
            handleInputChange={handleInputChange}
            values={values}
          />
        )}
      </div>
    </div>
  );
};
