import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MissingValidationObject } from '../certReducer';
import { messages } from '../translations/confirmsend';
import { messages as newCertMessages } from '../translations/newcert';

type Props = {
  missing: MissingValidationObject[];
};

export const ErrorsMissingList: React.FC<Props> = ({ missing }) => {
  const message =
    missing.length > 0 ? messages.errorsmissing : messages.errorsok;

  const emoji = missing.length > 0 ? '🗺️' : '✅';

  const { formatMessage } = useIntl();

  return (
    <div>
      <span className="text-xl text-brand-dark_blue py-2">
        2. <FormattedMessage {...message} /> {emoji}
      </span>
      {missing.map((m, i) => (
        <div className="pt-4" key={`missing_${m.id}_${i}`}>
          <span className="mx-2 text-red-500 truncate">
            ❗{m.question} ({formatMessage(newCertMessages[m.mapping])})
          </span>
        </div>
      ))}
    </div>
  );
};
