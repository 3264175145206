import { TranslationKey } from './navigationTranslations';

export type NavigationIcon =
  | 'calendarplus'
  | 'calendar'
  | 'billwave'
  | 'webmoney'
  | 'filepaper'
  | 'fileadd'
  | 'account'
  | 'logout'
  | 'feedback'
  | 'marketing'
  | 'archive';

type LinkItem = {
  link: string;
  message: TranslationKey;
  icon: NavigationIcon;
};

type NavGroup = {
  toggle: string;
  header: NavGroups;
  links: LinkItem[];
};

export type NavGroups = 'events' | 'certs' | 'invoicing' | 'leads';
export type NavigationGroupTable = Record<NavGroups, NavGroup>;
export const navigationGroups: NavigationGroupTable = {
  events: {
    toggle: 'EVENTS_TOGGLE',
    header: 'events',
    links: [
      {
        link: '/createevent',
        message: 'createevent',
        icon: 'calendarplus',
      },
      {
        link: '/events',
        message: 'allevents',
        icon: 'calendar',
      },
      {
        link: '/archivedevents',
        message: 'archivedevents',
        icon: 'archive',
      },
    ],
  },
  certs: {
    toggle: 'CERT_TOGGLE',
    header: 'certs',
    links: [
      {
        link: '/createcert',
        message: 'createcert',
        icon: 'fileadd',
      },
      {
        link: '/certs',
        message: 'allcerts',
        icon: 'filepaper',
      },
    ],
  },
  invoicing: {
    toggle: 'INVOICE_TOGGLE',
    header: 'invoicing',
    links: [
      {
        link: '/createinvoice',
        message: 'createinvoice',
        icon: 'billwave',
      },
      {
        link: '/invoices',
        message: 'allinvoices',
        icon: 'webmoney',
      },
    ],
  },
  leads: {
    toggle: 'LEAD_TOGGLE',
    header: 'leads',
    links: [
      {
        link: '/feedback',
        message: 'feedback',
        icon: 'feedback',
      },
      {
        link: '/marketing',
        message: 'marketing',
        icon: 'marketing',
      },
    ],
  },
};
