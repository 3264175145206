import React from 'react';
import { MappedEmailData } from '../certReducer';

import { FormattedMessage, useIntl } from 'react-intl';
import { emailMappingLabels } from '../wysiwygEmailMappings';

type Props = {
  data: Record<string, MappedEmailData>;
};

export const EmailPreviewItem: React.FC<Props> = ({ data }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="p-2">
      {Object.keys(data).map((k) => {
        const header = formatMessage({ id: emailMappingLabels[k] });
        return (
          <div key={`${k}`} className="text-brand-dark_blue">
            <span className="font-medium">{header}</span>:{' '}
            <span>{data[k].value}</span>
            {data[k].error && (
              <span className="ml-2 text-red-400">
                <FormattedMessage id={data[k].error} values={{ key: header }} />
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};
