import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import FormDateDate from '../../components/form/FormDateDate';
import FormInput from '../../components/form/FormInput';
import FormTextArea from '../../components/form/FormTextArea';
import { AppState } from '../../reducers/combineReducer';
import { getSendMultipleData } from '../../reducers/invoiceReducer';
import { InputDetails } from '../invoiceMappings';
import { InvoiceMultiInput } from './InvoiceMultiInput';

type Props = {
  handleInputChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    id: string,
    type?: 'ProductInfo' | 'Normal'
  ) => void;
  handleSelectChange: (
    e: React.ChangeEvent<HTMLSelectElement>,
    id: string,
    type?: 'ProductInfo' | 'Normal'
  ) => void;
  handleDateChange: (date: Date, id: string) => void;
  inputs: { [key: string]: InputDetails };
  control: any;
  resetDate: (id: string) => void;
  values: { [key: string]: string | string[] };
  register: any;
};

export const InvoiceSingleSendInputs: React.FC<Props> = ({
  handleInputChange,
  handleSelectChange,
  handleDateChange,
  inputs,
  control,
  resetDate,
  values,
  register,
}) => {
  const keys = Object.keys(inputs).filter((key) => key !== 'notes_before');
  const { formatMessage } = useIntl();
  const { productOptions } = useSelector((state: AppState) =>
    getSendMultipleData(state)
  );
  return (
    <>
      {keys.map((key, i) => {
        const input = inputs[key];
        const value = values[key];
        const last = i + 1 === keys.length;
        const name = formatMessage({
          id: input.name,
          defaultMessage: input.id,
        });
        const hardCodedInput =
          input.hardcodeType === 'longtext' ? 'textarea' : 'text';
        return inputs[key].type === 'Date' ? (
          <FormDateDate
            key={`${key}_key`}
            inputStyle={
              'flex h-10 bg-white border border-gray-300 mx-5 rounded-lg relative'
            }
            divStyle={`flex flex-col w-1/2 ${last ? 'pb-4' : ''}`}
            id={key}
            handleInputChange={handleDateChange}
            value={typeof value === 'string' ? new Date(value) : new Date()}
            control={control}
            reset={resetDate}
            question={name}
            register={
              input.required
                ? {
                    validate: () => {
                      return value !== null || `any`;
                    },
                  }
                : null
            }
          />
        ) : input.productCode && input.productCode.type === 'row_product_no' ? (
          <InvoiceMultiInput
            key={`${key}_key`}
            id={key}
            input={input}
            value={typeof value === 'string' ? value : ''}
            handleInputChange={handleSelectChange}
            options={
              input.productCode && input.productCode.type === 'row_product_no'
                ? [''].concat(productOptions)
                : []
            }
            last={last}
            register={register}
          />
        ) : hardCodedInput === 'text' ? (
          <FormInput
            key={`${key}_key`}
            divStyle={`flex flex-col w-1/2 ${last ? 'pb-4' : ''}`}
            type={input.hardcodeType}
            value={typeof value === 'string' ? value : ''}
            id={key}
            handleInputChange={(e) => handleInputChange(e, key)}
            question={name}
            register={input.required ? register({ required: `any` }) : null}
          />
        ) : (
          <FormTextArea
            value={typeof value === 'string' ? value : ''}
            id={key}
            handleInputChange={(e) => handleInputChange(e, key)}
            question={name}
            register={input.required ? register({ required: `any` }) : null}
          />
        );
      })}
    </>
  );
};
