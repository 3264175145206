import React from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../actions/allActions';
import TableData from '../../components/utils/TableData';
import { AppState } from '../../reducers/combineReducer';
import { getSelectedParticipants } from '../../reducers/invoiceReducer';

interface Props {
  receiver: any;
  headerKeys: string[];
}

export const InvoiceReceiverTableRow: React.FC<Props> = ({
  headerKeys,
  receiver,
}) => {
  const rowStyles =
    'hover:text-blue-400 flex flex-col flex-nowrap md:table-row mb-2 md:mb-0';
  const styles = 'border px-8 py-4 h-12 truncate';
  const dispatch = useDispatch();
  const selected = useSelector((state: AppState) =>
    getSelectedParticipants(state)
  );
  const check = selected.includes(receiver.uuid);
  const toggleReceiver = () => {
    dispatch(allActions.invoiceActions.toggleRecipient(receiver.uuid));
  };
  return (
    <tr className={rowStyles}>
      <td
        className={`${styles} text-center cursor-pointer`}
        onClick={toggleReceiver}
      >
        <label
          htmlFor={`select:${receiver.uuid}`}
          className={`relative cursor-pointer select-none multiSelect`}
        >
          <input
            className="cursor-pointer"
            type="checkbox"
            id={`select:${receiver.uuid}`}
            checked={check}
            onChange={toggleReceiver}
          />
          <span
            className={`absolute -bottom-2 -left-6 h-4 w-4 ${
              check ? 'bg-blue-400' : 'bg-blue-100 checkmark'
            }`}
          >
            {check ? <AiOutlineCheck style={{ color: '#00255f' }} /> : null}
          </span>
        </label>
      </td>
      {headerKeys.map((key, i) => {
        const value = receiver[key];
        return <TableData key={i} value={value} styles={styles} />;
      })}
    </tr>
  );
};

export default InvoiceReceiverTableRow;
