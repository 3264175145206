import { DefinedMessages } from '../../types/types';

export const messages: DefinedMessages = {
  any: {
    id: 'form.required.any',
    defaultMessage: 'This field is required!',
  },
  phone: {
    id: 'form.required.phone',
    defaultMessage: 'Invalid phonenumber!',
  },
  email: {
    id: 'form.required.email',
    defaultMessage: 'Invalid email address!',
  },
  startdate: {
    id: 'form.date.smaller',
    defaultMessage: 'Startdate cannot be after enddate!',
  },
  uniquequestionid: {
    id: 'form.inputid.unique',
    defaultMessage: 'Id must be unique!',
  },
  formatid: {
    id: 'form.inputid.format',
    defaultMessage: 'Cannot contain underscore!',
  },
  passwordminlength: {
    id: 'form.inputid.passwordminlength',
    defaultMessage: 'Password is too short!',
  },
  passwordmatch: {
    id: 'form.inputid.passwordmatch',
    defaultMessage: 'Passwords wont match!',
  },
};
