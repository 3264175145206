import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EventStatusChangeAction } from '../../actions/eventActions';
import { FormattedMessageProps } from '../../types/types';
import { DialogProperties } from './views/ListEventsPage';

type Props = {
  message: FormattedMessageProps;
  item: DialogProperties;
  alertAction: (itemId: string, type: EventStatusChangeAction) => void;
  alertCancel: () => void;
};

export const EventChangeDialog: React.FC<Props> = ({
  message,
  item,
  alertAction,
  alertCancel,
}) => {
  const positionStyles =
    'absolute top-1/4 right-1/3 px-20 py-10 z-10 text-center rounded shadow-xl';

  const dialogColors =
    item.type === 'delete'
      ? 'bg-red-400 text-brand-white'
      : 'bg-brand-white text-brand-dark_blue';

  const confirmButtonStyle =
    item.type === 'delete'
      ? 'bg-red-600 hover:bg-red-800 text-brand-white m-4 rounded w-20 h-10 shadow-lg'
      : 'bg-brand-white hover:bg-blue-200 text-brand-blue m-4 rounded w-20 h-10 shadow-lg';

  const textStyles =
    item.type === 'delete'
      ? 'text-brand-white font-thin'
      : 'text-brand-dark_blue';

  const cancelButtonStyle =
    item.type === 'delete'
      ? 'bg-yellow-400 hover:bg-yellow-600 text-gray-white font-thin m-4 rounded w-20 h-10 shadow-lg'
      : 'bg-brand-white hover:bg-blue-200 text-brand-blue m-4 rounded w-20 h-10 shadow-lg';

  const lineBreak = { lineBreak: <br /> };

  return (
    <div className={`${positionStyles} ${dialogColors}`}>
      <div className="flex flex-col">
        <div className="p-4 text-center">
          <span className={textStyles}>
            <FormattedMessage {...message} values={lineBreak} />
          </span>
        </div>
        <div className="flex justify-around">
          <div className="pb-6">
            <button
              className={confirmButtonStyle}
              onClick={() => alertAction(item.eventId, item.type)}
            >
              <FormattedMessage
                id="registration.subevent.dialog.confirm.button"
                defaultMessage="OK"
              />
            </button>
          </div>
          <div className="pb-6">
            <button className={cancelButtonStyle} onClick={alertCancel}>
              <FormattedMessage
                id="registration.subevent.dialog.cancel.button"
                defaultMessage="Cancel"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
