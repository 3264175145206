import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCertDetails } from './certReducer';
import { CertTable } from './certDetailsTable/CertTable';
import { CertParamTypes } from '../types/types';
import { useParams } from 'react-router-dom';
import BreadCrumb from '../components/breadcrumbs/BreadCrumb';
import { BreadRoute } from '../components/breadcrumbs/BreadRoute';
import * as uState from '../reducers/userReducer';
import storeService from '../services/storage';
import allActions from '../actions/allActions';
import { AppState } from '../reducers/combineReducer';
import { selectLoading } from '../reducers/loadingReducer';

export const CertDetails: React.FC = () => {
  const { certOperationId } = useParams<CertParamTypes>();
  const { userId } = useSelector(uState.selectUserData);
  const { data, headerRow } = useSelector((state: AppState) =>
    selectCertDetails(state, certOperationId)
  );
  const isLoading = useSelector((state: AppState) =>
    selectLoading(state, 'resendCert')
  );
  const tableName = `certdetails_${certOperationId}_${userId}`;
  const savedHeaders = storeService.getHeaders(tableName);
  const headers = savedHeaders.length > 0 ? savedHeaders : headerRow;
  const crumbs = [
    { label: 'cert-menu', link: '/certmenu' },
    { label: 'certs', link: '/certs' },
    { label: `cert ${certOperationId}`, link: `/certs/${certOperationId}` },
  ];
  const dispatch = useDispatch();

  const goEditCert = (mailId: string) => {
    dispatch(allActions.certActions.mapEditData(certOperationId, mailId));
  };

  const resend = (mailId: string) => {
    dispatch(allActions.certActions.resend(mailId));
  };

  return (
    <div className="w-11/12 h-full mx-auto items-center pr-4">
      <BreadRoute>
        {crumbs.map((c, i) => {
          const last = i + 1 === crumbs.length;
          return (
            <BreadCrumb
              key={c.link}
              label={c.label}
              link={c.link}
              lastChild={last}
            />
          );
        })}
      </BreadRoute>
      <div className="h-full max-h-11/12 w-full flex justify-center items-center overflow-auto scroller">
        <CertTable
          list={data}
          headerRow={headers}
          tableName={tableName}
          resend={resend}
          goEditCert={goEditCert}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default CertDetails;
