import React from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from '../translations/confirmsend';

type Props = {
  amountOfReceivers: number;
  type: 'cert' | 'email';
  divStyle?: string;
};

export const Receivers: React.FC<Props> = ({
  amountOfReceivers,
  type,
  divStyle,
}) => {
  const translation =
    type === 'email' ? messages.emailreceivers : messages.receivers;

  const message =
    amountOfReceivers > 0
      ? {
          ...translation,
          values: {
            amount: amountOfReceivers,
            bold: (amount: string) => <b>{amount}</b>,
          },
        }
      : messages.noreceivers;

  const emoji = amountOfReceivers === 0 ? '🤨' : '✅';

  return (
    <div className={divStyle ?? ''}>
      <span className="text-xl text-brand-dark_blue py-2">
        1. <FormattedMessage {...message} /> {emoji}
      </span>
    </div>
  );
};
