import React, { useCallback, useEffect, useState } from 'react';

import history from '../../../history/history';

import { BreadRoute } from '../../../components/breadcrumbs/BreadRoute';
import BreadCrumb from '../../../components/breadcrumbs/BreadCrumb';
import { EventTable } from '../eventTable/EventTable';
import { Toggle } from '../../../components/utils/Toggle';
import { FormattedMessageProps } from '../../../types/types';
import { EventChangeDialog } from '../EventChangeDialog';

import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../actions/allActions';
import * as uState from '../../../reducers/userReducer';
import * as leState from '../listEventsReducer';
import storeService from '../../../services/storage';
import { selectLoadingMultiple } from '../../../reducers/loadingReducer';
import { AppState } from '../../../reducers/combineReducer';
import { EventStatusChangeAction } from '../../../actions/eventActions';

const alertMessage: Record<EventStatusChangeAction, FormattedMessageProps> = {
  archive: {
    id: 'event.events.archive.modal.text',
    defaultMessage: 'Are you sure you want to archive the event?',
  },
  restore: {
    id: 'event.events.unarchive.modal.text',
    defaultMessage: 'Are you sure you want to restore the event',
  },
  delete: {
    id: 'event.events.delete.modal.text',
    defaultMessage: 'Are you sure you want to delete the event?',
  },
};

export type EventListType = 'archived' | 'unarchived';
type Props = {
  type: EventListType;
};

const currentCrumb = {
  unarchived: { label: 'events', link: '/events' },
  archived: { label: 'archived', link: '/archivedevents' },
};

export type DialogProperties = {
  eventId: string;
  type: EventStatusChangeAction;
};

export const ListEventsPage: React.FC<Props> = ({ type }) => {
  const { list, headerRow } = useSelector(leState.selectListEventsState);
  const isLoading = useSelector((state: AppState) =>
    selectLoadingMultiple(state, ['getEvents', 'changeEventStatus'])
  );
  const { userId } = useSelector(uState.selectUserData);
  const dispatch = useDispatch();
  const tableName = `events_${userId}`;
  const savedHeaders = storeService.getHeaders(tableName);
  const headers = savedHeaders.length > 0 ? savedHeaders : headerRow;
  const eventStatusChangeModal = 'EVENT_STATUS_CHANGE_MODAL';
  const [dialogProperties, setDialogProperties] = useState<DialogProperties>({
    type: 'archive',
    eventId: '',
  });

  const openEvent = (eventId: string, subEventId?: string) => {
    if (subEventId) {
      return history.push(`/events/${eventId}/subevents/${subEventId}`);
    }
    return history.push(`/events/${eventId}`);
  };

  const openStatusChangeModal = (properties: DialogProperties) => {
    setDialogProperties(properties);
    dispatch(allActions.toggleActions.toggle(eventStatusChangeModal));
  };

  const changeEventStatus = (
    eventId: string,
    type: EventStatusChangeAction
  ) => {
    openStatusChangeModal({ eventId, type });
    dispatch(allActions.eventActions.changeEventStatus(eventId, type));
  };

  const closeStatusChangeModal = () =>
    dispatch(allActions.toggleActions.hideToggle(eventStatusChangeModal));

  const getAllEvents = useCallback(() => {
    dispatch(
      allActions.eventActions.getAllEvents({ type, date: '0', limit: '100' })
    );
  }, [dispatch, type]);

  useEffect(() => {
    getAllEvents();
  }, [getAllEvents, type]);

  return (
    <div className="w-11/12 mx-auto h-screen flex-col pr-4">
      <BreadRoute>
        <BreadCrumb label={'event-menu'} link={'/eventmenu'} />
        <BreadCrumb
          label={currentCrumb[type].label}
          link={currentCrumb[type].link}
          lastChild={true}
        />
      </BreadRoute>
      <EventTable
        tableName={tableName}
        list={list}
        openEvent={openEvent}
        toggleStatusChangeModal={openStatusChangeModal}
        eventStatusChangeModal={eventStatusChangeModal}
        loading={isLoading}
        headerRow={headers}
        type={type}
      />
      <Toggle id={eventStatusChangeModal}>
        <EventChangeDialog
          message={alertMessage[dialogProperties.type]}
          alertAction={changeEventStatus}
          item={dialogProperties}
          alertCancel={closeStatusChangeModal}
        />
      </Toggle>
    </div>
  );
};

export default ListEventsPage;
