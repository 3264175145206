import React from 'react';
import { InvoiceErrors } from '../../reducers/invoiceReducer';
import { ErrorsMissingList } from './ErrorsMissingList';
import { ErrorsValidationList } from './ErrorsValidationList';
import { InvoiceErrorsBottom } from './InvoiceErrorsBottom';

type Props = {
  errors: InvoiceErrors;
  container: string;
  sendInvoice: () => void;
};
export const InvoiceErrorsCard: React.FC<Props> = ({
  errors,
  container,
  sendInvoice,
}) => {
  const warns =
    errors.validations.filter((v) => v.type === 'Warning').length > 0;
  const err =
    errors.missing.length +
      errors.validations.filter((v) => v.type === 'Error').length >
    0;

  const sendButton = err
    ? 'bg-gray-500 cursor-default'
    : warns
    ? 'bg-yellow-700 hover:bg-yellow-900'
    : 'bg-blue-400 hover:bg-brand-blue';

  return (
    <div className={container}>
      <ErrorsMissingList missing={errors.missing} />
      <ErrorsValidationList validations={errors.validations} />
      <InvoiceErrorsBottom
        state={err ? 'Errors' : warns ? 'Warnings' : 'OK'}
        sendButton={sendButton}
        sendInvoice={sendInvoice}
      />
    </div>
  );
};
