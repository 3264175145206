import React from 'react';
import { Link } from 'react-router-dom';
import { NavIcon } from './NavIcon';
import { NavigationIcon } from './navigationGroups';

type Props = {
  link: string;
  icon: NavigationIcon;
};

export const NavIconItem: React.FC<Props> = ({ link, icon }) => {
  const styles = 'p-2 pb-4';
  const iconNavClass = 'text-white hover:text-blue-300 m-1';
  return (
    <Link className={styles} to={link} title={link.replace('/', '')}>
      <NavIcon name={icon} size={24} styles={iconNavClass} />
    </Link>
  );
};
