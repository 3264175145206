import React from 'react';
import { InvoiceValues } from './InvoicePreviewItem';
import { destinationMaps } from '../invoiceMappings';
import { FormattedMessage } from 'react-intl';

type Props = {
  data: InvoiceValues;
};
export const InvoicePreviewCommon: React.FC<Props> = ({ data }) => {
  const keyStyle = 'text-xl font-semibold text-brand-dark_blue m-2';
  const valueStyle = 'text-xl text-brand-dark_blue m-2';
  const commonKeys = Object.keys(data).filter(
    (k) => k !== 'row' && k !== 'groupid' && k !== 'delivery_method'
  );
  const map = destinationMaps.Fennoa;

  return (
    <>
      {commonKeys.map((k, i) => {
        const name = map[k].name;
        return (
          <div key={`invoiceitemkey_${i}_${k}`} className="truncate">
            <span className={keyStyle}>
              <FormattedMessage {...{ id: name, defaultMessage: k }} />
            </span>
            <span className={valueStyle}>{data[k]}</span>
          </div>
        );
      })}
    </>
  );
};
