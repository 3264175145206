import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedMessageProps } from '../../types/types';
import { VscNewFile } from 'react-icons/vsc';

interface Props {
  styles?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  msg: FormattedMessageProps;
}

export const InputLoadButton: React.FC<Props> = ({ styles, onChange, msg }) => {
  const inputFile = React.useRef(null);

  const handleInputFileClick = () => {
    inputFile.current.click();
  };

  const style = styles
    ? styles
    : 'flex cursor-pointer items-center justify-around bg-brand-white hover:bg-blue-200 text-brand-blue m-4 rounded w-40 h-10 border border-gray-200 shadow-lg';

  return (
    <div className={style} onClick={handleInputFileClick}>
      <VscNewFile size={20} />
      <input
        className="hidden"
        type="file"
        accept="image/*"
        onChange={onChange}
        ref={inputFile}
      />
      <FormattedMessage {...msg} />
    </div>
  );
};
