import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as eState from '../../eventReducer';

import { Switch } from '../../../components/utils/Switch';
import FormInput from '../../../components/form/FormInput';
import allActions from '../../../actions/allActions';

type Props = {};

export const MailerTemplateModal: React.FC<Props> = () => {
  const { mailerQuestions, mailQuestions } = useSelector(
    eState.selectRegistrationPageStylingData
  );

  const dispatch = useDispatch();

  const setMailerQuestions = (
    id: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      allActions.eventActions.setMailerQuestions(id, event.target.value)
    );
  };

  const [isToggled, setIsToggled] = React.useState({});

  const inputLabelStyle = 'm-2  font-bold text-brand-dark_blue';
  const inputStyle =
    'font-semibold text-brand-dark_blue focus:outline-none focus:bg-blue-100 focus:shadow-inner border border-gray-300 rounded-lg py-2 pl-4 appearance-none leading-normal';
  const inputDiv = 'flex flex-col mb-4 xl:w-11/12 xl:m-auto';
  const switchDiv = 'px-5 m-2 w-1/2';

  return (
    <div className="absolute top-1/4 px-20 py-10 bg-brand-white rounded z-10 border divide-y">
      {mailerQuestions.map((q) => (
        <div key={q.id} className="flex">
          <Switch
            id={q.id}
            divStyle={switchDiv}
            header={q.question}
            show={isToggled[q.id]}
            switchAction={() =>
              setIsToggled({ ...isToggled, [q.id]: !isToggled[q.id] })
            }
          />
          <FormInput
            divStyle={`${inputDiv} ${
              isToggled[q.id] ? 'visible' : 'invisible'
            }`}
            id={q.id}
            labelStyle={`${inputLabelStyle}`}
            inputStyle={inputStyle}
            question={'osoite'}
            type="text"
            value={mailQuestions[q.id] ?? ''}
            handleInputChange={(event) => setMailerQuestions(q.id, event)}
          />
        </div>
      ))}
    </div>
  );
};
