import React from 'react';
import {
  RiFilePaperLine,
  RiFileAddLine,
  RiFeedbackLine,
  RiAdvertisementLine,
} from 'react-icons/ri';
import { BiCalendarPlus, BiCalendar, BiCabinet } from 'react-icons/bi';
import { SiWebmoney } from 'react-icons/si';
import { FaMoneyBillWave } from 'react-icons/fa';
import { VscAccount, VscSignOut } from 'react-icons/vsc';
import { NavigationIcon } from './navigationGroups';

type Props = {
  name: NavigationIcon;
  size?: number;
  styles?: string;
};

export const NavIcon: React.FC<Props> = ({ name, size, styles }) => {
  const sizes = size ? size : 20;
  const styless = styles ? styles : 'text-white hover:text-blue-300 m-1';
  switch (name) {
    case 'calendarplus':
      return <BiCalendarPlus size={sizes} className={styless} />;

    case 'calendar':
      return <BiCalendar size={sizes} className={styless} />;

    case 'billwave':
      return <FaMoneyBillWave size={sizes} className={styless} />;

    case 'webmoney':
      return <SiWebmoney size={sizes} className={styless} />;

    case 'filepaper':
      return <RiFilePaperLine size={sizes} className={styless} />;

    case 'fileadd':
      return <RiFileAddLine size={sizes} className={styless} />;

    case 'account':
      return <VscAccount size={sizes} className={styless} />;

    case 'logout':
      return <VscSignOut size={sizes} className={styless} />;

    case 'archive':
      return <BiCabinet size={sizes} className={styless} />;

    case 'feedback':
      return <RiFeedbackLine size={sizes} className={styless} />;

    case 'marketing':
      return <RiAdvertisementLine size={sizes} className={styless} />;

    default:
      return null;
  }
};
