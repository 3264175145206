import React from 'react';
import history from '../../../history/history';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../actions/allActions';
import { Loading } from '../../../components/utils/Loading';
import ParticipantFormHeader from './ParticipantFormHeader';
import * as pState from '../../../reducers/participantReducer';
import { AppState } from '../../../reducers/combineReducer';
import { selectLoadingMultiple } from '../../../reducers/loadingReducer';
import * as errorState from '../../../reducers/errorReducer';
import { useNotify } from '../../../hooks/useNotify';
import { Toggle } from '../../../components/utils/Toggle';
import { MoveParticipantModal } from './MoveParticipantModal';
import { BackDrop } from '../../../components/tableSearchBar/BackDrop';
import ParticipantForm from './ParticipantForm';
import { extractId } from '../../../utils/functions';
import AlertDialog from '../../../components/modals/AlertDialog';
import { FormattedMessageProps } from '../../../types/types';
import { selectSubEventForNewParticipant } from '../../eventReducer';

export const EditParticipant: React.FC = () => {
  const {
    values,
    inputs,
    uuid,
    eventId,
    subEventId,
    isPartOfGroup,
  } = useSelector(pState.selectParticipantDetailsFormData);
  const isLoading = useSelector((state: AppState) =>
    selectLoadingMultiple(state, ['updateParticipant', 'getParticipantDetails'])
  );
  const options = useSelector((state: AppState) =>
    selectSubEventForNewParticipant(state, eventId)
  );
  const dispatch = useDispatch();
  const { message } = useSelector(errorState.getErrorMessage);
  const error = useNotify(message);
  const moveModalId = 'PARTICIPANT_MOVE_TOGGLE';
  const deleteModalId = 'PARTICIPANT_DELETE_TOGGLE';
  const modalStyles =
    'absolute top-1/4 right-1/2 px-10 py-4 bg-yellow-200 rounded z-10';
  const alertMessage: FormattedMessageProps = {
    id: 'event.participant.delete.modal.text',
    defaultMessage: 'Are you sure you want to delete participant?',
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    array: boolean
  ) => {
    dispatch(
      allActions.participantActions.changeParticipantsData(
        e.target.id,
        e.target.value,
        array
      )
    );
  };

  const saveChanges = () => {
    dispatch(
      allActions.participantActions.saveParticipantChanges(uuid, eventId)
    );
  };

  const cancelParticipant = () => {
    dispatch(
      allActions.participantActions.cancelParticipant(eventId, subEventId, uuid)
    );
  };

  const deleteParticipant = () => {
    dispatch(
      allActions.participantActions.deleteParticipant(eventId, subEventId, uuid)
    );
    toggleAlertModal();
  };

  const moveParticipant = (toUnformatted: string, moveGroup: boolean) => {
    const to = extractId(toUnformatted);
    dispatch(
      allActions.participantActions.moveParticipant(
        subEventId,
        to,
        eventId,
        uuid,
        moveGroup
      )
    );
    toggleMoveModal();
  };

  const goback = () => {
    history.goBack();
  };

  const toggleMoveModal = () => {
    dispatch(allActions.toggleActions.toggle(moveModalId));
  };

  const toggleAlertModal = () => {
    dispatch(allActions.toggleActions.toggle(deleteModalId));
  };

  return (
    <div className="w-11/12 h-11/12 mx-auto">
      <BackDrop modalIds={[moveModalId, deleteModalId]} />
      <Toggle id={moveModalId}>
        <MoveParticipantModal
          isPartOfGroup={isPartOfGroup}
          options={options.filter((o) => o.subEventId !== subEventId)}
          onCancel={toggleMoveModal}
          onOk={moveParticipant}
        />
      </Toggle>
      <Toggle id={deleteModalId}>
        <AlertDialog
          alertId={deleteModalId}
          styles={modalStyles}
          message={alertMessage}
          alertAction={deleteParticipant}
          alertCancel={toggleAlertModal}
          type="warning"
        />
      </Toggle>
      <div className="flex flex-col h-full w-full">
        <ParticipantFormHeader
          goback={goback}
          save={saveChanges}
          cancel={cancelParticipant}
          deleteItem={toggleAlertModal}
          move={toggleMoveModal}
        />
        {isLoading ? (
          <Loading styles={'items-center my-auto'} />
        ) : (
          <ParticipantForm
            items={inputs}
            error={error}
            handleInputChange={handleInputChange}
            values={values}
          />
        )}
      </div>
    </div>
  );
};

export default EditParticipant;
