import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../actions/allActions';
import { AppState } from '../../reducers/combineReducer';
import { selectSendInvoiceData } from '../../reducers/invoiceReducer';
import { InvoiceErrorsCard } from './InvoiceErrorsCard';
import { InvoicePreviewCard } from './InvoicePreviewItem';
import { messages } from '../translations';
import { FormattedMessage } from 'react-intl';
import { getErrorMessage } from '../../reducers/errorReducer';
import { useNotify } from '../../hooks/useNotify';
import ErrorFetching from '../../components/modals/ErrorFetching';

export const InvoiceSendForm: React.FC = () => {
  const { message } = useSelector((state: AppState) => getErrorMessage(state));
  const dispatch = useDispatch();
  const [previewIndex, setPreviewIndex] = useState(0);
  const errorMsg = useNotify(message);
  const { data, errors } = useSelector((state: AppState) =>
    selectSendInvoiceData(state)
  );
  const container = 'w-full h-full items-center flex justify-around divide-x-2';
  const cards =
    'max-h-11/12 min-h-11/12 w-full mx-2 flex flex-col justify-between p-8 overflow-auto scroller';
  const previewCard =
    'max-h-11/12 min-h-11/12 w-full mx-2 flex flex-col justify-between p-8 overflow-auto scroller';
  const noReceiversText = 'text-2xl text-brand-dark_blue';
  const sendInvoice = () => {
    dispatch(allActions.invoiceActions.startInvoiceSend(data));
  };

  const changeItem = (step: number) => {
    setPreviewIndex(previewIndex + step);
  };

  return (
    <div className={container}>
      {data.length > 0 ? (
        <InvoicePreviewCard
          amountOfItems={data.length}
          currentItem={previewIndex}
          data={data[previewIndex]}
          changeItem={changeItem}
          container={previewCard}
        />
      ) : (
        <div className={previewCard}>
          <span className={noReceiversText}>
            <FormattedMessage {...messages.prevnoreceivers} />
          </span>
        </div>
      )}
      <InvoiceErrorsCard
        errors={errors}
        container={cards}
        sendInvoice={sendInvoice}
      />
      <ErrorFetching
        styles={'absolute z-10 p-24 border shadow-xl bg-brand-white'}
        message={errorMsg}
      />
    </div>
  );
};
