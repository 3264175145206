import { FormattedMessageProps } from '../types/types';

export type TranslationKey =
  | 'main'
  | 'events'
  | 'allevents'
  | 'archivedevents'
  | 'createevent'
  | 'leads'
  | 'feedback'
  | 'marketing'
  | 'certs'
  | 'createcert'
  | 'allcerts'
  | 'createinvoice'
  | 'invoicing'
  | 'allinvoices'
  | 'account'
  | 'logout';

export const messages: Record<TranslationKey, FormattedMessageProps> = {
  main: {
    id: 'navigation.main',
    defaultMessage: 'Main',
  },
  events: {
    id: 'navigation.eventmenu',
    defaultMessage: 'Events',
  },
  allevents: {
    id: 'navigation.event.all',
    defaultMessage: 'All events',
  },
  archivedevents: {
    id: 'navigation.event.archived',
    defaultMessage: 'Archived',
  },
  createevent: {
    id: 'navigation.event.create',
    defaultMessage: 'New event',
  },
  leads: {
    id: 'navigation.lead.menu',
    defaultMessage: 'Leads',
  },
  feedback: {
    id: 'navigation.lead.feedback',
    defaultMessage: 'Feedback',
  },
  marketing: {
    id: 'navigation.lead.marketing',
    defaultMessage: 'Marketing',
  },
  certs: {
    id: 'navigation.cert.menu',
    defaultMessage: 'eDiplomas',
  },
  createcert: {
    id: 'navigation.cert.create',
    defaultMessage: 'New eDiploma',
  },
  allcerts: {
    id: 'navigation.cert.all',
    defaultMessage: 'All eDiplomas',
  },
  createinvoice: {
    id: 'navigation.invoicing.create',
    defaultMessage: 'New invoice',
  },
  invoicing: {
    id: 'navigation.invoicing',
    defaultMessage: 'eDiplomas',
  },
  allinvoices: {
    id: 'navigation.invoicing.all',
    defaultMessage: 'Invoices',
  },
  account: {
    id: 'navigation.account',
    defaultMessage: 'Account',
  },
  logout: {
    id: 'navigation.logout',
    defaultMessage: 'Logout',
  },
};
