import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ErrorFetching from '../../../../components/modals/ErrorFetching';
import Pagination from '../../../../components/utils/Pagination';
import SearchBar from '../../../../components/tableSearchBar/SearchBar';
import { EventParticipant, TableParticipant } from '../../../../types/types';
import {
  getUniqueKeysForTable,
  mapFromKeys,
} from '../../../../utils/functions';
import ParticipantTableHeader from './ParticipantHeader';
import ParticipantTableRow from './ParticipantTableRow';
import * as erState from '../../../../reducers/errorReducer';
import { useNotify } from '../../../../hooks/useNotify';
import { Loading } from '../../../../components/utils/Loading';
import { useTable } from '../../../../hooks/useTable';
import { BackDrop } from '../../../../components/tableSearchBar/BackDrop';
import { messages } from '../../../../components/tableSearchBar/translations';
import { Toggle } from '../../../../components/utils/Toggle';
import { SelectSubEventModal } from './SelectSubEventModal';
import { NewParticipantModal } from '../../views/EventParticipants';
import * as t from '../../../../components/tableSearchBar/translations';
import { useIntl } from 'react-intl';

interface ParticipantTableProps {
  list: EventParticipant[];
  tableList: TableParticipant[];
  headerRow: string[];
  loading: boolean;
  open: (subEventId: string, id: string) => void;
  tableName: string;
  createModal: NewParticipantModal;
}

export const ParticipantTable: React.FC<ParticipantTableProps> = ({
  list,
  tableList,
  open,
  headerRow,
  loading,
  tableName,
  createModal: { modalId, modalOpen, modalAction, options },
}) => {
  const { message } = useSelector(erState.getErrorMessage);
  const errorMsg = useNotify(message);
  const { formatMessage } = useIntl();
  const filterDivToggleId = 'EVENT_PARTICIPANTS_TABLE_FILTER';
  const tableSettingsDivId = 'EVENT_PARTICIPANTS_TABLE_SETTINGS';
  const exportSettingsDivId = 'EVENT_PARTICIPANTS_TABLE_EXPORT_SETTINGS';
  const pageLimit = 11;
  const initialState = {
    tableName: tableName,
    tableList: tableList,
    incomingHeaders: headerRow,
    currentPage: 1,
    totalPages: Math.ceil(tableList.length / pageLimit),
    pageLimit: pageLimit,
    totalRecords: tableList.length,
    pageNeighbours: 0,
    filteredList: tableList,
    currentList: tableList.slice(0, pageLimit),
    filterKeys: [],
    searchWord: '',
    sortKey: 'firstName',
    ascending: false,
    possibleSearchKeys: getUniqueKeysForTable(tableList),
    currentHeaders: headerRow,
    headers: {
      droppableColumnsSelected: headerRow,
      droppableColumnsAll: getUniqueKeysForTable(tableList).filter(
        (item) => !headerRow.includes(item)
      ),
    },
    export: {
      exportAll: {
        data: mapFromKeys(tableList, getUniqueKeysForTable(tableList)),
        filename: 'participants_all',
        label: formatMessage({ ...t.messages.exportall }),
      },
      exportView: {
        data: mapFromKeys(list, headerRow),
        filename: 'participants_view',
        label: formatMessage({ ...t.messages.exportview }),
      },
    },
  };

  const table = useTable(initialState);
  const currentList = table.state.currentList as TableParticipant[];

  const updateTableData = useCallback(() => {
    table.changeHeadersAndTable(tableList, headerRow);
  }, [tableList, headerRow, list]); //eslint-disable-line

  useEffect(() => {
    updateTableData();
  }, [updateTableData]);

  return (
    <>
      <BackDrop
        modalIds={[
          filterDivToggleId,
          tableSettingsDivId,
          exportSettingsDivId,
          modalId,
        ]}
      />
      <div className="flex flex-col shadow-lg bg-brand-white md:shadow-lg my-5 md:w-full min-h-11/12 h-11/12 overflow-y-auto overflow-x-hidden scroller">
        <ErrorFetching
          message={errorMsg}
          styles={
            'absolute z-10 top-1/3 right-1/2 px-10 p-6 bg-brand-white border shadow-lg rounded'
          }
        />
        <Toggle id={modalId}>
          <SelectSubEventModal
            modalId={modalId}
            modalOpen={modalOpen}
            modalAction={modalAction}
            options={options}
          />
        </Toggle>
        <SearchBar
          searchWord={table.state.searchWord}
          exportSettings={table.state.export}
          sortKeys={table.state.possibleSearchKeys}
          onDragEnd={table.onDragEnd}
          selectedColumns={table.state.headers.droppableColumnsSelected}
          restColumns={table.state.headers.droppableColumnsAll}
          filterDivToggleId={filterDivToggleId}
          tableSettingsDivToggleId={tableSettingsDivId}
          exportSettingsDivToggleId={exportSettingsDivId}
          activeFilters={table.state.filterKeys}
          toggleFilter={table.toggleFilter}
          clearFilters={table.clearFilters}
          onSearchWordChange={table.onSearchWordChange}
          optionalAction={{
            action: modalOpen,
            message: messages.newparticipant,
          }}
        />
        {loading ? (
          <Loading styles="my-auto" />
        ) : (
          <table className="md:bg-white rounded-lg md:table-fixed h-full w-full">
            <ParticipantTableHeader
              headers={table.state.headers.droppableColumnsSelected}
              sort={table.sort}
            />
            <tbody>
              {currentList.map((p, i) => (
                <ParticipantTableRow
                  participant={p}
                  key={i}
                  open={open}
                  headerKeys={table.state.headers.droppableColumnsSelected}
                />
              ))}
            </tbody>
          </table>
        )}
        <Pagination
          pageLimit={table.state.pageLimit}
          totalPages={table.state.totalPages}
          pageNeighbours={table.state.pageNeighbours}
          currentPage={table.state.currentPage}
          onPageChanged={table.gotoPage}
        />
      </div>
    </>
  );
};

export default ParticipantTable;
