import React, { useState } from 'react';
import { LabelContainer } from './labels/LabelContainer';
import { RepeatableButtons } from './buttons/RepeatableButtons';
import { ErrorDiv } from './divs/ErrorDiv';
import { RepeatableHeader } from './divs/RepatableHeader';
import { HoverAttributes } from '../utils/HoverMessage';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

interface FormProps {
  id: string;
  placeholder?: string;
  value?: string | number;
  question?: string;
  containerStyle?: string;
  divider?: string;
  repeatable?: boolean;
  inputStyle?: string;
  divStyle?: string;
  labelStyle?: string;
  dividerHeaderStyle?: string;
  register?: any;
  errors?: Record<string, Object>;
  link?: string | boolean;
  cursiveLabel?: string;
  repeatableHeader?: string;
  repeatableKey?: { key: string; last: boolean; amount: number };
  index?: number;
  overlayProps?: HoverAttributes;
  disabled?: boolean;
  changeAmount?: (key: string, direction: number) => void;
  onBlur?: (event: any) => void;
  validate?: (id: string) => void;
  iconPosition?: string;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
    type?: string
  ) => void;
}

export const FormPassword: React.FC<FormProps> = ({
  id,
  placeholder,
  handleInputChange,
  question,
  value,
  containerStyle,
  errors,
  link,
  onBlur,
  repeatableKey,
  changeAmount,
  disabled,
  divider,
  repeatable,
  inputStyle,
  labelStyle,
  divStyle,
  dividerHeaderStyle,
  register,
  cursiveLabel,
  repeatableHeader,
  validate,
  index,
  overlayProps,
  iconPosition,
}) => {
  const styles = inputStyle
    ? inputStyle
    : 'w-full font-semibold text-brand-dark_blue focus:outline-none focus:bg-blue-100 focus:shadow-inner border border-gray-300 rounded-lg py-2 pl-4 m-4 appearance-none leading-normal';

  const divStyles = divStyle
    ? divStyle
    : 'flex flex-col mb-4 xl:w-11/12 xl:m-auto';

  const participantId = repeatable ? id.split('_')[1] : ''; //expected id is 'BLABLABLA_2' => 2

  const dividerHeaderStyles = dividerHeaderStyle
    ? dividerHeaderStyle
    : 'm-2  font-bold text-lg text-brand-blue';

  const iconPos = iconPosition ? iconPosition : 'right-1';
  const dividerStyles = divider ? divider : '';
  const containerStyles = containerStyle ? containerStyle : '';
  const onBlurHandler = (e: any, id: string) => {
    if (onBlur) {
      return onBlur(e);
    } else if (validate) {
      return validate(id);
    } else {
      return null;
    }
  };

  const repeatIndex = participantId ? participantId : 1;

  const [inputType, setInputType] = useState<'password' | 'text'>('password');

  const toggleType = () => {
    const updated = inputType === 'password' ? 'text' : 'password';
    setInputType(updated);
  };

  return (
    <div
      className={`${divStyles} ${containerStyles} ${dividerStyles}`}
      tabIndex={index}
    >
      <RepeatableHeader
        divider={divider}
        repeatable={repeatable}
        label={`${repeatableHeader} ${repeatIndex}`}
        style={dividerHeaderStyles}
      />
      <LabelContainer
        link={link}
        question={question}
        labelStyle={labelStyle}
        cursiveLabel={cursiveLabel}
        overlayProps={overlayProps}
      />
      <div className="relative">
        <input
          className={`${styles} ${disabled ? 'bg-gray-200' : ''}`}
          min="1"
          type={inputType}
          id={id}
          value={value}
          name={id}
          placeholder={placeholder}
          onChange={(e) => handleInputChange(e, id)}
          ref={register}
          onBlur={(e) => onBlurHandler(e, id)}
          disabled={disabled ? true : false}
        />
        <span
          className={`absolute top-1/3 ${iconPos} mt-1 cursor-pointer text-brand-dark_blue hover:text-brand-blue`}
          onClick={toggleType}
        >
          {inputType === 'password' ? (
            <BsFillEyeFill size={20} />
          ) : (
            <BsFillEyeSlashFill size={20} />
          )}
        </span>
      </div>
      <ErrorDiv errors={errors} id={id} />
      <RepeatableButtons
        repeatableKey={repeatableKey}
        changeAmount={changeAmount}
        repeatableHeader={repeatableHeader}
      />
    </div>
  );
};

export default FormPassword;
