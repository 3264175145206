import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Navbar } from './Navbar';
import { AccountPage } from '../account/AccountPage';
import { CreateEventPage } from '../events/createEvent/CreateEventPage';
import { ListEventsPage } from '../events/listEvents/views/ListEventsPage';
import { MainPage } from '../pages/MainPage';
import { MarketingPage } from '../pages/MarketingPage';
import { FeedbackPage } from '../pages/FeedbackPage';
import { NotFound } from '../components/utils/NotFound';
import history from '../history/history';
import { CertPage } from '../certs/CertPage';
import AllCerts from '../certs/AllCerts';
import CertMenu from '../certs/CertMenu';
import EventMenu from '../events/EventMenu';
import EventNavigation from './EventNavigation';
import CertNavigation from './CertNavigation';
import InvoicingMenu from '../invoicing/InvoicingMenu';
import CreateInvoice from '../invoicing/views/Invoicing';
import AllInvoices from '../invoicing/views/AllInvoices';

export const Navigation: React.FC = () => {
  return (
    <Router history={history}>
      <div className="font-sans flex flex-row w-screen h-screen relative">
        <Navbar />
        <Switch>
          <Route exact path="/" render={() => <MainPage />} />
          <Route path="/eventmenu" render={() => <EventMenu />} />
          <Route path="/createevent" render={() => <CreateEventPage />} />
          <Route path="/events/:id" render={() => <EventNavigation />} />
          <Route
            exact
            path="/events"
            render={() => <ListEventsPage type={'unarchived'} />}
          />
          <Route
            exact
            path="/archivedevents"
            render={() => <ListEventsPage type={'archived'} />}
          />
          <Route path="/account" render={() => <AccountPage />} />
          <Route path="/marketing" render={() => <MarketingPage />} />
          <Route path="/feedback" render={() => <FeedbackPage />} />
          <Route path="/certmenu" render={() => <CertMenu />} />
          <Route path="/createcert" render={() => <CertPage />} />
          <Route exact path="/certs" render={() => <AllCerts />} />
          <Route path="/invoicingmenu" render={() => <InvoicingMenu />} />
          <Route path="/createinvoice" render={() => <CreateInvoice />} />
          <Route exact path="/invoices" render={() => <AllInvoices />} />
          <Route
            path="/certs/:certOperationId"
            render={() => <CertNavigation />}
          />
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
};

export default Navigation;
