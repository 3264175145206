import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { returnRequiredRule } from '../utils/functions';
import FormPassword from '../components/form/FormPassword';
import { PasswordChangeObject } from '../services/user';
import { PasswordChangeButtons } from './PasswordChangeButtons';
import { FormattedMessage, useIntl } from 'react-intl';
import { messages } from './translations';

type Props = {
  formVisible: boolean;
  toggleFormVisible: () => void;
  save: (data: PasswordChangeObject) => void;
};

export const PasswordChangeForm: React.FC<Props> = ({
  formVisible,
  toggleFormVisible,
  save,
}) => {
  const { register, handleSubmit, errors, trigger } = useForm();

  type PasswordFields = {
    oldpassword: string;
    newpassword1: string;
    newpassword2: string;
  };

  const initPasswordVals = {
    oldpassword: '',
    newpassword1: '',
    newpassword2: '',
  };
  const [passwordValues, changePasswordValues] = useState<PasswordFields>(
    initPasswordVals
  );

  const { formatMessage } = useIntl();

  const changePasswordValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: keyof PasswordFields
  ) => {
    changePasswordValues({ ...passwordValues, [id]: e.target.value });
  };

  function validate(id: string) {
    trigger(id);
  }

  const cancelPasswordChange = () => {
    toggleFormVisible();
    changePasswordValues(initPasswordVals);
  };

  const change = () => {
    save({
      oldPassword: passwordValues.oldpassword,
      newPassword: passwordValues.newpassword1,
    });
  };

  return (
    <>
      <button
        className={`bg-blue-400 hover:bg-brand-blue text-brand-white py-2 px-4 rounded m-4 mx-auto ${
          formVisible ? 'invisible' : 'visible'
        }`}
        onClick={toggleFormVisible}
      >
        <FormattedMessage {...messages.changepassword} /> 🔑
      </button>
      <form
        className={`flex flex-col ${formVisible ? 'visible' : 'invisible'}`}
        onSubmit={handleSubmit(change)}
      >
        <FormPassword
          id="oldpassword"
          value={passwordValues.oldpassword}
          question={formatMessage({ ...messages.oldpassword })}
          handleInputChange={changePasswordValue}
          register={returnRequiredRule('any', register)}
          validate={validate}
          errors={errors}
        />
        <FormPassword
          id="newpassword1"
          value={passwordValues.newpassword1}
          question={formatMessage({ ...messages.newpassword1 })}
          handleInputChange={changePasswordValue}
          register={returnRequiredRule('passwordminlength', register)}
          validate={validate}
          errors={errors}
        />
        <FormPassword
          id="newpassword2"
          value={passwordValues.newpassword2}
          question={formatMessage({ ...messages.newpassword2 })}
          handleInputChange={changePasswordValue}
          register={returnRequiredRule(
            'passwordmatch',
            register,
            passwordValues.newpassword1 === passwordValues.newpassword2
          )}
          validate={validate}
          errors={errors}
        />
        <PasswordChangeButtons cancelPasswordChange={cancelPasswordChange} />
      </form>
    </>
  );
};
