import React from 'react';
import { BreadCrumb } from '../components/breadcrumbs/BreadCrumb';
import { BreadRoute } from '../components/breadcrumbs/BreadRoute';
import { Card } from '../components/utils/Card';
import * as nt from '../navigation/navigationTranslations';
import { messages } from './translations';
import * as imgs from '../img/images';

export const InvoicingMenu: React.FC = () => {
  return (
    <div className="w-11/12 h-screen mx-auto">
      <BreadRoute>
        <BreadCrumb
          label={'invoice-menu'}
          link={'/invoicingmenu'}
          lastChild={true}
        />
      </BreadRoute>
      <div className="h-full max-h-11/12 w-full flex justify-center">
        <Card
          header={{ ...messages.createinvoicingheader }}
          paragraph={{ ...messages.createinvoicingtext }}
          link={`/createinvoice`}
          linkMessage={{ ...nt.messages.createinvoice }}
          image={imgs.INVOICE_CARD_CREATE}
          canClick={true}
        />
        <Card
          header={{ ...messages.allinvoicesheader }}
          paragraph={{ ...messages.allinvoicestext }}
          link={`/invoices`}
          linkMessage={{ ...nt.messages.allinvoices }}
          image={imgs.INVOICE_CARD_ALL}
          canClick={true}
        />
      </div>
    </div>
  );
};

export default InvoicingMenu;
