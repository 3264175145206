import React from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from '../translations';

type Props = {
  amountOfItems: number;
};
export const InvoicePreviewHeader: React.FC<Props> = ({ amountOfItems }) => {
  const header = 'text-3xl text-brand-dark_blue';
  const message = 'text-xl text-brand-dark_blue';
  return (
    <div className="flex flex-col divide-y">
      <div>
        <span className={header}>
          <FormattedMessage {...messages.prevheader} />
        </span>
      </div>
      <div className="py-2">
        <span className={message}>
          <FormattedMessage
            {...messages.prevdescription}
            values={{
              amount: amountOfItems,
              bold: (amount: string) => <b>{amount}</b>,
            }}
          />
        </span>
      </div>
    </div>
  );
};
