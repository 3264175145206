import {
  CreateEventObject,
  FormInputInfo,
  FormState,
  NewMultiCertRequest,
  NewSingleCertRequest,
} from '../types/types';
import { LoopReducer, loop, Loop, Cmd } from 'redux-loop';
import formService from '../services/forms';
import {
  getType,
  createAsyncAction,
  ActionType,
  createAction,
} from 'typesafe-actions';
import { loginAsync } from '../login/loginReducer';
import {
  createEventAsync,
  getDefaultForm,
  getNewParticipantForm,
  getSubEventDetails,
  importRegistrationState,
} from '../events/eventReducer';
import {
  arrayToObject,
  arrayToObjectVariation,
  concatNarrays,
  formatDate,
  getArrays,
} from '../utils/functions';
import {
  changeSelectedTemplate,
  newCertSend,
  setCertTemplateDefaultValues,
} from '../certs/certReducer';
import certService from '../services/certs';
import { uploadFileToForm } from './fileUploadReducer';
import { AppState } from './combineReducer';
import eventService from '../services/events';
import { defaultQuestions } from './defaultQuestions';

const initialState: FormState = {
  input: {
    loginForm: { username: '', password: '' },
    createNewEvent: {
      eventName: '',
      eventOrganiser: '',
      eventPlace: '',
      eventStreetAddress: '',
      eventZip: '',
      eventCity: '',
      eventCountry: '',
      eventStartDate: null,
      eventEndDate: null,
      eventWebPage: '',
      eventDescription: '',
      eventInfoText: '',
      eventHelpText: '',
      eventSupportEmail: '',
      eventSupportPhone: '',
      eventSupportInstruction: '',
    },
    selectRegistration: {},
    previewRegistrationForm: {},
    RegistrationInfo: {},
    newCert: {},
    newParticipant: {},
    newInvoice: {
      delivery_method: 'finvoice',
      notes_before: '',
      invoice_date: formatDate(new Date()),
      due_date: formatDate(
        new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000)
      ),
      row_quantity: '1',
      row_vatpercent: '24',
    },
  },
  items: {
    loginForm: [],
    createNewEvent: [],
    selectRegistration: [],
    previewRegistrationForm: [],
    newParticipant: [],
  },
};

export const getFormAsync = createAsyncAction(
  'START_FORM_FETCH',
  'FORM_FETCH_COMPLETE',
  'FORM_FETCH_FAIL'
)<{ form: string; language: string }, { data: any; status: number }, Error>();

const updateValue = createAction('UPDATEVALUE')<{
  form: string;
  id: string;
  value: string;
}>();
const updateDateValue = createAction('UPDATEDATEVALUE')<{
  form: string;
  id: string;
  value: Date;
}>();
const addToValue = createAction('ADDTOVALUE')<{
  form: string;
  id: string;
  value: string[];
}>();
const removeKey = createAction('REMOVEKEY')<{ form: string; id: string }>();
const setForm = createAction('SET_FORM')<{
  id: string;
  form: FormInputInfo[];
}>();
const setFormValues = createAction('SET_FORM_VALUES')<{
  id: string;
  data: { [key: string]: string };
}>();
const setCreateEvent = createAction('SET_CREATE_EVENT_DATA')<undefined>();

const setAndPreserveFormValues = createAction('SET_AND_PRESERVE_FORM_VALUES')<{
  id: string;
  data: { [key: string]: string };
}>();

type Action =
  | ActionType<typeof getFormAsync>
  | ActionType<typeof getNewParticipantForm>
  | ActionType<typeof loginAsync>
  | ActionType<typeof createEventAsync>
  | ActionType<typeof getDefaultForm>
  | ActionType<typeof getSubEventDetails>
  | ActionType<typeof importRegistrationState>
  | ActionType<typeof newCertSend>
  | ActionType<typeof createEventAsync>
  | ActionType<typeof uploadFileToForm>
  | ActionType<typeof changeSelectedTemplate>
  | ActionType<typeof updateValue>
  | ActionType<typeof updateDateValue>
  | ActionType<typeof addToValue>
  | ActionType<typeof removeKey>
  | ActionType<typeof setForm>
  | ActionType<typeof setAndPreserveFormValues>
  | ActionType<typeof setFormValues>
  | ActionType<typeof setCertTemplateDefaultValues>
  | ActionType<typeof setCreateEvent>;

export const formReducer: LoopReducer<FormState, Action> = (
  state: FormState = initialState,
  action: Action
): FormState | Loop<FormState> => {
  switch (action.type) {
    case getType(getDefaultForm.success):
      const itemsTable = arrayToObject(
        action.payload.data.defaultCustomerForm.inputs,
        'id'
      );
      const allBaskets = Object.keys(
        action.payload.data.defaultCustomerForm.baskets
      );
      const previewList = concatNarrays(
        getArrays(allBaskets, action.payload.data.defaultCustomerForm.baskets)
      ).map((id) => itemsTable[id]);
      return {
        ...state,
        items: {
          ...state.items,
          previewRegistrationForm: previewList,
        },
      };

    case getType(getSubEventDetails.success):
      return {
        ...state,
        items: {
          ...state.items,
          previewRegistrationForm: action.payload.data.registrationQuestions,
        },
      };

    case getType(importRegistrationState.success):
      if (action.payload.keys.questions) {
        return {
          ...state,
          items: {
            ...state.items,
            previewRegistrationForm: action.payload.data.registrationQuestions,
          },
        };
      }
      return state;

    case getType(getNewParticipantForm.request):
      return loop(
        state,
        Cmd.run(eventService.getSubEventDetails, {
          successActionCreator: getNewParticipantForm.success,
          failActionCreator: getNewParticipantForm.failure,
          args: [action.payload],
        })
      );

    case getType(getNewParticipantForm.success):
      const formItems = action.payload.data.registrationQuestions
        ? action.payload.data.registrationQuestions
        : defaultQuestions;
      return {
        ...state,
        items: {
          ...state.items,
          newParticipant: formItems,
        },
        input: {
          ...state.input,
          newParticipant: {},
        },
      };

    case getType(getNewParticipantForm.failure):
      return {
        ...state,
        items: {
          ...state.items,
          newParticipant: [],
        },
        input: {
          ...state.input,
          newParticipant: {},
        },
      };

    case getType(createEventAsync.success):
      return {
        ...state,
        input: {
          ...state.input,
          createNewEvent: {
            ...initialState.input.createNewEvent,
            eventOrganiser: state.input.createNewEvent.eventOrganiser,
          },
        },
      };

    case getType(setForm):
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: action.payload.form,
        },
      };

    case getType(setFormValues):
      return {
        ...state,
        input: {
          ...state.input,
          [action.payload.id]: action.payload.data,
        },
      };

    case getType(setAndPreserveFormValues):
      return {
        ...state,
        input: {
          ...state.input,
          [action.payload.id]: {
            ...state.input[action.payload.id],
            ...action.payload.data,
          },
        },
      };

    case getType(uploadFileToForm.success):
      return loop(
        {
          ...state,
          input: {
            ...state.input,
            [action.payload.context.formName]: {
              ...state.input[action.payload.context.formName],
              [action.payload.context.questionId]:
                action.payload.data.resourceId.toString(),
            },
          },
        },
        Cmd.action({
          type: 'TOGGLE',
          payload: action.payload.context.toggleId,
        })
      );

    case getType(updateValue):
      return {
        ...state,
        input: {
          ...state.input,
          [action.payload.form]: {
            ...state.input[action.payload.form],
            [action.payload.id]: action.payload.value,
          },
        },
      };
    case getType(updateDateValue):
      const { form, value, id } = action.payload;
      const updatedValue =
        value && form === 'createNewEvent'
          ? value.toISOString()
          : value
          ? formatDate(value)
          : null;

      return {
        ...state,
        input: {
          ...state.input,
          [form]: {
            ...state.input[form],
            [id]: updatedValue,
          },
        },
      };
    case getType(addToValue):
      return {
        ...state,
        input: {
          ...state.input,
          [action.payload.form]: {
            ...state.input[action.payload.form],
            [action.payload.id]: action.payload.value,
          },
        },
      };

    case getType(removeKey):
      if (state.input[action.payload.form][action.payload.id]) {
        const { [action.payload.id]: remove, ...rest } =
          state.input[action.payload.form];
        return {
          ...state,
          input: {
            ...state.input,
            [action.payload.form]: rest,
          },
        };
      }
      return state;

    case getType(changeSelectedTemplate):
      return {
        ...state,
        input: {
          ...state.input,
          newCert: {},
        },
      };

    case getType(setCreateEvent):
      return loop(
        state,
        Cmd.action({
          type: 'START_EVENT_CREATE_REQUEST',
          payload: state.input.createNewEvent,
        })
      );

    //get form
    case getType(getFormAsync.request):
      return loop(
        state,
        Cmd.run(formService.getForm, {
          successActionCreator: getFormAsync.success,
          failActionCreator: getFormAsync.failure,
          args: [action.payload],
        })
      );
    case getType(getFormAsync.success):
      if (action.payload.status === 200) {
        return {
          ...state,
          items: {
            ...state.items,
            [action.payload.data.formName]: action.payload.data.inputs,
          },
        };
      }
      return state;

    case getType(loginAsync.success):
      return {
        ...state,
        input: {
          ...state.input,
          loginForm: initialState.input.loginForm,
          createNewEvent: {
            ...state.input.createNewEvent,
            eventOrganiser: action.payload.data.customer.customerName,
          },
        },
      };

    case getType(loginAsync.failure):
      return {
        ...state,
        input: {
          ...state.input,
          loginForm: initialState.input.loginForm,
        },
      };

    //new cert send
    case getType(newCertSend.request):
      //ennen kuin mennään pidemmälle, tarkastetaan, että kaikki pakolliset kohdat on täytetty
      const allRequired =
        action.payload.template.postmarkTemplateDataModel.postmarkTemplateQuestions
          .filter((q) => q.required)
          .map((q) => {
            return {
              id: q.id,
              question: q.question,
              defaultValue: q.defaultValue ? true : false,
            };
          })
          .concat(
            action.payload.template.templateDataModel.templateQuestions
              .filter((q) => q.required)
              .map((q) => {
                return {
                  id: q.id,
                  question: q.question,
                  defaultValue: q.defaultValue ? true : false,
                };
              })
          );

      const missing = allRequired
        .map((q) =>
          state.input.newCert[q.id]
            ? false
            : q.defaultValue
            ? false
            : q.question
        )
        .filter((r) => typeof r === 'string');
      if (missing.length > 0) {
        return loop(
          state,
          Cmd.action({
            type: 'SET_ERROR_MESSAGE_CERT_MISSING_REQUIRED_FIELD',
            payload: missing,
          })
        );
      }

      //seuraavaksi tarkistetaan, että sähköpostikentät on oikein!

      if (action.payload.certOperationType === 'singleSend') {
        const templateData =
          action.payload.template.templateDataModel.templateQuestions.reduce(
            (map, obj) => {
              map[obj.id] = state.input.newCert[obj.id]
                ? state.input.newCert[obj.id]
                : obj.defaultValue
                ? obj.defaultValue
                : '';
              return map;
            },
            {}
          );

        const postmarkTemplateData =
          action.payload.template.postmarkTemplateDataModel.postmarkTemplateQuestions.reduce(
            (map, obj) => {
              map[obj.id] = obj.readOnly
                ? obj.defaultValue
                : state.input.newCert[obj.id]
                ? state.input.newCert[obj.id]
                : obj.defaultValue
                ? obj.defaultValue
                : '';
              return map;
            },
            {}
          );

        const newCertRequestObject: NewSingleCertRequest = {
          certOperationId: action.payload.certOperationId,
          certOperationType: action.payload.certOperationType,
          templateId: action.payload.template.templateId,
          preview: action.payload.preview,
          downloadPreview: action.payload.downloadPreview,
          templateData,
          postmarkTemplateData,
        };

        return loop(
          { ...state },
          Cmd.run(certService.newCertSend, {
            successActionCreator: newCertSend.success,
            failActionCreator: newCertSend.failure,
            args: [newCertRequestObject],
          })
        );
      }

      const certData = action.payload.selected.map((person) => {
        const templateData =
          action.payload.template.templateDataModel.templateQuestions.reduce(
            (map, obj) => {
              map[obj.id] = obj.person
                ? person[state.input.newCert[obj.id].toString()]
                : state.input.newCert[obj.id]
                ? state.input.newCert[obj.id]
                : obj.defaultValue
                ? obj.defaultValue
                : '';
              return map;
            },
            {}
          );
        const postmarkTemplateData =
          action.payload.template.postmarkTemplateDataModel.postmarkTemplateQuestions.reduce(
            (map, obj) => {
              map[obj.id] = obj.person
                ? person[state.input.newCert[obj.id].toString()]
                : obj.readOnly
                ? obj.defaultValue
                : state.input.newCert[obj.id]
                ? state.input.newCert[obj.id]
                : obj.defaultValue
                ? obj.defaultValue
                : '';
              return map;
            },
            {}
          );
        return {
          templateData,
          postmarkTemplateData,
        };
      });

      const newCertRequestObject: NewMultiCertRequest = {
        templateId: action.payload.template.templateId,
        certOperationId: action.payload.certOperationId,
        certOperationType: action.payload.certOperationType,
        preview: action.payload.preview,
        downloadPreview: action.payload.downloadPreview,
        certData,
      };

      return loop(
        state,
        Cmd.run(certService.newCertSend, {
          successActionCreator: newCertSend.success,
          failActionCreator: newCertSend.failure,
          args: [newCertRequestObject],
        })
      );

    case getType(newCertSend.success):
      if (
        action.payload.status === 200 &&
        action.payload.data.status.toLowerCase() === 'sent'
      ) {
        const { newCert, ...rest } = state.input;
        return { ...state, input: rest };
      }
      return state;

    case getType(newCertSend.failure):
      return state;

    case getType(setCertTemplateDefaultValues):
      const newCert = arrayToObjectVariation(
        action.payload,
        'id',
        'defaultValue'
      );
      return {
        ...state,
        input: {
          ...state.input,
          newCert,
        },
      };

    default:
      return state;
  }
};

export type CreateEventFormData = {
  eventStartId: keyof CreateEventObject;
  eventEndId: keyof CreateEventObject;
  eventNameId: keyof CreateEventObject;
  eventOrganiserId: keyof CreateEventObject;
  eventDescriptionId: keyof CreateEventObject;
  eventPlaceId: keyof CreateEventObject;
  eventCityId: keyof CreateEventObject;
  eventZipId: keyof CreateEventObject;
  eventStreetAddressId: keyof CreateEventObject;
  eventCountryId: keyof CreateEventObject;
  supportEmailId: keyof CreateEventObject;
  eventWebPageId: keyof CreateEventObject;
  supportPhoneId: keyof CreateEventObject;
  supportInstructionId: keyof CreateEventObject;
  eventStartDateValue: Date | null;
  eventEndDateValue: Date | null;
  eventNameValue: string;
  eventOrganiserValue: string;
  eventDescriptionValue: string;
  eventPlaceValue: string;
  eventCityValue: string;
  eventZipValue: string;
  eventStreetAddressValue: string;
  eventCountryValue: string;
  supportEmailValue: string;
  eventWebPageValue: string;
  supportPhoneValue: string;
  supportInstructionValue: string;
  formState: { [key: string]: string };
};
export function selectCreateEventFormInputs(
  state: AppState
): CreateEventFormData {
  const eventStartId = 'eventStartDate';
  const eventEndId = 'eventEndDate';
  const eventNameId = 'eventName';
  const eventOrganiserId = 'eventOrganiser';
  const eventDescriptionId = 'eventDescription';
  const eventPlaceId = 'eventPlace';
  const eventCityId = 'eventCity';
  const eventZipId = 'eventZip';
  const eventStreetAddressId = 'eventStreetAddress';
  const eventCountryId = 'eventCountry';
  const supportEmailId = 'eventSupportEmail';
  const eventWebPageId = 'eventWebPage';
  const supportPhoneId = 'eventSupportPhone';
  const supportInstructionId = 'eventSupportInstruction';
  const formState = state.formState.input.createNewEvent;
  const eventStart = formState[eventStartId];
  const eventEnd = formState[eventEndId];
  const eventStartDateValue =
    typeof eventStart !== 'string' ||
    eventStart === undefined ||
    eventStart === null
      ? null
      : new Date(eventStart);
  const eventEndDateValue =
    typeof eventEnd !== 'string' || eventEnd === undefined || eventEnd === null
      ? null
      : new Date(eventEnd);
  const eventNameValue = formState[eventNameId] || '';
  const eventOrganiserValue = formState[eventOrganiserId] || '';
  const eventDescriptionValue = formState[eventDescriptionId] || '';
  const eventPlaceValue = formState[eventPlaceId] || '';
  const eventCityValue = formState[eventCityId] || '';
  const eventZipValue = formState[eventZipId] || '';
  const eventStreetAddressValue = formState[eventStreetAddressId] || '';
  const eventCountryValue = formState[eventCountryId] || '';
  const supportEmailValue = formState[supportEmailId] || '';
  const eventWebPageValue = formState[eventWebPageId] || '';
  const supportPhoneValue = formState[supportPhoneId] || '';
  const supportInstructionValue = formState[supportInstructionId] || '';
  return {
    eventCityId,
    eventCountryId,
    eventDescriptionId,
    eventEndId,
    eventNameId,
    eventOrganiserId,
    eventPlaceId,
    eventStartId,
    eventStreetAddressId,
    eventWebPageId,
    eventZipId,
    supportEmailId,
    supportInstructionId,
    supportPhoneId,
    eventCityValue,
    eventCountryValue,
    eventDescriptionValue,
    eventEndDateValue,
    eventNameValue,
    eventOrganiserValue,
    eventPlaceValue,
    eventStartDateValue,
    eventStreetAddressValue,
    eventWebPageValue,
    eventZipValue,
    supportEmailValue,
    supportInstructionValue,
    supportPhoneValue,
    formState,
  };
}

export function getFormState(state: AppState): FormState {
  return state.formState;
}

export function getPreviewFormItems(state: AppState): FormInputInfo[] {
  const items = state.formState.items.previewRegistrationForm
    ? state.formState.items.previewRegistrationForm
    : [];
  return items;
}

export function getCreateEventFormItems(state: AppState): FormInputInfo[] {
  return state.formState.items.createNewEvent;
}

export const selectCreateParticipantFormItems = (
  state: AppState
): FormInputInfo[] => {
  return state.formState.items.newParticipant;
};

export function getLoginFormValues(state: AppState): {
  username: string;
  password: string;
} {
  return {
    username: state.formState.input.loginForm.username,
    password: state.formState.input.loginForm.password,
  };
}

export function getNewCertTemplateFormValues(state: AppState): {
  [key: string]: any;
} {
  return state.formState.input.newCert ? state.formState.input.newCert : {};
}

export function selectFormInputValues(
  state: AppState,
  formName: string
): { [key: string]: string | string[] } {
  return state.formState.input[formName] ? state.formState.input[formName] : {};
}

export type FormValues = {
  allItems: FormInputInfo[];
  items: FormInputInfo[];
  values: { [key: string]: string | string[] };
  repeatableLength: number;
  pages: number[];
  maxPage: number;
};
export function selectFormItemsAndValues(
  state: AppState,
  formName: string
): FormValues {
  const values = state.formState.input[formName]
    ? state.formState.input[formName]
    : {};
  const formItems: FormInputInfo[] = state.formState.items[formName]
    ? state.formState.items[formName]
    : [];
  const repeatableAdded = modifyListDependingOnStateAndRepeats(
    values,
    formItems,
    returnRepeatableGroups(formItems.filter((q) => q.repeatable))
  );
  const pages =
    formItems.length > 0
      ? Array.from(new Set(formItems.map((item) => item.page)))
      : [0];
  const items = filterList(repeatableAdded, values);
  const maxPage = pages[pages.length - 1];
  return {
    values,
    items,
    repeatableLength: repeatableAdded.length,
    allItems: formItems,
    pages,
    maxPage,
  };
}

function returnRepeatableGroups(
  listOfRepeatableQuestions: FormInputInfo[]
): FormInputInfo[][] {
  const sortedList = listOfRepeatableQuestions.sort(
    (a, b) =>
      a.page - b.page ||
      a.repeatable.key.localeCompare(b.repeatable.key) ||
      a.repeatable.value.localeCompare(b.repeatable.value) ||
      a.repeatable.amount.localeCompare(b.repeatable.amount)
  );
  const allGroups: FormInputInfo[][] = [];
  let uniqueGroup: FormInputInfo[] = [];
  let prevObj = {
    after: '',
    amount: '',
    key: '',
    value: '',
  };
  for (const question of sortedList) {
    const currObj = {
      after: question.repeatable.after,
      amount: question.repeatable.amount,
      key: question.repeatable.key,
      value: question.repeatable.value,
    };
    if (checkIfObjectMatches(prevObj, currObj)) {
      uniqueGroup.push(question);
    } else {
      if (uniqueGroup.length !== 0) {
        allGroups.push(changeLastElement(uniqueGroup));
      }
      uniqueGroup = [question];
    }
    prevObj = currObj;
  }
  if (uniqueGroup.length > 0) {
    allGroups.push(changeLastElement(uniqueGroup));
  }
  return allGroups;
}

function checkIfObjectMatches(
  obj: { after: string; amount: string; key: string; value: string },
  obj2: { after: string; amount: string; key: string; value: string }
) {
  return (
    obj.amount === obj2.amount &&
    obj.key === obj2.key &&
    obj.value === obj2.value
  );
}

function changeLastElement(array: FormInputInfo[]) {
  const copyOfLastElement = { ...array[array.length - 1] };
  const changedLastElement = {
    ...copyOfLastElement,
    repeatable: { ...copyOfLastElement.repeatable, last: true },
  };
  array.splice(array.length - 1, 1, changedLastElement);
  return array;
}

function modifyListDependingOnStateAndRepeats(
  formState: { [key: string]: string | string[] },
  allQuestions: FormInputInfo[],
  repeatableGroups: FormInputInfo[][]
) {
  const copyOfQuestions = [...allQuestions];
  for (const group of repeatableGroups) {
    if (
      group[0].repeatable.key === '*' ||
      formState[group[0].repeatable.key] === group[0].repeatable.value
    ) {
      const lastItem = group.find((s) => s.repeatable.last);
      const lastItemIndex = copyOfQuestions.findIndex(
        (s) => s.id === lastItem.id
      );
      const groupAmount = group[0].repeatable.amount;
      const numberOfRepeats = !isNaN(Number(groupAmount))
        ? Number(groupAmount)
        : formState[groupAmount] === undefined
        ? 0
        : Number(formState[groupAmount]) - 1;
      const repeated = Array(numberOfRepeats).fill(group);
      const flattened: FormInputInfo[] = repeated.reduce(
        (acc, val) => acc.concat(val),
        []
      );
      let iterator = 2;
      let groupCounter = 0;
      let maxGroup = copyOfQuestions
        .map((item) => item.group)
        .reduce((a, b) => {
          return Math.max(a, b);
        });
      const result = flattened.map((item) => {
        groupCounter++;
        if (groupCounter > group.length) {
          iterator++;
          groupCounter = 1;
        }
        return {
          ...item,
          id: `${item.id}_${iterator}`,
          group: maxGroup + (iterator - 1),
          ...(item.hide && {
            hide: {
              ...item.hide,
              key: getCorrectKey(iterator, item.hide.key, allQuestions),
            },
          }),
        };
      });
      const afterIndex = allQuestions.findIndex(
        (item) => item.id === group[0].repeatable.after
      );
      copyOfQuestions[lastItemIndex] = {
        ...copyOfQuestions[lastItemIndex],
        repeatable: {
          ...copyOfQuestions[lastItemIndex].repeatable,
          last: true,
        },
      };
      copyOfQuestions.splice(afterIndex, 0, ...result);
    }
  }
  return copyOfQuestions;
}

function getCorrectKey(
  iterator: number,
  key: string,
  allQuestions: FormInputInfo[]
) {
  const item = allQuestions.find((q) => q.id === key);
  if (item.repeatable) {
    return `${key}_${iterator}`;
  }
  return key;
}

function filterList(
  list: FormInputInfo[],
  formState: { [key: string]: string | string[] }
) {
  return list.filter((q) => shouldShow(q, formState));
}

function shouldShow(
  q: FormInputInfo,
  formState: { [key: string]: string | string[] }
) {
  return q.show ? hasHidden(q, formState) : false;
}
function hasHidden(
  q: FormInputInfo,
  formState: { [key: string]: string | string[] }
) {
  return q.hide ? hideTypeSelect(q, formState) : q;

  function hideTypeSelect(
    q: FormInputInfo,
    formState: { [key: string]: string | string[] }
  ) {
    return q.hide.type.toLowerCase() === 'show'.toLowerCase()
      ? hiddenIsCorrectShow(q, formState)
      : hiddenIsCorrectHide(q, formState);
  }
}
function hiddenIsCorrectShow(
  q: FormInputInfo,
  formState: { [key: string]: string | string[] }
) {
  if (Array.isArray(formState[q.hide.key])) {
    return formState[q.hide.key].includes(q.hide.value) ? q : false;
  }
  return q.hide.value === formState[q.hide.key] ? q : false;
}

function hiddenIsCorrectHide(
  q: FormInputInfo,
  formState: { [key: string]: string | string[] }
) {
  if (Array.isArray(formState[q.hide.key])) {
    return !formState[q.hide.key].includes(q.hide.value) ? q : [];
  }
  return q.hide.value !== formState[q.hide.key] ? q : false;
}
