import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedMessageProps } from '../types/types';

interface Props {
  header: FormattedMessageProps;
}

export const AccountDataCard: React.FC<Props> = ({ header, children }) => {
  return (
    <div className="flex flex-col justiyfy-center items-center w-1/2 m-2 border shadow-xl">
      <span className="text-3xl text-brand-dark_blue p-4">
        <FormattedMessage {...header} />
      </span>
      {children}
    </div>
  );
};
