import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedMessageProps } from '../../types/types';
import { CgSoftwareUpload } from 'react-icons/cg';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  styles?: string;
  onOk: () => void;
  msg: FormattedMessageProps;
}

export const InputUploadButton: React.FC<Props> = ({
  styles,
  onOk,
  msg,
  ...buttonProps
}) => {
  const style = styles
    ? styles
    : 'flex cursor-pointer items-center justify-around bg-brand-white hover:bg-blue-200 text-brand-blue m-4 rounded w-40 h-10 border border-gray-200 shadow-lg';

  return (
    <div className={style}>
      <CgSoftwareUpload size={20} />
      <button onClick={onOk} {...buttonProps}>
        <FormattedMessage {...msg} />
      </button>
    </div>
  );
};
