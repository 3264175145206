import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { messages } from '../translations/confirmsend';

import { EmailErrors } from '../certReducer';
import { emailMappingLabels } from '../wysiwygEmailMappings';

type Props = {
  errors: EmailErrors;
};

export const EmailErrorsView: React.FC<Props> = ({ errors }) => {
  const emoji = errors.numberOfErrors > 0 ? '❗' : '✅';

  const { formatMessage } = useIntl();

  const textColor = 'text-brand-dark_blue';

  return (
    <div className="p-4">
      <span className={`text-xl py-2 ${textColor}`}>
        3. <FormattedMessage {...messages.emailerrors} /> {emoji}
      </span>
      <div className={`p-2 ${textColor}`}>
        <FormattedMessage
          {...messages.numberofemailerrors}
          values={{
            numberOfErrors: errors.numberOfErrors,
            bold: (numberOfErrors: string) => <b>{numberOfErrors}</b>,
          }}
        />
      </div>
      <ol className="list-decimal p-4">
        {Object.keys(errors.errors).map((errKey) => {
          const errorKey = errors.errors[errKey].key;
          const indexes = errors.errors[errKey].indexes
            .map((value) => value + 1)
            .join(', ');
          const header = formatMessage({ id: emailMappingLabels[errorKey] });

          return (
            <li className="mb-2" key={errors.errors[errKey].key}>
              <div className={textColor}>
                <FormattedMessage
                  {...messages.emailerrorindexes}
                  values={{ indexes }}
                />
              </div>
              <div className={textColor}>
                <FormattedMessage
                  id={errors.errors[errKey].error}
                  values={{ key: header }}
                />
              </div>
            </li>
          );
        })}
      </ol>
    </div>
  );
};
