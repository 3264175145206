import { FormInputInfo, RequiredType } from '../types/types';

type SummaryFormInputInfo = FormInputInfo & { count: number };
export const getProductSummary = (
  questions: FormInputInfo[],
  formState: { [key: string]: string | string[] }
) => {
  const products = questions
    .filter((item) => item.product)
    .reduce<SummaryFormInputInfo[]>((acc, curr) => {
      const found = acc.find((q) => q.id === removeLastChars(curr.id)); //because if multiple same questions, id is same but with added _{number} after ==> Registration, Registration_1, Registration_2....
      const amount = found ? found.count + 1 : 1;
      const filtered = found ? acc.filter((q) => q.id === found.id) : acc;
      return [...filtered, { ...curr, count: amount }];
    }, []);
  const sum = products.reduce((acc, curr) => {
    return (acc = acc + getAmount(curr, formState));
  }, 0);
  return sum > 0 ? sum.toFixed(2) : null;
};

const removeLastChars = (str: string) => {
  return str.substring(0, str.length - 2);
};

const getAmount = (
  curr: SummaryFormInputInfo,
  formState: { [key: string]: string | string[] }
) => {
  const val = (formState[curr.id] as string) ?? '0';
  const valueArr = val.replace(',', '.').split(' ');
  const multiplier =
    curr.product.multiplier === 'Self'
      ? curr.count
      : Number(formState[curr.product.multiplier]) ?? 1;
  const tax = Number(curr.product.tax) / 100 + 1;
  return Number(valueArr[valueArr.length - 1]) * multiplier * tax;
};

export function returnRequiredRule(
  obj: { type: RequiredType; key?: string; value?: string },
  register: any,
  formState: { [key: string]: string | string[] }
) {
  if (obj.key && obj.value) {
    if (formState[obj.key] !== obj.value) {
      return register();
    }
  }
  if (obj.type === 'email') {
    return register({
      required: `any`,
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, //eslint-disable-line
        message: `email`,
      },
    });
  }
  if (obj.type === 'phone') {
    return register({
      required: `any`,
      pattern: {
        value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, //eslint-disable-line
        message: `phone`,
      },
    });
  }
  if (obj.type === 'password') {
    return register({
      required: 'any',
      minLength: { value: 4, message: 'email' },
    });
  }
  if (obj.type === 'any') {
    return register({ required: `any` });
  }
  return register();
}
