import React from 'react';
import { useSelector } from 'react-redux';
import * as uState from '../reducers/userReducer';
import { NavIconItem } from './NavIconItem';
import { VscSignOut } from 'react-icons/vsc';

type Props = {
  logout: () => void;
};

export const MinimizedNavigation: React.FC<Props> = ({ logout }) => {
  const services = useSelector(uState.selectServices);
  const iconNavClass = 'text-white hover:text-blue-300 m-1';
  return (
    <div className="flex flex-col h-full divide-y justify-center">
      {services['events'] && (
        <div className="flex flex-col">
          <NavIconItem link={'/createevent'} icon={'calendarplus'} />
          <NavIconItem link={'/events'} icon={'calendar'} />
        </div>
      )}
      {services['certificates'] && (
        <div className="flex flex-col">
          <NavIconItem link={'/createcert'} icon={'fileadd'} />
          <NavIconItem link={'/certs'} icon={'filepaper'} />
        </div>
      )}
      {services['invoicing'] && (
        <div className="flex flex-col">
          <NavIconItem link={'/createinvoice'} icon={'billwave'} />
          <NavIconItem link={'/invoices'} icon={'webmoney'} />
        </div>
      )}
      <div className="flex flex-col">
        <NavIconItem link={'/account'} icon={'account'} />
        <div className="p-2 cursor-pointer" onClick={logout} title="logout">
          <VscSignOut size={24} className={iconNavClass} />
        </div>
      </div>
    </div>
  );
};
