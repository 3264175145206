import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../actions/allActions';
import { selectNewCertValidation } from '../certReducer';
import * as eState from '../../reducers/errorReducer';
import { useNotify } from '../../hooks/useNotify';
import { ErrorFetching } from '../../components/modals/ErrorFetching';
import { messages } from '../translations/confirmsend';
import { AppState } from '../../reducers/combineReducer';
import { PreviewCard } from './PreviewCard';
import { CertErrorsCard } from './CertErrorsCard';

interface ConfirmSendProps {
  templateId: string;
}

export const CertSendContainer: React.FC<ConfirmSendProps> = ({
  templateId,
}) => {
  const dispatch = useDispatch();
  const { message } = useSelector(eState.getErrorMessage);
  const { errors, amountOfReceivers } = useSelector((state: AppState) =>
    selectNewCertValidation(state)
  );
  const errorMsg = useNotify(message);
  const errorDivStyles =
    'absolute z-50 transform translate-y-1/2 translate-x-1/2 top-0 left-0 flex justify-center place-items-center w-1/2 h-1/6 shadow-xl bg-white border-none rounded';
  const halfContainers =
    'flex flex-col h-full w-1/2 justify-around items-center divide-y divide-gray-200';

  const sendNewCert = (id: string) => {
    dispatch(allActions.certActions.startCertSend(id, false, false));
  };

  const sendCertPreview = (id: string) => {
    dispatch(allActions.certActions.startCertSend(id, true, false));
  };

  const sendMultiPreview = (id: string) => {
    dispatch(allActions.certActions.startCertSend(id, false, true));
  };

  const err =
    errors.missing.length + errors.validations.length > 0 ||
    amountOfReceivers === 0;

  return (
    <div className="h-full w-full bg-white rounded overflow-auto scroller">
      <div className="flex w-full h-full relative divide-x divide-gray-200">
        <div className={halfContainers}>
          <CertErrorsCard
            errors={errors}
            amountOfReceivers={amountOfReceivers}
          />
        </div>
        <div className={halfContainers}>
          <PreviewCard
            header={messages.previewheader}
            paragraph={messages.previewtext}
            buttonText={[messages.previewbutton, messages.multipreviewbutton]}
            onClick={[
              () => sendCertPreview(templateId),
              () => sendMultiPreview(templateId),
            ]}
            disabled={err}
          />
          <PreviewCard
            header={messages.sendheader}
            paragraph={messages.sendtext}
            buttonText={[messages.sendbutton]}
            onClick={[() => sendNewCert(templateId)]}
            disabled={err}
          />
        </div>
        <ErrorFetching message={errorMsg} styles={errorDivStyles} />
      </div>
    </div>
  );
};
