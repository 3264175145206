import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import { messages } from '../translations/newcert';
import 'react-image-crop/dist/ReactCrop.css';
import { InputLoadButton } from './InputLoadButton';
import { InputUploadButton } from './InputUploadButton';
import { highestCommonFactor } from '../../utils/functions';

interface Props {
  width: number;
  height: number;
  maxSize?: number;
  acceptedTypes: string[];
  toggleId: string;
  questionId: string;
  upload: (
    crop: Crop,
    blob: HTMLCanvasElement,
    id: string,
    toggleId: string
  ) => void;
}
export const CropView: React.FC<Props> = ({
  width,
  height,
  toggleId,
  questionId,
  upload,
}) => {
  const [upImg, setUpImg] = useState(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const hcf = highestCommonFactor(width, height);
  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    width: width,
    aspect: width / hcf / (height / hcf),
  });

  const cropImage = (crop: Crop) => {
    setCrop(crop);
  };

  const [completedCrop, setCompletedCrop] = useState(null);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  return (
    <div className="flex flex-col w-5/6 h-2/3 m-auto p-2 pt-4 mt-6">
      <div className="flex w-full justify-center">
        <InputLoadButton onChange={onSelectFile} msg={messages.changeimage} />
        {upImg && (
          <InputUploadButton
            onOk={() =>
              upload(
                previewCanvasRef.current,
                completedCrop,
                questionId,
                toggleId
              )
            }
            msg={messages.useimage}
          />
        )}
      </div>
      <ReactCrop
        src={upImg}
        ruleOfThirds
        onImageLoaded={onLoad}
        crop={crop}
        onChange={(c) => cropImage(c)}
        onComplete={(c) => setCompletedCrop(c)}
      />
      <div className="flex flex-col">
        <div className="flex flex-col hidden">
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                width: Math.round(completedCrop?.width ?? 0),
                height: Math.round(completedCrop?.height ?? 0),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CropView;
