import React from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from '../translations/confirmsend';

type Props = {
  errors: boolean;
};

export const CertSendStatus: React.FC<Props> = ({ errors }) => {
  const text = errors
    ? messages.validationstatuserrors
    : messages.validationstatusok;

  const textColor = errors ? 'text-red-500' : 'text-green-700';

  const emoji = errors ? '🚨' : '✅';

  const lineBreak = {
    lineBreak: <br />,
  };

  return (
    <div>
      <span className="text-xl text-brand-dark_blue py-2">
        4. <FormattedMessage {...messages.validationstatusheader} /> {emoji}
      </span>
      <div className="py-1 pt-4">
        <span className={`mx-4 ${textColor}`}>
          <FormattedMessage {...text} values={lineBreak} />
        </span>
      </div>
    </div>
  );
};
