import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { messages } from '../../translations/questionTranslations';
import {
  HiOutlineTicket,
  HiOutlineTrash,
  HiOutlineEyeOff,
} from 'react-icons/hi';
import { IoIosRepeat } from 'react-icons/io';
import { useForm } from 'react-hook-form';
import FormInput from '../../../../components/form/FormInput';
import FormSelect from '../../../../components/form/FormSelect';
import {
  FormInputInfo,
  DbKeyValues,
  MailKeyValues,
  RequiredType,
} from '../../../../types/types';
import { HoverMessage } from '../../../../components/utils/HoverMessage';
import { overlays } from '../overlayToggles';
import { useDispatch } from 'react-redux';
import allActions from '../../../../actions/allActions';
import { Switch } from '../../../../components/utils/Switch';
import { QuestionOptions } from './QuestionOptions';
import { QuestionModalButtons } from './QuestionModalButtons';
import { QuestionIconButton } from './QuestionIconButton';

interface ModalProps {
  modalAction: (objectI: FormInputInfo, basket: string) => void;
  modalCancel: (id: string) => void;
  modalOpen: (modalId: string) => void;
  modalId: string;
  alertId: string;
  dependencyId: string;
  productId: string;
  hideId: string;
  item: FormInputInfo;
  basket: string;
  dbKeys: DbKeyValues[];
  mailKeys: MailKeyValues[];
  questionIds: string[];
}

export const QuestionModal: React.FC<ModalProps> = ({
  modalAction,
  modalCancel,
  modalOpen,
  modalId,
  alertId,
  dependencyId,
  item,
  basket,
  productId,
  hideId,
  dbKeys,
  questionIds,
  mailKeys,
}) => {
  const containerStyle = 'flex flex-col w-1/3 mb-2';
  const iconRow = 'flex w-full justify-around';
  const switchRow = 'flex justify-center w-full';
  const questions = 'flex justify-center flex-wrap';
  const questionDiv = 'w-1/2';
  const requiredOptions: RequiredType[] = ['', 'email', 'phone', 'any'];
  const databaseKeyOptions = [''].concat(dbKeys.map((a) => a.fieldName));
  const mailKeyOptions = [''].concat(mailKeys.map((a) => a.fieldName));
  const [state, setState] = useState<FormInputInfo>({
    ...item,
    id: item.id ? item.id : '',
    options: item.options ? item.options : [''],
    description: item.description ? item.description! : '',
    required: item.required
      ? item.required!
      : { key: '', type: '' as RequiredType, value: '' },
    link: item.link ? item.link! : '',
    reservation: item.reservation ? item.reservation : false,
    person: item.person ? item.person : false,
    dbKeys: item.dbKeys
      ? item.dbKeys
      : { key: '', entryValue: '', leadKey: false, dbKey: false },
    mailKeys: item.mailKeys ? item.mailKeys : { key: '' },
  });
  const dispatch = useDispatch();
  const [message, setMessage] = useState<string>('');
  const { formatMessage } = useIntl();
  const hideOptions =
    state.type === 'radio' ||
    state.type === 'checkbox' ||
    state.type === 'dropdown' ||
    state.type === 'product'
      ? 'visible'
      : 'invisible';
  const hideLink = state.type === 'upload' ? true : false;
  const { register, trigger, errors } = useForm();

  function validateWithUseForm(id: string) {
    trigger(id);
  }

  const localInputChange = (e: any, item?: string, itemAttribute?: string) => {
    if (itemAttribute) {
      setState({
        ...state,
        [item]: { ...state[item], [itemAttribute]: e.target.value },
      });
    } else if (e.target.id === 'type' && e.target.value === 'accept') {
      setState({
        ...state,
        options: [' '],
        [e.target.id]: e.target.value,
      });
    } else {
      setState({ ...state, [e.target.id]: e.target.value });
    }
  };

  const changeDbKey = (e: any) => {
    const obj = dbKeys.find((dbKey) => dbKey.fieldName === e.target.value);
    if (!obj) {
      setState({
        ...state,
        dbKeys: {
          key: '',
          entryValue: '',
          leadKey: false,
          dbKey: false,
        },
      });
    } else {
      const result = {
        key: obj.internalDbValue,
        ...(obj.entryValue && { entryValue: obj.entryValue }),
        leadKey: obj.leadKey ? obj.leadKey : false,
        dbKey: obj.dbKey ? obj.dbKey : false,
      };
      setState({
        ...state,
        dbKeys: result,
      });
    }
  };

  const changeMailKey = (e: any) => {
    const obj = mailKeys.find(
      (mailKey) => mailKey.fieldName === e.target.value
    );
    if (!obj) {
      setState({
        ...state,
        mailKeys: {
          key: '',
        },
      });
    } else {
      const result = {
        key: obj.internalDbValue,
        groupRequired: obj.groupRequired ? obj.groupRequired : false,
      };
      setState({
        ...state,
        mailKeys: result,
      });
    }
  };

  const arrayInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (state.options) {
      const copy = [...state.options];
      copy[index] = e.target.value;
      setState({ ...state, options: copy });
    }
  };

  const addOptions = () => {
    let newArr: string[] = [];
    if (state.options) {
      newArr = [...state.options, ''];
    } else {
      newArr = [''];
    }
    setState({ ...state, options: newArr });
  };

  const setBoolean = (key: string) => {
    const result = state[key] ? false : true;
    setState({ ...state, [key]: result });
  };

  const removeOption = () => {
    if (state.options) {
      const newArr = [...state.options];
      newArr.pop();
      setState({ ...state, options: newArr });
    }
  };

  const validate = (str: string) => {
    if (state.type === 'product') {
      const splitted = str.split(' ');
      if (
        splitted.length <= 1 ||
        isNaN(Number(splitted[splitted.length - 1].replace(',', '.')))
      ) {
        setMessage(formatMessage({ ...messages.formatproduct }));
        return false;
      }
    }
    setMessage('');
    return true;
  };

  const getDbFieldValue = (key: string, type: string) => {
    if (key === '') {
      return '';
    }
    return type === 'dbKeys'
      ? dbKeys.find((k) => k.internalDbValue === key).fieldName
      : mailKeys.find((k) => k.internalDbValue === key).fieldName;
  };

  function returnRequiredRule(register: any) {
    return register({
      validate: {
        unique: (value: string) =>
          !questionIds.find((qId) => qId === value) || 'uniquequestionid',
        format: (value: string) => !/(_)/.test(value) || 'formatid',
      },
    });
  }

  const toggleHelp = (id: string) => {
    dispatch(allActions.toggleActions.toggle(id));
  };

  return (
    <form
      className="flex flex-col justify-around absolute z-20 text-center border bg-white border-gray-200 rounded w-5/6 shadow-xl py-8 px-16 left-28"
      onSubmit={() => modalAction(state, basket)}
    >
      <span className="text-brand-dark_blue text-4xl text-center pb-8">
        <FormattedMessage {...messages.header} />
      </span>
      <div className={questions}>
        <HoverMessage
          messageHeader={messages.questionhelp}
          message={messages.questionhelpparagraph}
          overlayProps={{
            ...overlays.questionquestion,
            overlayToggle: toggleHelp,
          }}
        >
          <FormInput
            id="question"
            divStyle={questionDiv}
            question={formatMessage({ ...messages.question })}
            value={state.question}
            type="text"
            handleInputChange={localInputChange}
            containerStyle={containerStyle}
            overlayProps={{
              overlayId: overlays.questionquestion.overlayId,
              overlayToggle: toggleHelp,
            }}
          />
        </HoverMessage>
        <HoverMessage
          messageHeader={messages.idhelp}
          message={messages.idhelpparagraph}
          overlayProps={{
            ...overlays.questionid,
            overlayToggle: toggleHelp,
          }}
        >
          <FormInput
            id="id"
            divStyle={questionDiv}
            question={formatMessage({ ...messages.id })}
            value={state.id}
            type="text"
            handleInputChange={localInputChange}
            register={returnRequiredRule(register)}
            validate={validateWithUseForm}
            errors={errors}
            containerStyle={containerStyle}
            overlayProps={{
              overlayId: overlays.questionid.overlayId,
              overlayToggle: toggleHelp,
            }}
          />
        </HoverMessage>
        <HoverMessage
          messageHeader={messages.descriptionhelp}
          message={messages.descriptionhelpparagraph}
          overlayProps={{
            ...overlays.questiondescription,
            overlayToggle: toggleHelp,
          }}
        >
          <FormInput
            id="description"
            divStyle={questionDiv}
            question={formatMessage({ ...messages.description })}
            value={state.description}
            type="text"
            handleInputChange={localInputChange}
            containerStyle={containerStyle}
            overlayProps={{
              overlayId: overlays.questiondescription.overlayId,
              overlayToggle: toggleHelp,
            }}
          />
        </HoverMessage>
        <HoverMessage
          messageHeader={messages.typehelp}
          message={messages.typehelpparagraph}
          overlayProps={{
            ...overlays.questiontype,
            overlayToggle: toggleHelp,
          }}
        >
          <FormSelect
            id="type"
            divStyle={questionDiv}
            options={[
              '',
              'text',
              'number',
              'radio',
              'checkbox',
              'textarea',
              'dropdown',
              'product',
              'accept',
              'link',
              'file',
            ]}
            value={state.type}
            question={formatMessage({ ...messages.type })}
            handleInputChange={localInputChange}
            containerStyle={containerStyle}
            overlayProps={{
              overlayId: overlays.questiontype.overlayId,
              overlayToggle: toggleHelp,
            }}
          />
        </HoverMessage>
        <HoverMessage
          messageHeader={messages.grouphelp}
          message={messages.grouphelpparagraph}
          overlayProps={{
            ...overlays.questiongroup,
            overlayToggle: toggleHelp,
          }}
        >
          <FormInput
            id="group"
            divStyle={questionDiv}
            question={formatMessage({ ...messages.group })}
            type="number"
            value={state.group}
            handleInputChange={localInputChange}
            containerStyle={containerStyle}
            overlayProps={{
              overlayId: overlays.questiongroup.overlayId,
              overlayToggle: toggleHelp,
            }}
          />
        </HoverMessage>
        <HoverMessage
          messageHeader={messages.requiredhelp}
          message={messages.requiredhelpparagraph}
          overlayProps={{
            ...overlays.questionrequired,
            overlayToggle: toggleHelp,
          }}
        >
          <FormSelect
            id="required"
            divStyle={questionDiv}
            question={formatMessage({ ...messages.required })}
            options={requiredOptions}
            value={state.required.type}
            itemAttribute="type"
            handleInputChange={localInputChange}
            containerStyle={containerStyle}
            overlayProps={{
              overlayId: overlays.questionrequired.overlayId,
              overlayToggle: toggleHelp,
            }}
          />
        </HoverMessage>
        <HoverMessage
          messageHeader={messages.dbkeyshelp}
          message={messages.dbkeyshelpparagraph}
          overlayProps={{
            ...overlays.questiondbkey,
            overlayToggle: toggleHelp,
          }}
        >
          <FormSelect
            id="dbKeys"
            divStyle={questionDiv}
            question={formatMessage({ ...messages.dbkeys })}
            options={databaseKeyOptions}
            value={getDbFieldValue(state.dbKeys.key, 'dbKeys')}
            handleInputChange={changeDbKey}
            containerStyle={containerStyle}
            overlayProps={{
              overlayId: overlays.questiondbkey.overlayId,
              overlayToggle: toggleHelp,
            }}
          />
        </HoverMessage>
        <HoverMessage
          messageHeader={messages.mailkeyshelp}
          message={messages.mailkeyshelpparagraph}
          overlayProps={{
            ...overlays.questionmailkey,
            overlayToggle: toggleHelp,
          }}
        >
          <FormSelect
            id="mailKeys"
            divStyle={questionDiv}
            question={formatMessage({ ...messages.mailkeys })}
            options={mailKeyOptions}
            value={getDbFieldValue(state.mailKeys.key, 'mailKeys')}
            handleInputChange={changeMailKey}
            containerStyle={containerStyle}
            overlayProps={{
              overlayId: overlays.questionmailkey.overlayId,
              overlayToggle: toggleHelp,
            }}
          />
        </HoverMessage>
        <HoverMessage
          messageHeader={messages.linkhelp}
          message={messages.linkhelpparagraph}
          overlayProps={{
            ...overlays.questionlink,
            overlayToggle: toggleHelp,
          }}
        >
          <FormInput
            id="link"
            divStyle={questionDiv}
            question={formatMessage({ ...messages.link })}
            type="text"
            value={state.link}
            handleInputChange={localInputChange}
            containerStyle={containerStyle}
            disabled={hideLink}
            overlayProps={{
              overlayId: overlays.questionlink.overlayId,
              overlayToggle: toggleHelp,
            }}
          />
        </HoverMessage>
        <div className={switchRow}>
          <HoverMessage
            messageHeader={messages.personhelp}
            message={messages.personhelpparagraph}
            overlayProps={{
              ...overlays.questionperson,
              overlayToggle: toggleHelp,
            }}
          >
            <Switch
              id="person"
              header={formatMessage({ ...messages.person })}
              switchAction={setBoolean}
              show={state.person}
              overlayProps={{
                overlayId: overlays.questionperson.overlayId,
                overlayToggle: toggleHelp,
              }}
            />
          </HoverMessage>
          <HoverMessage
            messageHeader={messages.reservationhelp}
            message={messages.reservationhelpparagraph}
            overlayProps={{
              ...overlays.questionreservation,
              overlayToggle: toggleHelp,
            }}
          >
            <Switch
              id="reservation"
              header={formatMessage({ ...messages.reservation })}
              switchAction={setBoolean}
              show={state.reservation}
              overlayProps={{
                overlayId: overlays.questionreservation.overlayId,
                overlayToggle: toggleHelp,
              }}
            />
          </HoverMessage>
        </div>
        <QuestionOptions
          options={state.options}
          message={message}
          addOptions={addOptions}
          removeOption={removeOption}
          arrayInputChange={arrayInputChange}
          validate={validate}
          hideOptions={hideOptions}
        />
        <div className={iconRow}>
          <HoverMessage
            messageHeader={messages.repeatablehelp}
            message={messages.repeatablehelpparagraph}
            overlayProps={{
              ...overlays.questionrepeatable,
              overlayToggle: toggleHelp,
            }}
          >
            <QuestionIconButton
              overlayProps={{
                overlayId: overlays.questionrepeatable.overlayId,
                overlayToggle: toggleHelp,
              }}
            >
              <IoIosRepeat size={30} onClick={() => modalOpen(dependencyId)} />
            </QuestionIconButton>
          </HoverMessage>

          <HoverMessage
            messageHeader={messages.producthelp}
            message={messages.producthelpparagraph}
            overlayProps={{
              ...overlays.questionproduct,
              overlayToggle: toggleHelp,
            }}
          >
            <QuestionIconButton
              overlayProps={{
                overlayId: overlays.questionproduct.overlayId,
                overlayToggle: toggleHelp,
              }}
            >
              <HiOutlineTicket size={30} onClick={() => modalOpen(productId)} />
            </QuestionIconButton>
          </HoverMessage>
          <HoverMessage
            messageHeader={messages.hiddenhelp}
            message={messages.hiddenhelpparagraph}
            overlayProps={{
              ...overlays.questionhidden,
              overlayToggle: toggleHelp,
            }}
          >
            <QuestionIconButton
              overlayProps={{
                overlayId: overlays.questionhidden.overlayId,
                overlayToggle: toggleHelp,
              }}
            >
              <HiOutlineEyeOff size={30} onClick={() => modalOpen(hideId)} />
            </QuestionIconButton>
          </HoverMessage>
          <HoverMessage
            messageHeader={messages.deletehelp}
            message={messages.deletehelpparagraph}
            overlayProps={{
              ...overlays.questiondelete,
              overlayToggle: toggleHelp,
            }}
          >
            <QuestionIconButton
              overlayProps={{
                overlayId: overlays.questiondelete.overlayId,
                overlayToggle: toggleHelp,
              }}
            >
              <HiOutlineTrash size={30} onClick={() => modalOpen(alertId)} />
            </QuestionIconButton>
          </HoverMessage>
        </div>
      </div>
      <QuestionModalButtons
        modalAction={modalAction}
        modalCancel={modalCancel}
        message={message}
        item={state}
        modalId={modalId}
        basket={basket}
        errors={errors}
      />
    </form>
  );
};

export default QuestionModal;
