import React from 'react';

interface IconWrapperProps {
  styles: string;
  title?: string;
}

export const IconWrapper: React.FC<IconWrapperProps> = ({
  children,
  styles,
  title,
}) => {
  return (
    <span className={styles} title={title}>
      {children}
    </span>
  );
};
