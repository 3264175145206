import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedMessageProps } from '../../types/types';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  styles?: string;
  onOk: (id: string) => void;
  msg: FormattedMessageProps;
  id: string;
}

export const IconButton: React.FC<Props> = ({
  styles,
  onOk,
  msg,
  id,
  children,
  ...buttonProps
}) => {
  const style = styles
    ? styles
    : 'cursor-pointer flex justify-center bg-brand-white hover:bg-blue-200 text-brand-blue m-4 rounded w-20 h-10 border border-gray-200 shadow-lg';
  return (
    <div className={style}>
      {children}
      <button onClick={() => onOk(id)} {...buttonProps}>
        <FormattedMessage {...msg} />
      </button>
    </div>
  );
};
