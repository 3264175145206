import React from 'react';
import {
  RiInboxArchiveLine,
  RiInboxUnarchiveLine,
  RiDeleteBin5Line,
} from 'react-icons/ri';
import { useIntl } from 'react-intl';
import { DialogProperties, EventListType } from '../views/ListEventsPage';
import { translations } from './headerTranslations';

type IconProps = {
  type: EventListType;
  toggleStatusChangeModal: (properties: DialogProperties) => void;
  id: string;
};
export const ActionIcon: React.FC<IconProps> = ({
  type,
  toggleStatusChangeModal,
  id,
}) => {
  const { formatMessage } = useIntl();

  return type === 'archived' ? (
    <td className="border px-8 py-4 h-24 w-1/12 text-center">
      <span className="flex justify-around">
        <RiInboxUnarchiveLine
          className="hover:text-yellow-500 cursor-pointer"
          size={22}
          onClick={() =>
            toggleStatusChangeModal({ eventId: id, type: 'restore' })
          }
          title={formatMessage({
            id: translations['restore'],
            defaultMessage: 'Restore',
          })}
        />
        <RiDeleteBin5Line
          className="hover:text-yellow-500 cursor-pointer"
          size={22}
          onClick={() =>
            toggleStatusChangeModal({ eventId: id, type: 'delete' })
          }
          title={formatMessage({
            id: translations['delete'],
            defaultMessage: 'Delete',
          })}
        />
      </span>
    </td>
  ) : (
    <td className="hover:text-yellow-500 border px-8 py-4 h-24 w-1/12 cursor-pointer text-center">
      <RiInboxArchiveLine
        size={22}
        onClick={() =>
          toggleStatusChangeModal({ eventId: id, type: 'archive' })
        }
        title={formatMessage({
          id: translations['archive'],
          defaultMessage: 'Archive',
        })}
      />
    </td>
  );
};
