import React from 'react';
import { useSelector } from 'react-redux';

import FormInput from '../../components/form/FormInput';
import FormSelect from '../../components/form/FormSelect';
import { Switch } from '../../components/utils/Switch';
import { AppState } from '../../reducers/combineReducer';
import { selectCustomerData } from '../../reducers/userReducer';

import { WysiwygEmailMapper } from '../../types/types';
import { returnRequiredRule } from '../../utils/functions';

interface Props {
  mapping: WysiwygEmailMapper;
  label: string;
  handleInputChange: (
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => void;
  setIsHardCoded: () => void;
  validate?: (id: string) => void;
  register: any;
  disabled: boolean;
  options: string[];
  errors?: Record<string, Object>;
  switchHeader: string;
}

export const EmailMapper: React.FC<Props> = ({
  mapping,
  label,
  register,
  disabled,
  handleInputChange,
  options,
  setIsHardCoded,
  errors,
  validate,
  switchHeader,
}) => {
  const inputLabelStyle = 'm-2  font-bold text-brand-dark_blue';
  const inputDiv = 'flex flex-col mb-4 w-2/3';
  const switchDiv = 'px-5 m-2 w-1/3';

  const customer = useSelector((state: AppState) => selectCustomerData(state));

  const selectableOptions =
    mapping.type === 'senderEmail'
      ? [''].concat(customer.customerSettings.general.allowedSenderEmails)
      : options;

  return (
    <div className="flex">
      {mapping.isHardCoded ? (
        <FormInput
          divStyle={inputDiv}
          labelStyle={inputLabelStyle}
          value={mapping.value}
          id={mapping.id}
          handleInputChange={(e) => handleInputChange(e, mapping.id)}
          question={label}
          type={'text'}
          register={
            mapping.required ? returnRequiredRule(mapping.type, register) : null
          }
          disabled={disabled}
          errors={errors}
          validate={mapping.required ? validate : null}
        />
      ) : (
        <FormSelect
          divStyle={inputDiv}
          labelStyle={inputLabelStyle}
          value={mapping.value}
          id={mapping.id}
          handleInputChange={(e) => handleInputChange(e, mapping.id)}
          options={selectableOptions}
          question={label}
          register={
            mapping.required ? returnRequiredRule('text', register) : null
          }
          errors={errors}
          validate={mapping.required ? validate : null}
        />
      )}
      <Switch
        divStyle={switchDiv}
        id={mapping.id}
        header={switchHeader}
        show={mapping.isHardCoded}
        switchAction={setIsHardCoded}
        disabled={mapping.isOverWriteDisabled}
      />
    </div>
  );
};
