import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as t from '../translations/confirmSend';
import * as imgs from '../../../img/images';

interface Props {
  confirm: () => void;
}

export const SaveQuestions: React.FC<Props> = ({ confirm }) => {
  return (
    <div className="flex w-full h-full divide-y-2 divide-gray-300 overflow-auto">
      <div className="self-center w-2/3 h-5/6 mx-auto p-4 border shadow-md text-brand-dark_blue">
        <div className="flex flex-col h-full">
          <div className="flex h-3/4">
            <div className="flex flex-col justify-evenly w-1/2">
              <div className="text-3xl">
                <FormattedMessage {...t.messages.header} />
              </div>
              <div>
                <FormattedMessage {...t.messages.paragraph1} />
              </div>
              <div>
                <FormattedMessage {...t.messages.paragraph2} />
              </div>
              <div>
                <FormattedMessage {...t.messages.paragraph3} />
              </div>
            </div>
            <div className="h-full">
              <img
                className="h-full mx-auto"
                src={`/${imgs.SUBEVENT_REGISTRATION_SAVE}`}
                alt={`save_image`}
              />
            </div>
          </div>

          <div className="p-6">
            <button
              className="bg-blue-400 hover:bg-brand-blue font-thin text-brand-white py-2 px-4 rounded m-5"
              onClick={() => confirm()}
            >
              <FormattedMessage {...t.messages.save} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveQuestions;
