import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../actions/allActions';
import BreadCrumb from '../../components/breadcrumbs/BreadCrumb';
import { BreadRoute } from '../../components/breadcrumbs/BreadRoute';
import { AppState } from '../../reducers/combineReducer';
import { selectInvoicesTableData } from '../../reducers/invoiceReducer';
import InvoicesTable from '../allInvoicesTable/InvoicesTable';
import * as uState from '../../reducers/userReducer';
import storeService from '../../services/storage';
import { selectLoading } from '../../reducers/loadingReducer';

export const AllInvoices: React.FC = () => {
  const dispatch = useDispatch();
  const { userId } = useSelector(uState.selectUserData);

  const { data, headerRow } = useSelector((state: AppState) =>
    selectInvoicesTableData(state)
  );

  const isLoading = useSelector((state: AppState) =>
    selectLoading(state, 'getFennoaInvoices')
  );

  const tableName = `invoices_${userId}`;
  const savedHeaders = storeService.getHeaders(tableName);
  const headers = savedHeaders.length > 0 ? savedHeaders : headerRow;

  const getAllFennoaInvoices = useCallback(() => {
    dispatch(allActions.invoiceActions.startGetFennoaInvoices());
  }, [dispatch]);

  useEffect(() => {
    getAllFennoaInvoices();
  }, [getAllFennoaInvoices]);

  return (
    <div className="w-11/12 h-full mx-auto pr-4">
      <BreadRoute>
        <BreadCrumb label={`invoice-menu`} link={`/invoicingmenu`} />
        <BreadCrumb label={`invoices`} link={`/invoices`} lastChild={true} />
      </BreadRoute>
      <div className="flex h-full min-w-11/12 max-h-11/12 min-h-11/12 mx-auto py-5 my-5">
        <InvoicesTable
          loading={isLoading}
          list={data}
          headerRow={headers}
          tableName={tableName}
        />
      </div>
    </div>
  );
};

export default AllInvoices;
