import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IntlMessageID } from '../';

interface Props {
  data: string;
  header: IntlMessageID;
}

export const Item: React.FC<Props> = ({ data, header }) => {
  return (
    <div className="flex justify-between w-full text-brand-dark_blue bg-blue-200 rounded-lg m-2 p-4">
      <span className="font-semibold">
        <FormattedMessage id={header} defaultMessage="Header" />
      </span>
      <span>{data}</span>
    </div>
  );
};
