import React from 'react';

type Props = { message?: string };

export const MessageDiv: React.FC<Props> = ({ message }) => {
  return message !== '' ? (
    <div className="text-red-400 h-2 pb-4">{message}</div>
  ) : (
    <div className="invisible h-2 pb-2">{message}</div>
  );
};
