import React from 'react';
import BreadCrumb from '../../components/breadcrumbs/BreadCrumb';
import { BreadRoute } from '../../components/breadcrumbs/BreadRoute';
import InvoicingPage from '../components/InvoicingPage';

export const CreateInvoice: React.FC = () => {
  return (
    <div className="w-11/12 h-full mx-auto pr-4">
      <BreadRoute>
        <BreadCrumb label={`invoice-menu`} link={`/invoicingmenu`} />
        <BreadCrumb label={`create`} link={`/createinvoice`} lastChild={true} />
      </BreadRoute>
      <InvoicingPage />
    </div>
  );
};

export default CreateInvoice;
