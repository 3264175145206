import React from 'react';
import { FormInputInfo } from '../../../types/types';

interface Props {
  styles: string;
  item: FormInputInfo;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    array: boolean
  ) => void;
  value: string | string[];
}

export const ParticipantFormInput: React.FC<Props> = ({
  styles,
  value,
  item,
  handleInputChange,
}) => {
  const style = styles
    ? styles
    : 'box-border py-4 pl-6 h-full w-full truncate relative';

  const isChecked = (option: string, checked: string[] | string) => {
    const toCheck = typeof checked === 'string' ? [checked] : checked;
    return (
      toCheck.find((checkedOption) => checkedOption === option) !== undefined
    );
  };

  const options =
    item.type === 'accept' ? ['YES'] : item.options ? item.options : [];

  const multiSelectable = item.type === 'checkbox' || item.type === 'accept';
  const radioSelectable = item.type === 'radio' || item.type === 'product';

  return multiSelectable ? (
    <fieldset className="flex justify-around">
      {options.map((option, index) => (
        <div key={index} className="flex flex-col">
          <label>{option}</label>
          <input
            className={'truncate relative'}
            checked={isChecked(option, value)}
            value={option}
            id={item.id}
            type="checkbox"
            onChange={(e) => handleInputChange(e, true)}
          />
        </div>
      ))}
    </fieldset>
  ) : radioSelectable ? (
    <div className="flex justify-around">
      {options.map((option) => (
        <label key={`${item.id}:${option}`}>
          <input
            value={option ?? ''}
            className="m-2"
            type="radio"
            id={item.id}
            onChange={(e) => handleInputChange(e, false)}
            checked={isChecked(option, value)}
          />
          {option}
        </label>
      ))}
    </div>
  ) : (
    <input
      className={style}
      value={value || ''}
      id={item.id}
      onChange={(e) => handleInputChange(e, false)}
    />
  );
};

export default ParticipantFormInput;
