import React from 'react';
import { useSelector } from 'react-redux';
import * as tState from '../../reducers/toggleReducer';

interface ToggleProps {
  id: string;
  reverse?: boolean;
}

export const Toggle: React.FC<ToggleProps> = ({ id, children, reverse }) => {
  const show = useSelector(tState.getToggleState);

  const checked = reverse ? !show[id] : show[id];

  return checked ? <React.Fragment>{children}</React.Fragment> : null;
};
