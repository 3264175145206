import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ErrorFetching from '../../../../components/modals/ErrorFetching';
import Pagination from '../../../../components/utils/Pagination';
import SearchBar from '../../../../components/tableSearchBar/SearchBar';
import { SubEventObject } from '../../../../types/types';
import {
  getUniqueKeysForTable,
  mapFromKeys,
} from '../../../../utils/functions';
import * as erState from '../../../../reducers/errorReducer';
import { useNotify } from '../../../../hooks/useNotify';
import { SubEventTableHeader } from './SubEventHeader';
import { SubEventTableRow } from './SubEventTableRow';
import Loading from '../../../../components/utils/Loading';
import * as h from './headerTranslations';
import * as t from '../../../../components/tableSearchBar/translations';
import { useTable } from '../../../../hooks/useTable';
import { BackDrop } from '../../../../components/tableSearchBar/BackDrop';
import { messages } from '../../../../components/tableSearchBar/translations';
import { useIntl } from 'react-intl';

interface Props {
  tableList: SubEventObject[];
  headerRow: string[];
  open: (id: string) => void;
  createSubEvent: () => void;
  loading: boolean;
  tableName: string;
  newSubEventModalToggleId: string;
  subEventDeleteModal: string;
  toggleDeleteModal: (subEventId: string) => void;
}

export const SubEventTable: React.FC<Props> = ({
  tableList,
  open,
  headerRow,
  createSubEvent,
  loading,
  tableName,
  newSubEventModalToggleId,
  subEventDeleteModal,
  toggleDeleteModal,
}) => {
  const { message } = useSelector(erState.getErrorMessage);
  const errorMsg = useNotify(message);
  const { formatMessage } = useIntl();
  const filterDivToggleId = 'SUBEVENTS_TABLE_FILTER';
  const tableSettingsDivId = 'SUBEVENTS_TABLE_SETTINGS';
  const exportSettingsDivId = 'SUBEVENTS_TABLE_EXPORT_SETTINGS';
  const pageLimit = 11;
  const initialState = {
    tableName: tableName,
    tableList: tableList,
    incomingHeaders: headerRow,
    currentPage: 1,
    totalPages: Math.ceil(tableList.length / pageLimit),
    pageLimit: pageLimit,
    totalRecords: tableList.length,
    pageNeighbours: 0,
    filteredList: tableList,
    currentList: tableList.slice(0, pageLimit),
    filterKeys: [],
    searchWord: '',
    sortKey: headerRow[0],
    ascending: false,
    possibleSearchKeys: getUniqueKeysForTable(tableList),
    currentHeaders: headerRow,
    headers: {
      droppableColumnsSelected: headerRow,
      droppableColumnsAll: getUniqueKeysForTable(tableList).filter(
        (item) => !headerRow.includes(item)
      ),
    },
    export: {
      exportAll: {
        data: mapFromKeys(tableList, getUniqueKeysForTable(tableList)),
        filename: 'subevents_all',
        label: formatMessage({ ...t.messages.exportall }),
      },
      exportView: {
        data: mapFromKeys(tableList, headerRow),
        filename: 'subevents_view',
        label: formatMessage({ ...t.messages.exportview }),
      },
    },
  };

  const table = useTable(initialState);
  const currentList = table.state.currentList as SubEventObject[];

  const updateTableData = useCallback(() => {
    table.changeHeadersAndTable(tableList, headerRow);
  }, [tableList, headerRow]); //eslint-disable-line

  useEffect(() => {
    updateTableData();
  }, [updateTableData]);

  return (
    <>
      <BackDrop
        modalIds={[
          filterDivToggleId,
          tableSettingsDivId,
          exportSettingsDivId,
          newSubEventModalToggleId,
          subEventDeleteModal,
        ]}
      />
      <div className="flex flex-col shadow-lg bg-brand-white md:shadow-lg my-5 md:w-full min-h-11/12 h-11/12 overflow-y-auto overflow-x-hidden scroller">
        <ErrorFetching message={errorMsg} />
        <SearchBar
          searchWord={table.state.searchWord}
          exportSettings={table.state.export}
          sortKeys={table.state.possibleSearchKeys}
          onDragEnd={table.onDragEnd}
          selectedColumns={table.state.headers.droppableColumnsSelected}
          restColumns={table.state.headers.droppableColumnsAll}
          filterDivToggleId={filterDivToggleId}
          tableSettingsDivToggleId={tableSettingsDivId}
          exportSettingsDivToggleId={exportSettingsDivId}
          activeFilters={table.state.filterKeys}
          headerTranslations={h.translations}
          toggleFilter={table.toggleFilter}
          clearFilters={table.clearFilters}
          onSearchWordChange={table.onSearchWordChange}
          optionalAction={{
            action: createSubEvent,
            message: messages.newsubevent,
          }}
        />
        {loading ? (
          <Loading styles="my-auto" />
        ) : (
          <table className="md:bg-white rounded-lg md:table-fixed h-full w-full">
            <SubEventTableHeader
              headers={table.state.headers.droppableColumnsSelected}
              sort={table.sort}
              hasTableItems={table.state.tableList.length > 0}
            />
            <tbody>
              {currentList.map((s, i) => (
                <SubEventTableRow
                  subEvent={s}
                  key={i}
                  open={open}
                  headerKeys={table.state.headers.droppableColumnsSelected}
                  toggleDeleteModal={toggleDeleteModal}
                />
              ))}
            </tbody>
          </table>
        )}
        <Pagination
          pageLimit={table.state.pageLimit}
          totalPages={table.state.totalPages}
          pageNeighbours={table.state.pageNeighbours}
          currentPage={table.state.currentPage}
          onPageChanged={table.gotoPage}
        />
      </div>
    </>
  );
};

export default SubEventTable;
