import React from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../actions/allActions';
import { AppState } from '../../reducers/combineReducer';
import { getSelectedParticipants } from '../../reducers/invoiceReducer';

interface Props {
  headers: string[];
  sort: (header: string) => void;
  currentView: { [key: string]: string }[];
  hasTableReceivers: boolean;
}

export const InvoiceReceiverTableHeader: React.FC<Props> = ({
  headers,
  sort,
  currentView,
  hasTableReceivers,
}) => {
  const styles =
    'bg-blue-100 text-left text-brand-dark_blue px-8 py-4 h-12 cursor-pointer hover:text-brand-blue truncate';
  const selected = useSelector((state: AppState) =>
    getSelectedParticipants(state)
  );
  const dispatch = useDispatch();
  const isSelected = selected.length > 0;
  const uids = currentView.map((p) => p.uuid);

  const toggleAll = () => {
    dispatch(allActions.invoiceActions.toggleAllRecipients(uids));
  };

  return (
    <thead>
      <tr className="flex flex-col flex-no wrap md:table-row rounded-l-lg md:rounded-none mb-2 md:mb-0">
        {hasTableReceivers && (
          <th className={`bg-blue-100`}>
            <label
              htmlFor={`selectAll`}
              className={`relative cursor-pointer select-none multiSelect`}
            >
              <input
                className="cursor-pointer"
                type="checkbox"
                id="selectAll"
                checked={isSelected}
                onChange={toggleAll}
              />
              <span
                className={`absolute -bottom-2 -left-6 h-4 w-4 ${
                  isSelected ? 'bg-blue-400' : 'bg-blue-50 checkmark'
                }`}
              >
                {isSelected ? (
                  <AiOutlineCheck style={{ color: '#00255f' }} />
                ) : null}
              </span>
            </label>
          </th>
        )}
        {headers.map((header) => (
          <th key={header} className={styles} onClick={() => sort(header)}>
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default InvoiceReceiverTableHeader;
