import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedMessageProps } from '../../../types/types';

type Props = {
  message: FormattedMessageProps;
  itemId?: string;
  alertId: string;
  alertAction: (itemId?: string) => void;
  alertCancel: (alertId?: string) => void;
};

export const SubEventDeleteDialog: React.FC<Props> = ({
  message,
  alertId,
  itemId,
  alertAction,
  alertCancel,
}) => {
  const positionStyles =
    'absolute top-1/4 right-1/3 px-20 py-10 z-10 text-center rounded shadow-xl';

  const dialogColors = 'bg-red-400 text-brand-white';

  const confirmButtonStyle =
    'bg-red-600 hover:bg-red-800 text-brand-white m-4 rounded w-20 h-10 shadow-lg';

  const textStyles = 'text-brand-white font-thin';

  const cancelButtonStyle =
    'bg-yellow-400 hover:bg-yellow-600 text-gray-white font-thin m-4 rounded w-20 h-10 shadow-lg';

  const lineBreak = { lineBreak: <br /> };

  return (
    <div className={`${positionStyles} ${dialogColors}`}>
      <div className="flex flex-col">
        <div className="p-4 text-center">
          <span className={textStyles}>
            <FormattedMessage {...message} values={lineBreak} />
          </span>
        </div>
        <div className="flex justify-around">
          <div className="pb-6">
            <button
              className={confirmButtonStyle}
              onClick={() => alertAction(itemId)}
            >
              <FormattedMessage
                id="registration.subevent.dialog.confirm.button"
                defaultMessage="OK"
              />
            </button>
          </div>
          <div className="pb-6">
            <button
              className={cancelButtonStyle}
              onClick={() => alertCancel(alertId)}
            >
              <FormattedMessage
                id="registration.subevent.dialog.cancel.button"
                defaultMessage="Cancel"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
