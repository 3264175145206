import React from 'react';

type Props = { message?: string; divStyle?: string; messageStyle?: string };
export const StyledMessage: React.FC<Props> = ({
  message,
  divStyle,
  messageStyle,
}) => {
  const div =
    divStyle ??
    'text-center text-brand-dark_blue lg:m-4 font-semibold text-3xl';
  const style = messageStyle ?? 'text-brand-dark_blue';
  return message ? (
    <div className={div}>
      <span className={style}>{message}</span>
    </div>
  ) : null;
};
