import { LoginObject, LoginResponse, LoginState } from '../types/types';
import { LoopReducer, loop, Loop, Cmd } from 'redux-loop';
import loginService from '../services/login';
import {
  getType,
  createAsyncAction,
  ActionType,
  createAction,
} from 'typesafe-actions';
import storeService from '../services/storage';
import { AppState } from '../reducers/combineReducer';

export const initialState: LoginState = {
  initLoading: false,
  loggedIn: false,
  formFetched: false,
  message: null,
  jwt: '',
};
export const loginAsync = createAsyncAction(
  'START_LOGIN',
  'LOGIN_COMPLETED',
  'LOGIN_FAIL'
)<LoginObject, { data: LoginResponse; status: number }, Error>();

export const logout = createAction('LOGOUT')<undefined>();
const loggedIn = createAction('LOGGEDIN')();

type Action =
  | ActionType<typeof loginAsync>
  | ActionType<typeof logout>
  | ActionType<typeof loggedIn>;

export const loginReducer: LoopReducer<LoginState, Action> = (
  state: LoginState = initialState,
  action: Action
): LoginState | Loop<LoginState> => {
  switch (action.type) {
    //login
    case getType(loginAsync.request):
      return loop(
        { ...state, initLoading: true },
        Cmd.run(loginService.login, {
          successActionCreator: loginAsync.success,
          failActionCreator: loginAsync.failure,
          args: [action.payload],
        })
      );

    case getType(loginAsync.success):
      if (action.payload.status === 200) {
        return loop(
          {
            ...state,
            loggedIn: true,
            initLoading: false,
          },
          Cmd.list([
            Cmd.run(storeService.storeString, {
              successActionCreator: loggedIn,
              args: ['jwt', action.payload.data.jwt],
            }),
            Cmd.run(storeService.storeString, {
              args: ['re', action.payload.data.re],
            }),
          ])
        );
      }
      return {
        ...state,
        loggedIn: false,
        initLoading: false,
      };

    case getType(loginAsync.failure):
      return {
        ...state,
        loggedIn: false,
        initLoading: false,
      };

    //logout
    case getType(logout):
      return {
        ...state,
        loggedIn: false,
      };

    default: {
      return state;
    }
  }
};

export const selectLoginState = (state: AppState) => state.loginState;
