import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ValidationError } from '../../eventReducer';

interface Props {
  header: { id: string; defaultMessage: string; values?: any };
  errors: ValidationError[];
}

export const ErrorList: React.FC<Props> = ({ header, errors }) => {
  return (
    <div>
      <span className="semibold font-sm  text-lg text-brand-dark_blue ml-1">
        <FormattedMessage {...header} />
      </span>
      <ul>
        {errors.map((e) => (
          <li key={e.error} className="text-red-400 text-lg ml-2">
            {e.error}
            <ul>
              {e.additionalMessages.map((msg, i) => (
                <li
                  key={`${e.error}_${i}_additionalMessage`}
                  className="italic font-thin ml-6"
                >
                  <FormattedMessage
                    id={msg.message}
                    values={{ ...msg.variables }}
                    defaultMessage={msg.defaultMessage}
                  />
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};
