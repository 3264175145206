import React, { useEffect, useRef, useState } from 'react';
import { FormInputInfo, ButtonInfo } from '../../types/types';
import allActions from '../../actions/allActions';
import { useDispatch, useSelector } from 'react-redux';
import { DeepMap, FieldError, useForm } from 'react-hook-form';
import ConsoleHelper from '../../utils/logger';
import { selectFormItemsAndValues } from '../../reducers/formReducer';
import { AppState } from '../../reducers/combineReducer';
import { getProductSummary } from '../../utils/formFunctions';
import { FormElement } from './FormElementSelector';
import { FormNavigation } from './FormNavigation';
import { StyledMessage } from './StyledMessage';
import { SummaryDiv } from './SummaryDiv';
import { MessageDiv } from './MessageDiv';

interface FormProps {
  formAction?: (e: any) => void;
  title?: string;
  description?: string;
  formStyle: string;
  buttonInfo: ButtonInfo;
  message?: string;
  formName: string;
}

export const FormContainer: React.FC<FormProps> = ({
  title,
  description,
  formStyle,
  buttonInfo,
  formAction,
  message,
  formName,
}) => {
  const { register, handleSubmit, trigger, errors, unregister } = useForm();
  const dispatch = useDispatch();
  const { items, values, repeatableLength, maxPage, pages } = useSelector(
    (state: AppState) => selectFormItemsAndValues(state, formName)
  );
  const [currentPage, setCurrentPage] = useState(pages[0]);
  const [registered, setRegistered] = useState([]);
  const pageList = items.filter((item) => item.page === currentPage);
  let prevGroup = pageList.length > 0 ? pageList[0].group : 0;
  const formRef = useRef(null);
  const summary = getProductSummary(items, values);

  //TODO: 1. Pitää olla myös erilainen ehto toistoryhmille. Lisää ja vähennä nappi halutaan myös ns. ensimmäisten toistoryhmien alle.
  //Jos painaa Add, niin täytyy muuttaa myös Key valuen mukaiseksi!
  //TODO: 2. Jos viimeinen toistoryhmän kysymys on piilotettu, niin 'lisää' j poista nappula ovat piilossa.

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    id: string,
    type?: string
  ) => {
    if (type === 'array') {
      const currentValue: string[] | string = values[id];
      const newArray =
        currentValue === undefined
          ? []
          : typeof currentValue === 'string'
          ? [currentValue]
          : currentValue;
      dispatch(
        allActions.formActions.addToValue(
          id,
          newArray,
          event.target.value,
          formName
        )
      );
    } else {
      dispatch(
        allActions.formActions.updateValue(id, event.target.value, formName)
      );
    }
  };

  const sortByGroup = (arr: FormInputInfo[]) => {
    return arr.sort((a, b) => a.group - b.group);
  };

  const setPage = async (e: React.FormEvent, page: number) => {
    e.preventDefault();
    const valid = page < currentPage ? true : await trigger();
    if (valid) {
      setCurrentPage(page);
    }
    ConsoleHelper('non valid');
  };

  const changeAmount = (key: string, direction: number) => {
    const currentValue = values[key];
    const newAmount =
      typeof currentValue === 'string' ? Number(currentValue) + direction : 0;
    dispatch(
      allActions.formActions.updateValue(key, newAmount.toString(), formName)
    );
  };

  function unRegisterFiltered(
    current: FormInputInfo[],
    previous: FormInputInfo[],
    formName: string
  ) {
    if (JSON.stringify(previous) !== JSON.stringify(current)) {
      const previousOnlyIds = previous.map((p) => p.id);
      const currentOnlyIds = current.map((c) => c.id);
      const idsToUnregister = previousOnlyIds.filter(
        (item) => !currentOnlyIds.includes(item)
      );
      for (const id of idsToUnregister) {
        unregister(id);
        dispatch(allActions.formActions.removeKey(id, formName));
      }
    }
    setRegistered(items);
  }

  function validate(id: string) {
    trigger(id);
  }

  function focusOnErrors(errors: DeepMap<Record<string, any>, FieldError>) {
    for (const key of Object.keys(errors)) {
      return errors[key].ref.focus();
    }
  }

  function focusToTop() {
    formRef.current.scrollIntoView();
  }

  useEffect(() => {
    unRegisterFiltered(items, registered, formName);
  }, [repeatableLength]); //eslint-disable-line

  useEffect(() => {
    focusOnErrors(errors);
  }, [errors]);

  useEffect(() => {
    focusToTop();
  }, [currentPage]);

  return (
    <form
      className={`${formStyle}`}
      onSubmit={handleSubmit(formAction)}
      ref={formRef}
    >
      <StyledMessage message={title} />
      <StyledMessage
        message={description}
        divStyle={
          'text-center text-brand-dark_blue lg:m-4 m-2 italic text-lg xl:pb-5'
        }
        messageStyle={'text-brand-dark_blue'}
      />

      {sortByGroup(pageList).map((inputObject, index) => {
        const divider = Number(inputObject.group) !== Number(prevGroup);
        prevGroup = inputObject.group;
        return (
          <FormElement
            key={inputObject.id}
            obj={inputObject}
            register={register}
            errors={errors}
            handlerFunction={handleInputChange}
            index={index}
            divider={divider}
            changeAmount={changeAmount}
            values={values}
            validate={validate}
          />
        );
      })}

      <MessageDiv message={message} />
      <SummaryDiv
        summary={summary}
        maxPage={maxPage}
        currentPage={currentPage}
      />

      <FormNavigation
        currentPage={currentPage}
        maxPage={maxPage}
        pages={pages}
        changePage={setPage}
        buttonInfo={buttonInfo}
      />
    </form>
  );
};

export default FormContainer;
