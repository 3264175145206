import React from 'react';
import { useSelector } from 'react-redux';
import { Toggle } from '../components/utils/Toggle';
import { AppState } from '../reducers/combineReducer';
import { NavHeader } from './NavHeader';
import { NavIcon } from './NavIcon';
import { navigationGroups, NavigationGroupTable } from './navigationGroups';
import { messages } from './navigationTranslations';
import { NavItem } from './NavItem';

type Props = {
  toggleElement: (id: string) => void;
  group: keyof NavigationGroupTable;
};

export const NavGroup: React.FC<Props> = ({ group, toggleElement }) => {
  const { toggle, header, links } = navigationGroups[group];
  const iconNavClass = 'text-white hover:text-blue-300 m-1';
  const toggleState = useSelector((state: AppState) => state.toggleState);
  return (
    <>
      <NavHeader
        onClick={() => toggleElement(toggle)}
        message={messages[header]}
        expanded={toggleState[toggle]}
      />
      <Toggle id={toggle}>
        {links.map((link) => (
          <NavItem
            key={`group_${link.link}`}
            link={link.link}
            message={messages[link.message]}
          >
            <NavIcon name={link.icon} size={20} styles={iconNavClass} />
          </NavItem>
        ))}
      </Toggle>
    </>
  );
};
