import React from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from '../translations';
import { InvoiceValues } from './InvoicePreviewItem';
import { InvoicePreviewRowItem } from './InvoicePreviewRowItem';

type Props = {
  data: InvoiceValues;
};
export const InvoicePreviewRows: React.FC<Props> = ({ data }) => {
  const filtered = Object.keys(data.row).filter((k) => !k.startsWith('dim_'));
  return (
    <>
      {filtered.map((row) => (
        <div key={`invoiceitemrow_${row}`} className="truncate">
          <div className="pt-4 divide-y">
            <span className="text-2xl text-brand-dark_blue">
              <FormattedMessage {...messages.prevrow} values={{ row: row }} />
            </span>
            <div>
              <InvoicePreviewRowItem rowData={data.row[row]} />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
