import { Cmd, Loop, loop, LoopReducer } from 'redux-loop';
import {
  ActionType,
  createAction,
  createAsyncAction,
  getType,
} from 'typesafe-actions';
import { getParticipantDetails } from '../events/eventReducer';
import eventService from '../services/events';
import { historyService } from '../services/historyService';
import {
  FormInputInfo,
  ParticipantState,
  EventParticipant,
  CreateParticipantNoLink,
  CreateParticipantRequestWithLink,
  CreateParticipantResponse,
  CancelParticipantResponse,
  DeleteParticipantResponse,
  MoveParticipantsResponse,
  TableParticipant,
} from '../types/types';
import {
  convertParticipantToListFormat,
  convertParticipantToPartypants,
} from '../utils/functions';
import { AppState } from './combineReducer';

const initialState: ParticipantState = {
  subEventId: null,
  participantUuid: null,
  isPartOfGroup: false,
  groupCommonAnswer: [],
  groupMemberUuids: [],
  participants: [],
  participantsFormatted: [],
};

export const newParticipantAsync = createAsyncAction(
  'START_NEW_PARTICIPANT_REQUEST',
  'NEW_PARTICIPANT_REQUEST_COMPLETE',
  'NEW_PARTICIPANT_REQUEST_FAIL'
)<
  {
    subEventId: string;
    eventId: string;
    items: FormInputInfo[];
    values: { [key: string]: string | string[] };
    send: boolean;
    link?: string;
  },
  { data: CreateParticipantResponse; status: number; eventId: string },
  Error
>();

export const getParticipants = createAsyncAction(
  'GET_EVENT_PARTICIPANTS',
  'GET_PARTICIPANTS_FETCH_COMPLETE',
  'GET_PARTICIPANT_FETCH_FAIL'
)<
  { id: string; name: string | null },
  { data: EventParticipant[]; status: number },
  Error
>();

export const saveParticipantChanges = createAsyncAction(
  'START_PARTICIPANT_PATCH_REQUEST',
  'PARTICIPANT_PATCH_COMPLETE',
  'PARTICIPANT_PATCH_FAIL'
)<
  { uuid: string; eventId: string },
  { data: any; status: number; eventId: string },
  Error
>();

export const cancelParticipantAsync = createAsyncAction(
  'START_CANCEL_PARTICIPANT_REQUEST',
  'CANCEL_PARTICIPANT_COMPLETE',
  'CANCEL_PARTICIPANT_FAIL'
)<
  { eventId: string; subEventId: string; uuid: string },
  { data: CancelParticipantResponse; status: number; eventId: string },
  Error
>();

export const deleteParticipantAsync = createAsyncAction(
  'START_DELETE_PARTICIPANT_REQUEST',
  'DELETE_PARTICIPANT_COMPLETE',
  'DELETE_PARTICIPANT_FAIL'
)<
  { eventId: string; subEventId: string; uuid: string },
  { data: DeleteParticipantResponse; status: number; eventId: string },
  Error
>();

export const moveParticipantAsync = createAsyncAction(
  'START_PARTICIPANT_MOVE_REQUEST',
  'PARTICIPANT_MOVE_COMPLETE',
  'PARTICIPANT_MOVE_FAIL'
)<
  {
    eventId: string;
    from: string;
    to: string;
    uuid: string;
    moveGroup: boolean;
  },
  { data: MoveParticipantsResponse; status: number; eventId: string },
  Error
>();

const changeParticipantsData = createAction('CHANGE_PARTICIPANTS_DATA')<{
  id: string;
  value: string;
  array: boolean;
}>();

type Action =
  | ActionType<typeof getParticipantDetails>
  | ActionType<typeof getParticipants>
  | ActionType<typeof saveParticipantChanges>
  | ActionType<typeof changeParticipantsData>
  | ActionType<typeof newParticipantAsync>
  | ActionType<typeof cancelParticipantAsync>
  | ActionType<typeof deleteParticipantAsync>
  | ActionType<typeof moveParticipantAsync>;

export const participantReducer: LoopReducer<ParticipantState, Action> = (
  state: ParticipantState = initialState,
  action: Action
): ParticipantState | Loop<ParticipantState> => {
  switch (action.type) {
    case getType(getParticipantDetails.success):
      return loop(
        {
          ...state,
          subEventId: action.payload.data.subEventId,
          participantUuid: action.payload.data.participantUuid,
          isPartOfGroup: action.payload.data.isPartOfGroup,
          groupCommonAnswer: action.payload.data.groupCommonAnswer,
          groupMemberUuids: action.payload.data.groupMemberUuids,
        },
        Cmd.run(historyService.goto, {
          args: [
            `/events/${action.payload.eventId}/participants/${action.payload.data.participantUuid}`,
          ],
        })
      );

    case getType(getParticipantDetails.failure):
      return {
        ...initialState,
        participants: state.participants,
        participantsFormatted: state.participantsFormatted,
      };

    case getType(getParticipants.request):
      return loop(
        state,
        Cmd.run(eventService.getParticipants, {
          successActionCreator: getParticipants.success,
          failActionCreator: getParticipants.failure,
          args: [action.payload.id],
        })
      );

    case getType(getParticipants.failure):
      return {
        ...state,
        participants: initialState.participants,
        participantsFormatted: initialState.participantsFormatted,
      };

    case getType(getParticipants.success):
      const participants = action.payload.data;
      const participantsFormatted = participants.length
        ? participants.map((participant) => {
            return convertParticipantToListFormat(participant);
          })
        : [];
      return {
        ...state,
        participants,
        participantsFormatted,
      };

    case getType(saveParticipantChanges.request):
      const updated = state.participants.find(
        (p) => p.partypantsUuid === action.payload.uuid
      );

      const {
        modifyToken,
        cancelToken,
        partypantsQrCode,
        partypantsRegisterTime,
        ...rest
      } = updated;
      return loop(
        state,
        Cmd.run(eventService.saveParticipantChanges, {
          successActionCreator: saveParticipantChanges.success,
          failActionCreator: saveParticipantChanges.failure,
          args: [rest, action.payload.eventId],
        })
      );

    case getType(saveParticipantChanges.success):
      return loop(
        state,
        Cmd.list([
          Cmd.action({
            type: 'GET_EVENT_PARTICIPANTS',
            payload: { id: action.payload.eventId },
          }),
          Cmd.action({
            type: 'START_EVENTS_FETCH',
            payload: { date: '0', limit: '100', type: 'unarchived' },
          }),
        ])
      );

    case getType(newParticipantAsync.request):
      const input = action.payload.values;
      const answers = action.payload.items
        .map((item) => {
          const answer =
            item.type === 'accept' && input[item.id] ? 'YES' : input[item.id];
          return answer
            ? {
                id: item.id,
                question: item.question,
                answer,
                ...(item.person && { person: true }),
                ...(item.dbKeys && { dbKeys: item.dbKeys }),
                ...(item.mailKeys && { mailKeys: item.mailKeys }),
              }
            : null;
        })
        .filter((a) => a);

      const data: CreateParticipantNoLink | CreateParticipantRequestWithLink = {
        subEventId: action.payload.subEventId,
        ...(action.payload.send && { linkUri: action.payload.link }),
        modifyType: 'single',
        sendMail: action.payload.send,
        answers: { '0': answers },
      };
      return loop(
        state,
        Cmd.run(eventService.createParticipant, {
          successActionCreator: newParticipantAsync.success,
          failActionCreator: newParticipantAsync.failure,
          args: [data, action.payload.eventId],
        })
      );

    case getType(newParticipantAsync.success):
      return loop(
        state,
        Cmd.list([
          Cmd.action({
            type: 'GET_EVENT_PARTICIPANTS',
            payload: { id: action.payload.eventId },
          }),
          Cmd.run(historyService.goto, {
            args: [`/events/${action.payload.eventId}/participants`],
          }),
        ])
      );

    case getType(cancelParticipantAsync.request):
      return loop(
        state,
        Cmd.run(eventService.cancelParticipant, {
          successActionCreator: cancelParticipantAsync.success,
          failActionCreator: cancelParticipantAsync.failure,
          args: [
            action.payload.eventId,
            action.payload.subEventId,
            action.payload.uuid,
          ],
        })
      );

    case getType(cancelParticipantAsync.success):
      return loop(
        state,
        Cmd.list([
          Cmd.action({
            type: 'GET_EVENT_PARTICIPANTS',
            payload: { id: action.payload.eventId },
          }),
          Cmd.run(historyService.goto, {
            args: [`/events/${action.payload.eventId}/participants`],
          }),
        ])
      );

    case getType(deleteParticipantAsync.request):
      return loop(
        state,
        Cmd.run(eventService.deleteParticipant, {
          successActionCreator: deleteParticipantAsync.success,
          failActionCreator: deleteParticipantAsync.failure,
          args: [
            action.payload.eventId,
            action.payload.subEventId,
            action.payload.uuid,
          ],
        })
      );

    case getType(deleteParticipantAsync.success):
      return loop(
        state,
        Cmd.list([
          Cmd.action({
            type: 'GET_EVENT_PARTICIPANTS',
            payload: { id: action.payload.eventId },
          }),
          Cmd.run(historyService.goto, {
            args: [`/events/${action.payload.eventId}/participants`],
          }),
        ])
      );

    case getType(moveParticipantAsync.request):
      return loop(
        state,
        Cmd.run(eventService.moveParticipant, {
          successActionCreator: moveParticipantAsync.success,
          failActionCreator: moveParticipantAsync.failure,
          args: [
            action.payload.eventId,
            action.payload.from,
            action.payload.to,
            action.payload.uuid,
            action.payload.moveGroup,
          ],
        })
      );

    case getType(moveParticipantAsync.success):
      return loop(
        state,
        Cmd.list([
          Cmd.action({
            type: 'GET_EVENT_PARTICIPANTS',
            payload: { id: action.payload.eventId },
          }),
          Cmd.action({
            type: 'START_EVENTS_FETCH',
            payload: { date: '0', limit: '100', type: 'unarchived' },
          }),
          Cmd.run(historyService.goto, {
            args: [`/events/${action.payload.eventId}/participants`],
          }),
        ])
      );

    case getType(changeParticipantsData):
      const participantFormatted = state.participantsFormatted.find(
        (p) => p.uuid === state.participantUuid
      );
      const currentValue = participantFormatted[action.payload.id];
      const result =
        action.payload.array && typeof currentValue === 'object'
          ? currentValue.length === 0
            ? [action.payload.value]
            : currentValue.includes(action.payload.value)
            ? currentValue.filter((val) => val !== action.payload.value)
            : currentValue.concat(action.payload.value)
          : action.payload.value;
      const updatedParticipantFormatted = {
        ...participantFormatted,
        [action.payload.id]: result,
      };
      const participantsFormattedUpdated = state.participantsFormatted.map(
        (p) => {
          return p.uuid === state.participantUuid
            ? updatedParticipantFormatted
            : p;
        }
      );
      const participant = state.participants.find(
        (a) => a.partypantsUuid === state.participantUuid
      );
      const updatedParticipant = convertParticipantToPartypants(
        updatedParticipantFormatted,
        participant
      );
      const participantsUpdated = state.participants.map((p) => {
        return p.partypantsUuid === state.participantUuid
          ? updatedParticipant
          : p;
      });
      return {
        ...state,
        participantsFormatted: participantsFormattedUpdated,
        participants: participantsUpdated,
      };

    default:
      return state;
  }
};
const nonChangeable = (id: keyof TableParticipant) => {
  return (
    id !== 'uuid' &&
    id !== 'qrCode' &&
    id !== 'subEventId' &&
    id !== 'role' &&
    id !== 'groupUuid'
  );
};

export type ParticipantDetailsFormData = {
  inputs: FormInputInfo[];
  values: { [key: string]: string | string[] };
  uuid: string;
  eventId: string;
  subEventId: string;
  isPartOfGroup: boolean;
};
export function selectParticipantDetailsFormData(
  state: AppState
): ParticipantDetailsFormData {
  const items = state.eventState.registrationState.registrationQuestions.items;
  const foundParticipant = state.participantState.participantsFormatted.find(
    (participant) => participant.uuid === state.participantState.participantUuid
  );
  const foundIds = foundParticipant
    ? Object.keys(foundParticipant).filter((id) => nonChangeable(id))
    : [];
  const inputs = foundIds.map(
    (id) =>
      items.find((item) => item.id === id) || {
        id: id,
        question: '',
        show: true,
        type: 'text',
        group: 1,
        page: 1,
      }
  );

  const values = foundParticipant ? foundParticipant : {};
  const uuid = state.participantState.participantUuid;
  const eventId = state.eventState.eventId;
  const subEventId = state.participantState.subEventId;
  const isPartOfGroup = state.participantState.isPartOfGroup;
  return { inputs, values, uuid, eventId, subEventId, isPartOfGroup };
}
