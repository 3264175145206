import React from 'react';

import { FormattedMessage } from 'react-intl';
import { messages } from '../translations/confirmsend';

import { MappedEmailData } from '../certReducer';

import { EmailPreviewButtons } from './EmailPreviewButtons';
import { EmailPreviewItem } from './EmailPreviewItem';

type Props = {
  data: Array<Record<string, MappedEmailData>>;
  hasErrors: boolean;
};

export const EmailPreview: React.FC<Props> = ({ data, hasErrors }) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const emoji = hasErrors ? '❗' : '✅';

  const textColor = 'text-brand-dark_blue';

  return (
    <div className="p-4">
      <span className={`text-xl py-2 ${textColor}`}>
        2. <FormattedMessage {...messages.mappedemails} /> {emoji}
      </span>
      <EmailPreviewItem data={data[currentIndex]} />
      <EmailPreviewButtons
        amountOfItems={data.length}
        currentItem={currentIndex}
        changeItem={setCurrentIndex}
      />
    </div>
  );
};
