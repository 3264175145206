import React from 'react';
import TableData from '../../../../components/utils/TableData';
import { SubEventObject } from '../../../../types/types';
import { RiDeleteBin5Line } from 'react-icons/ri';

interface Props {
  subEvent: SubEventObject;
  headerKeys: string[];
  open: (id: string) => void;
  toggleDeleteModal: (subEventId: string) => void;
}

export const SubEventTableRow: React.FC<Props> = ({
  subEvent,
  headerKeys,
  open,
  toggleDeleteModal,
}) => {
  const styles = 'border px-8 py-4 h-12 cursor-pointer truncate';
  const rowStyles =
    'hover:text-brand-blue flex flex-col flex-nowrap md:table-row mb-2 md:mb-0';

  return (
    <>
      <tr className={rowStyles}>
        {headerKeys.map((key, i) => {
          const value = subEvent[key] ? subEvent[key] : '';
          const valueStr = typeof value !== 'string' ? value.join(', ') : value;
          return (
            <TableData
              key={`${i}_${key}`}
              value={valueStr}
              open={() => open(subEvent.subEventId)}
              styles={styles}
            />
          );
        })}
        <td className="hover:text-yellow-500 border px-8 py-4 h-24 w-1/12 cursor-pointer text-center">
          <RiDeleteBin5Line
            size={22}
            onClick={() => toggleDeleteModal(subEvent.subEventId)}
          />
        </td>
      </tr>
    </>
  );
};

export default SubEventTableRow;
