import { LoopReducer, loop, Loop, Cmd } from 'redux-loop';
import {
  getType,
  createAsyncAction,
  ActionType,
  createAction,
} from 'typesafe-actions';
import { AppState } from '../../reducers/combineReducer';
import eventService from '../../services/events';
import {
  EventObject,
  EventResponse,
  EventStats,
  ListEventsState,
  LyytiEventObject,
  SubEventStateData,
  SubEventTableData,
} from '../../types/types';
import { customKeySubEventStats } from '../../utils/functions';
import { PieData } from '../eventDetails/views/PieChart';
import { EventListType } from './views/ListEventsPage';

const initialState: ListEventsState = {
  events: [],
  eventsResponse: [],
  lyytiEvents: [],
};

export type GetEventsPayload = {
  date: string;
  limit: string;
  type: EventListType;
};
export const getAllEventsAsync = createAsyncAction(
  'START_EVENTS_FETCH',
  'EVENTS_FETCH_COMPLETE',
  'EVENTS_FETCH_FAIL'
)<GetEventsPayload, { data: EventResponse[]; status: number }, Error>();

export const getAllLyytiEventsAsync = createAsyncAction(
  'START_LYYTIEVENTS_FETCH',
  'LYYTIEVENTS_FETCH_COMPLETE',
  'LYYTIEVENTS_FETCH_FAIL'
)<string, { data: any; status: number; locale: string }, Error>();

export const getAllLyytiParticipantsAsync = createAsyncAction(
  'START_LYYTIPARTICIPANTS_FETCH',
  'LyytiPARTICIPANTSS_FETCH_COMPLETE',
  'LyytiPARTICIPANTSS_FETCH_FAIL'
)<string, { data: any; status: number }, Error>();

const getEventDetails = createAction('GET_EVENT_DETAILS')<{
  id: string;
  subEventId?: string;
}>();

type Action =
  | ActionType<typeof getAllEventsAsync>
  | ActionType<typeof getAllLyytiEventsAsync>
  | ActionType<typeof getAllLyytiParticipantsAsync>
  | ActionType<typeof getEventDetails>;

export const listEventsReducer: LoopReducer<ListEventsState, Action> = (
  state = initialState,
  action: Action
): ListEventsState | Loop<ListEventsState> => {
  switch (action.type) {
    case getType(getAllEventsAsync.request):
      return loop(
        state,
        Cmd.run(eventService.getAll, {
          successActionCreator: getAllEventsAsync.success,
          failActionCreator: getAllEventsAsync.failure,
          args: [action.payload],
        })
      );

    case getType(getAllEventsAsync.success):
      const mapped = formatEventsResponse(action.payload.data);
      if (action.payload.status === 200) {
        return {
          ...state,
          eventsResponse: action.payload.data,
          events: mapped,
        };
      }
      return {
        ...state,
        eventsResponse: initialState.eventsResponse,
        events: initialState.events,
      };

    case getType(getAllLyytiEventsAsync.request):
      return loop(
        state,
        Cmd.run(eventService.getEventsLyyti, {
          successActionCreator: getAllLyytiEventsAsync.success,
          failActionCreator: getAllLyytiEventsAsync.failure,
          args: [action.payload],
        })
      );

    case getType(getAllLyytiEventsAsync.success):
      if (action.payload.status === 200) {
        const lyytiEvents: LyytiEventObject[] = Object.keys(
          action.payload.data.results
        ).map((lyytiEvent) =>
          mapToLyytiEventObject(
            action.payload.data.results[lyytiEvent],
            action.payload.locale
          )
        );
        return {
          ...state,
          lyytiEvents: lyytiEvents,
        };
      }
      return state;

    case getType(getAllLyytiParticipantsAsync.request):
      if (action.payload.length === 0) {
        return state;
      }
      const event = state.lyytiEvents.find(
        (event) => event.name === action.payload
      );
      return loop(
        state,
        Cmd.run(eventService.getParticipantsLyyti, {
          successActionCreator: getAllLyytiParticipantsAsync.success,
          failActionCreator: getAllLyytiParticipantsAsync.failure,
          args: [event.eid],
        })
      );

    //set event details
    case getType(getEventDetails):
      const a = state.events.find((e) => e.eventId === action.payload.id);
      const details = {
        eventName: a.eventName,
        eventPlace: a.eventPlace,
        eventStreetAddress: a.eventStreetAddress,
        eventZip: a.eventZip,
        eventCity: a.eventCity,
        eventCountry: a.eventCountry,
        eventStartDate: `${a.startDate}T${a.startDateTime}`,
        eventEndDate: `${a.endDate}T${a.endDateTime}`,
        eventDescription: a.description,
        supportEmail: a.eventSupportEmail,
        supportPhone: a.eventSupportPhone,
        supportInstruction: a.eventSupportInstructrion,
      };

      const payload = action.payload.subEventId
        ? { details, subEventId: action.payload.subEventId }
        : { details };

      return loop(state, Cmd.action({ type: 'SET_EVENT_DETAILS', payload }));

    default:
      return state;
  }
};

function formatEventsResponse(data: EventResponse[]): EventObject[] {
  const formatted = data.map((e) => {
    const uniqueSubEvents = e.subEvents.filter(
      (sub, index) =>
        e.subEvents.findIndex((s) => s.subEventId === sub.subEventId) === index
    );

    return {
      ...e,
      subEvents: uniqueSubEvents.length,
      registered: customKeySubEventStats(uniqueSubEvents, 'registered'),
      cancelled: customKeySubEventStats(uniqueSubEvents, 'canceled'),
      queue: customKeySubEventStats(uniqueSubEvents, 'que'),
    };
  });
  return formatted;
}

export type ListEventData = {
  list: EventObject[];
  headerRow: string[];
};
export function selectListEventsState(state: AppState): ListEventData {
  const list = state.listEventsState.events;
  const headerRow =
    list.length > 0
      ? Object.keys(list[0]).length > 5
        ? Object.keys(list[0]).slice(0, 5)
        : Object.keys(list[0])
      : [];
  return { list, headerRow };
}

export type SubEventsTableData = {
  list: SubEventTableData[];
  headerRow: string[];
  subEventLinksTable: LinkTable;
};

export const selectSubEvent = (
  state: AppState,
  eventId: string,
  subEventId: string
): SubEventStateData | false => {
  const event = state.listEventsState.eventsResponse.find(
    (e) => e.eventId === eventId
  );
  return event
    ? event.subEvents.find((s) => s.subEventId === subEventId)
    : false;
};

export function selectSubEventState(
  state: AppState,
  eventId: string,
  subEventId: string
): string {
  const result = selectSubEvent(state, eventId, subEventId);
  return result ? result.subEventState : '';
}

type LinkTable = {
  [key: string]: string[];
};

export function selectSubEvents(
  state: AppState,
  eventId: string
): SubEventsTableData {
  const found = state.listEventsState.eventsResponse.find(
    (e) => e.eventId === eventId
  );
  const raw = found ? found.subEvents : [];
  const subEventLinksTable: LinkTable = raw.reduce((acc, curr) => {
    const links = acc[curr.subEventId]
      ? acc[curr.subEventId].concat(curr.subEventLinkUrl)
      : [curr.subEventLinkUrl];
    return { ...acc, [curr.subEventId]: links };
  }, {});
  const list = Object.keys(subEventLinksTable).map((subEventId) => {
    return {
      ...raw.find((sub) => sub.subEventId === subEventId),
      subEventLinkUrl: subEventLinksTable[subEventId],
    };
  });
  const headerRow =
    raw.length > 0
      ? Object.keys(raw[0]).length > 5
        ? Object.keys(raw[0]).slice(0, 5)
        : Object.keys(raw[0])
      : [];
  return { list, headerRow, subEventLinksTable };
}

export function mapToLyytiEventObject(
  lyytiEvent: any,
  locale: string
): LyytiEventObject {
  const contact_person = lyytiEvent.contact_person[locale]
    ? lyytiEvent.contact_person[locale]
    : Object.values(lyytiEvent.contact_person)[0];
  return {
    eid: lyytiEvent.eid,
    owner: lyytiEvent.owner,
    owner_name: lyytiEvent.owner_name,
    name: lyytiEvent.name[locale]
      ? lyytiEvent.name[locale]
      : Object.values(lyytiEvent.name)[0],
    location: lyytiEvent.location[locale]
      ? lyytiEvent.location[locale]
      : Object.values(lyytiEvent.location)[0],
    address: lyytiEvent.address[locale]
      ? lyytiEvent.address[locale]
      : Object.values(lyytiEvent.address)[0],
    homepage: lyytiEvent.homepage[locale]
      ? lyytiEvent.homepage[locale]
      : Object.values(lyytiEvent.homepage)[0],
    homepage_title: lyytiEvent.homepage_title[locale]
      ? lyytiEvent.homepage_title[locale]
      : Object.values(lyytiEvent.homepage_title)[0],
    participant_type_heading: lyytiEvent.participant_type_heading[locale]
      ? lyytiEvent.participant_type_heading[locale]
      : Object.values(lyytiEvent.participant_type_heading)[0],
    online_event_link_title: lyytiEvent.online_event_link_title[locale]
      ? lyytiEvent.online_event_link_title[locale]
      : Object.values(lyytiEvent.online_event_link_title)[0],
    contact_person_name: contact_person.name,
    contact_person_email: contact_person.email,
    has_participants: lyytiEvent.hasParticipants,
  };
}

export type SubEventImport = {
  subEventId: string;
  formId: string;
  subEventName: string;
};
export const selectSubEventsWithRegistrationState = (
  state: AppState
): SubEventImport[] => {
  return state.listEventsState.eventsResponse
    .map((e) =>
      e.subEvents.filter(
        (sub, index) =>
          e.subEvents.findIndex(
            (s) => s.subEventId === sub.subEventId && s.subEventFormId !== '0'
          ) === index
      )
    )
    .flat()
    .map((s) => {
      const name = s.subEventName ? s.subEventName : 'Unnamed';
      return {
        subEventId: s.subEventId,
        subEventName: `${name} :: ${s.subEventId}`,
        formId: s.subEventFormId,
      };
    });
};

export type EventData = {
  data: PieData[];
  eventStats: EventStats;
};
export const selectEventData = (state: AppState, eventId: string) => {
  const eventName = state.eventState.eventDetails.eventName;
  const { list } = selectSubEvents(state, eventId);
  const data = list
    .filter((d) => d.subEventStats.registered)
    .map((data) => {
      const name = data.subEventName ? data.subEventName : 'Unnamed';
      return {
        label: `${name} (${data.subEventStats.registered})`,
        value: data.subEventStats.registered,
      };
    });
  const event = state.listEventsState.events.find(
    (event) => event.eventId === eventId
  );
  const subEvents = event ? event.subEvents : 0;
  const registered = event ? event.registered : 0;
  const cancelled = event ? event.cancelled : 0;
  const queue = event ? event.queue : 0;
  const eventCreatedAt = event ? event.eventCreatedAt : null;
  const startDate = event ? event.startDate : null;
  const endDate = event ? event.endDate : null;
  return {
    data,
    eventStats: {
      eventName,
      subEvents,
      registered,
      cancelled,
      queue,
      eventCreatedAt,
      startDate,
      endDate,
    },
  };
};
