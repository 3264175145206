import React from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../actions/allActions';
import * as cState from '../certReducer';

interface CertParticipantHeaderProps {
  headers: string[];
  sort: (header: string) => void;
  currentView: { [key: string]: string }[];
  hasTableParticipants: boolean;
}

export const CertParticipantTableHeader: React.FC<CertParticipantHeaderProps> =
  ({ headers, sort, currentView, hasTableParticipants }) => {
    const styles =
      'bg-blue-100 text-left text-brand-dark_blue px-8 py-4 h-12 cursor-pointer hover:text-brand-blue truncate';
    const selected = useSelector(cState.getSelectedParticipants);
    const dispatch = useDispatch();
    const uids = currentView.map((p) => p.uuid);
    return (
      <thead>
        <tr className="flex flex-col flex-no wrap md:table-row rounded-l-lg md:rounded-none mb-2 md:mb-0">
          {hasTableParticipants && (
            <th className={`bg-blue-100`}>
              <label
                htmlFor={`selectAll`}
                className={`relative cursor-pointer select-none multiSelect`}
              >
                <input
                  className="cursor-pointer"
                  type="checkbox"
                  id="selectAll"
                  checked={selected.length > 0}
                  onChange={() =>
                    dispatch(allActions.certActions.toggleAllRecipients(uids))
                  }
                />
                <span
                  className={`absolute -bottom-2 -left-6 h-4 w-4 ${
                    selected.length > 0 ? 'bg-blue-400' : 'bg-blue-50 checkmark'
                  }`}
                >
                  {selected.length > 0 ? (
                    <AiOutlineCheck style={{ color: '#00255f' }} />
                  ) : null}
                </span>
              </label>
            </th>
          )}
          {headers.map((header) => (
            <th key={header} className={styles} onClick={() => sort(header)}>
              {header}
            </th>
          ))}
        </tr>
      </thead>
    );
  };

export default CertParticipantTableHeader;
