import React from 'react';
import { useIntl } from 'react-intl';
import FormSelect from '../../../components/form/FormSelect';
import { Button } from '../../../components/utils/Button';
import Paragraph from '../../../components/utils/Paragrahp';
import { DefinedMessages } from '../../../types/types';

type Props = {
  closeModal: () => void;
  createSubEvent: (type: 'frontPage' | 'normal') => void;
};

const messages: DefinedMessages = {
  button: {
    id: 'subevent.create.modal.ok.button',
    defaultMessage: 'Create',
  },
  cancelbutton: {
    id: 'subevent.create.modal.cancel.button',
    defaultMessage: 'Cancel',
  },
  question: {
    id: 'subevent.create.modal.select.question',
    defaultMessage: 'Type',
  },

  description: {
    id: 'subevent.create.modal.select.description',
    defaultMessage: 'What kind of subevent are you creating?',
  },
  frontpage: {
    id: 'subevent.create.modal.select.frontpage',
    defaultMessage: 'frontpage description',
  },
  subevent: {
    id: 'subevent.create.modal.select.subevent',
    defaultMessage: 'subevent description',
  },
  optionSub: {
    id: 'subevent.create.modal.option.subevent',
    defaultMessage: 'Subevent',
  },
  optionFront: {
    id: 'subevent.create.modal.option.frontpage',
    defaultMessage: 'Frontpage',
  },
};

const mapType = {
  Etusivu: 'frontPage',
  Frontpage: 'frontPage',
  Subevent: 'normal',
  Alitapahtuma: 'normal',
};

export const CreateSubEventModal: React.FC<Props> = ({
  closeModal,
  createSubEvent,
}) => {
  const options = ['optionSub'] as const;
  // const options = ['optionSub', 'optionFront'] as const;
  //
  const { formatMessage } = useIntl();
  const [type, setType] = React.useState('');

  const changeType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setType(e.target.value);
  };

  const lineBreak = { lineBreak: <br /> };
  const paragraph =
    mapType[type] === 'frontPage' ? messages.frontpage : messages.subevent;

  const isDisabled = type === '';

  return (
    <div className="absolute top-1/4 px-20 py-10 bg-brand-white rounded z-10 border">
      <FormSelect
        id="selectSubEvent"
        options={[''].concat(
          options.map((op) => formatMessage({ ...messages[op] }))
        )}
        question={formatMessage({ ...messages.question })}
        cursiveLabel={formatMessage({ ...messages.description })}
        value={type}
        handleInputChange={changeType}
      />
      <Paragraph message={paragraph} header={false} values={lineBreak} />
      <div className="flex">
        <Button
          id="CreateSubEventOkButton"
          onOk={() => createSubEvent(mapType[type])}
          msg={{ ...messages.button }}
          disabled={isDisabled}
        />
        <Button
          id="CreateSubEventCancelButton"
          onOk={closeModal}
          msg={{ ...messages.cancelbutton }}
        />
      </div>
    </div>
  );
};
