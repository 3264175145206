import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedMessageProps } from '../../types/types';

interface Props {
  styles?: string;
  onOk: (id: string) => void;
  msg: FormattedMessageProps;
  id: string;
  disabled?: boolean;
}

export const Button: React.FC<Props> = ({
  styles,
  onOk,
  msg,
  id,
  disabled,
}) => {
  const buttonColor = disabled
    ? 'bg-gray-500 cursor-default text-brand-white'
    : 'bg-brand-white hover:bg-blue-200 text-brand-blue';
  const style = styles
    ? styles
    : `${buttonColor} m-4 rounded w-20 h-10 border border-gray-200 shadow-lg`;
  return (
    <button onClick={() => onOk(id)} className={style} disabled={disabled}>
      <FormattedMessage {...msg} />
    </button>
  );
};
