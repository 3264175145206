import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../actions/allActions';
import { EventParamTypes, PostLink } from '../../../types/types';
import * as eState from '../../eventReducer';
import { BreadCrumb } from '../../../components/breadcrumbs/BreadCrumb';
import { BreadRoute } from '../../../components/breadcrumbs/BreadRoute';
import { useParams } from 'react-router-dom';
import { Loading } from '../../../components/utils/Loading';
import { LinkList } from '../components/LinkList';
import * as erState from '../../../reducers/errorReducer';
import { selectLoadingMultiple } from '../../../reducers/loadingReducer';
import { useNotify } from '../../../hooks/useNotify';
import { AppState } from '../../../reducers/combineReducer';
import { LinkDetails } from '../components/LinkDetails';
import { LinkForm } from '../components/LinkForm';
import ErrorFetching from '../../../components/modals/ErrorFetching';

export const Linkpage: React.FC = () => {
  const { linkId, linkPrefix, linkSuggestion, link } = useSelector(
    eState.selectLinkPageData
  );
  const { id, subEventId } = useParams<EventParamTypes>();
  const { errors } = useSelector(eState.selectRegistrationValidationData);
  const { message } = useSelector(erState.getErrorMessage);
  const links = useSelector((state: AppState) =>
    eState.selectSubEventLinks(state, id, subEventId)
  );

  const isLoading = useSelector((state: AppState) =>
    selectLoadingMultiple(state, ['getLinkSuggestion', 'postLink'])
  );
  const dispatch = useDispatch();

  const error = useNotify(message);

  const handleNestedItemChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    id: string
  ) => {
    const itemValues = id.split(':');
    dispatch(
      allActions.eventActions.changePageStyling(
        itemValues[1],
        event.target.value,
        itemValues[0]
      )
    );
  };

  const publishForm = (publish: boolean) => {
    const postLinkObject: PostLink = {
      subEventId,
      publishSubEvent: publish,
      linkId,
      linkUrl: `${linkPrefix}/${linkSuggestion}`,
    };
    dispatch(allActions.eventActions.startPostLinkForm(postLinkObject));
  };

  const getLinkSuggestion = useCallback(() => {
    dispatch(allActions.eventActions.startGetLinkSuggestion());
  }, [dispatch]);

  useEffect(() => {
    getLinkSuggestion();
  }, [getLinkSuggestion]);

  return (
    <div className="flex-col w-11/12 h-screen mx-auto pr-4">
      <BreadRoute>
        <BreadCrumb label={'event-menu'} link={'/eventmenu'} />
        <BreadCrumb label={'events'} link={'/events'} />
        <BreadCrumb label={`event ${id}`} link={`/events/${id}`} />
        <BreadCrumb label={`subevents`} link={`/events/${id}/subevents/`} />
        <BreadCrumb
          label={`subevent ${subEventId}`}
          link={`/events/${id}/subevents/${subEventId}`}
        />
        <BreadCrumb
          label={`link`}
          link={`/events/${id}/subevents/${subEventId}/link`}
          lastChild={true}
        />
      </BreadRoute>

      <div className="h-full max-h-11/12 w-full flex justify-center overflow-auto my-auto border border-gray-400 scroller relative">
        {isLoading ? (
          <Loading styles={`items-center h-full`} />
        ) : (
          <div className="flex self-center w-5/6 h-5/6 mx-auto text-center">
            <div className="container flex flex-col w-1/2 m-2">
              <LinkList list={links} />
              <LinkDetails link={link} linkPrefix={linkPrefix} />
            </div>
            <LinkForm
              linkSuggestion={linkSuggestion}
              handleNestedItemChange={handleNestedItemChange}
              errors={errors}
              publishForm={publishForm}
            />
            <ErrorFetching
              styles="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-8 border shadow-xl z-50 bg-brand-white"
              message={error}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Linkpage;
