import React from 'react';
import { BiChevronRight, BiChevronLeft } from 'react-icons/bi';
import { useIntl } from 'react-intl';
import { DefinedMessages } from '../types/types';

type Props = {
  position: string;
  expanded: boolean;
  toggleElement: (id: string) => void;
  toggleId: string;
};
export const NavBallToggler: React.FC<Props> = ({
  position,
  expanded,
  toggleElement,
  toggleId,
}) => {
  const style =
    'p-2 m-auto color-brand-white bg-brand-blue rounded-full hover:bg-yellow-400 cursor-pointer shadow-md';
  const message: DefinedMessages = {
    togglerTitle: {
      id: 'navigation.toggler.title',
      defaultMessage: 'Hide/show navigation',
    },
  };
  const { formatMessage } = useIntl();

  return (
    <div className={position}>
      <button
        title={formatMessage(message.togglerTitle)}
        className={style}
        onClick={() => toggleElement(toggleId)}
      >
        {expanded ? (
          <BiChevronLeft size={24} color={'white'} />
        ) : (
          <BiChevronRight size={24} color={'white'} />
        )}
      </button>
    </div>
  );
};
