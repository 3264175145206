import React from 'react';

import { useSelector } from 'react-redux';
import { getCustomerCertTemplate } from '../certReducer';
import { AppState } from '../../reducers/combineReducer';

import { EmailSendContainer } from './EmailSendContainer';
import { CertSendContainer } from './CertSendContainer';
import NoTemplate from './NoTemplate';
import { emailTemplates } from '../wysiwygEmailMappings';

interface Props {
  templateName: string;
}

export const SendContainer: React.FC<Props> = ({ templateName }) => {
  const { found, template } = useSelector((state: AppState) =>
    getCustomerCertTemplate(state, templateName)
  );

  const isEmailSend = emailTemplates.includes(templateName);

  return isEmailSend ? (
    <EmailSendContainer />
  ) : found ? (
    <CertSendContainer templateId={template.templateId} />
  ) : (
    <NoTemplate />
  );
};

export default SendContainer;
