import React from 'react';
import { InvoiceMultisendInputs } from './InvoiceMultisendInputs';
import { InvoiceSingleSendInputs } from './InvoiceSingleSendInputs';
import { DeliveryMethods, InputDetails } from '../invoiceMappings';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../reducers/combineReducer';
import { selectFormInputValues } from '../../reducers/formReducer';
import allActions from '../../actions/allActions';
import FormRadio from '../../components/form/FormRadio';
import { useIntl } from 'react-intl';
import { messages } from '../translations';

type Props = {
  multiSend: boolean;
  mapping: { [key: string]: InputDetails };
};

export const InvoiceMappingForm: React.FC<Props> = ({ multiSend, mapping }) => {
  const { control, register } = useForm();
  const dispatch = useDispatch();

  const values = useSelector((state: AppState) =>
    selectFormInputValues(state, 'newInvoice')
  );
  const deliveryMethod = values.delivery_method as unknown as DeliveryMethods;
  const { formatMessage } = useIntl();

  const handleSelect = (
    e: React.ChangeEvent<HTMLSelectElement>,
    id: string,
    type: 'ProductInfo' | 'Normal' = 'Normal'
  ) => {
    if (type === 'ProductInfo') {
      return dispatch(
        allActions.invoiceActions.setDefaultFennoaProductValues(e.target.value)
      );
    }
    return dispatch(
      allActions.formActions.updateValue(id, e.target.value, 'newInvoice')
    );
  };

  const handleInput = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    id: string
  ) => {
    dispatch(
      allActions.formActions.updateValue(id, e.target.value, 'newInvoice')
    );
  };

  const resetDate = (id: string) => {
    dispatch(allActions.formActions.updateDateValue(id, null, 'newInvoice'));
  };

  const handleDateChange = (date: Date, id: string) => {
    dispatch(allActions.formActions.updateDateValue(id, date, 'newInvoice'));
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="self-center w-1/2 pt-2">
        <FormRadio
          id="delivery_method"
          question={formatMessage({ ...messages.deliveryMethod })}
          options={['email', 'finvoice']}
          checked={deliveryMethod}
          handleInputChange={handleSelect}
        />
      </div>
      <div className="flex flex-wrap w-full h-full pl-2 pr-2 pb-2 pt-8">
        {multiSend ? (
          <InvoiceMultisendInputs
            values={values}
            inputs={mapping}
            handleInputChange={handleSelect}
            handleDateChange={handleDateChange}
            resetDate={resetDate}
            control={control}
            register={register}
          />
        ) : (
          <InvoiceSingleSendInputs
            values={values}
            inputs={mapping}
            handleInputChange={handleInput}
            handleSelectChange={handleSelect}
            handleDateChange={handleDateChange}
            resetDate={resetDate}
            control={control}
            register={register}
          />
        )}
      </div>
    </div>
  );
};
