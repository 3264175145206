import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as erState from '../../reducers/errorReducer';
import * as t from '../../components/tableSearchBar/translations';
import { useNotify } from '../../hooks/useNotify';
import { useTable } from '../../hooks/useTable';
import { useIntl } from 'react-intl';
import InvoiceReceiverTableRow from './InvoiceReceiverTableRow';
import InvoiceReceiverTableHeader from './InvoiceReceiverTableHeader';
import ErrorFetching from '../../components/modals/ErrorFetching';
import Pagination from '../../components/utils/Pagination';
import SearchBar from '../../components/tableSearchBar/SearchBar';
import { getUniqueKeysForTable, mapFromKeys } from '../../utils/functions';
import Loading from '../../components/utils/Loading';
import { BackDrop } from '../../components/tableSearchBar/BackDrop';

interface Props {
  list: any[];
  headerRow: string[];
  loading: boolean;
  tableName: string;
}

export const InvoiceReceiverTable: React.FC<Props> = ({
  list,
  headerRow,
  loading,
  tableName,
}) => {
  const { message } = useSelector(erState.getErrorMessage);
  const errorMsg = useNotify(message);
  const { formatMessage } = useIntl();
  const filterDivToggleId = 'INVOICE_RECEIVERS_TABLE_FILTER';
  const tableSettingsDivId = 'INVOICE_RECEIVERS_TABLE_SETTINGS';
  const exportSettingsDivId = 'INVOICE_RECEIVERS_TABLE_EXPORT_SETTINGS';
  const pageLimit = 9;
  const initialState = {
    tableName: tableName,
    tableList: list,
    incomingHeaders: headerRow,
    currentPage: 1,
    totalPages: Math.ceil(list.length / pageLimit),
    pageLimit: pageLimit,
    totalRecords: list.length,
    pageNeighbours: 0,
    filteredList: list,
    currentList: list.slice(0, pageLimit),
    filterKeys: [],
    searchWord: '',
    sortKey: 'firstName',
    ascending: false,
    possibleSearchKeys: getUniqueKeysForTable(list),
    currentHeaders: headerRow,
    headers: {
      droppableColumnsSelected: headerRow,
      droppableColumnsAll: getUniqueKeysForTable(list).filter(
        (item) => !headerRow.includes(item)
      ),
    },
    export: {
      exportAll: {
        data: mapFromKeys(list, getUniqueKeysForTable(list)),
        filename: 'invoice_receivers_all',
        label: formatMessage({ ...t.messages.exportall }),
      },
      exportView: {
        data: mapFromKeys(list, headerRow),
        filename: 'invoice_receivers_view',
        label: formatMessage({ ...t.messages.exportview }),
      },
    },
  };

  const table = useTable(initialState);
  const currentList = table.state.currentList as any[];

  const updateTableData = useCallback(() => {
    table.changeHeadersAndTable(list, headerRow);
  }, [list, headerRow]); //eslint-disable-line

  useEffect(() => {
    updateTableData();
  }, [updateTableData]);

  return (
    <>
      <BackDrop
        modalIds={[filterDivToggleId, tableSettingsDivId, exportSettingsDivId]}
      />
      <div className="flex flex-col shadow-lg bg-brand-white md:w-full h-full overflow-y-auto overflow-x-hidden scroller">
        <ErrorFetching message={errorMsg} />
        <SearchBar
          searchWord={table.state.searchWord}
          exportSettings={table.state.export}
          sortKeys={table.state.possibleSearchKeys}
          onDragEnd={table.onDragEnd}
          selectedColumns={table.state.headers.droppableColumnsSelected}
          restColumns={table.state.headers.droppableColumnsAll}
          filterDivToggleId={filterDivToggleId}
          tableSettingsDivToggleId={tableSettingsDivId}
          exportSettingsDivToggleId={exportSettingsDivId}
          activeFilters={table.state.filterKeys}
          toggleFilter={table.toggleFilter}
          clearFilters={table.clearFilters}
          onSearchWordChange={table.onSearchWordChange}
        />
        {loading ? (
          <Loading styles="my-auto" />
        ) : (
          <table className="md:bg-white rounded-lg md:table-fixed w-full">
            <InvoiceReceiverTableHeader
              currentView={table.state.filteredList}
              headers={table.state.headers.droppableColumnsSelected}
              sort={table.sort}
              hasTableReceivers={table.state.tableList.length > 0}
            />
            <tbody>
              {currentList.map((p, i) => (
                <InvoiceReceiverTableRow
                  receiver={p}
                  key={i}
                  headerKeys={table.state.headers.droppableColumnsSelected}
                />
              ))}
            </tbody>
          </table>
        )}
        <Pagination
          pageLimit={table.state.pageLimit}
          totalPages={table.state.totalPages}
          pageNeighbours={table.state.pageNeighbours}
          currentPage={table.state.currentPage}
          onPageChanged={table.gotoPage}
        />
      </div>
    </>
  );
};

export default InvoiceReceiverTable;
