import React from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../actions/allActions';
import { TableData } from '../../components/utils/TableData';
import * as cState from '../certReducer';

interface CertParticipantRowProps {
  participant: any;
  headerKeys: string[];
}

export const CertParticipantTableRow: React.FC<CertParticipantRowProps> = ({
  participant,
  headerKeys,
}) => {
  const styles = 'border px-8 py-4 h-12 truncate';
  const rowStyles =
    'hover:text-blue-400 flex flex-col flex-nowrap md:table-row mb-2 md:mb-0';
  const dispatch = useDispatch();
  const selected = useSelector(cState.getSelectedParticipants);
  const check = selected.includes(participant.uuid);

  return (
    <>
      <tr className={rowStyles}>
        <td
          className={`${styles} text-center cursor-pointer`}
          onClick={() =>
            dispatch(allActions.certActions.toggleRecipient(participant.uuid))
          }
        >
          <label
            htmlFor={`select:${participant.uuid}`}
            className={`relative cursor-pointer select-none multiSelect`}
          >
            <input
              className="cursor-pointer"
              type="checkbox"
              id={`select:${participant.uuid}`}
              checked={check}
              onChange={() =>
                dispatch(
                  allActions.certActions.toggleRecipient(participant.uuid)
                )
              }
            />
            <span
              className={`absolute -bottom-2 -left-6 h-4 w-4 ${
                check ? 'bg-blue-400' : 'bg-blue-100 checkmark'
              }`}
            >
              {check ? <AiOutlineCheck style={{ color: '#00255f' }} /> : null}
            </span>
          </label>
        </td>
        {headerKeys.map((key) => {
          const value = participant[key] ? participant[key] : '';
          const valueStr =
            typeof value !== 'string' && typeof value !== 'number'
              ? value.join(', ')
              : value;
          return <TableData key={key} value={valueStr} styles={styles} />;
        })}
      </tr>
    </>
  );
};

export default CertParticipantTableRow;
