import React from 'react';
import * as imgs from '../../img/images';

export const NotFound: React.FC = () => {
  return (
    <div className="h-screen w-screen">
      <div className="h-5/6 w-5/6">
        <img
          className="h-full m-auto "
          src={`/${imgs.NOTFOUND}`}
          alt="Not found"
        />
      </div>
    </div>
  );
};
