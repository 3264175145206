import React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../actions/allActions';
import { FormDate } from '../../components/form/FormDate';
import FormInput from '../../components/form/FormInput';
import FormTextArea from '../../components/form/FormTextArea';
import { ErrorFetching } from '../../components/modals/ErrorFetching';
import * as fState from '../../reducers/formReducer';
import * as eState from '../../reducers/errorReducer';
import { useNotify } from '../../hooks/useNotify';
import BreadCrumb from '../../components/breadcrumbs/BreadCrumb';
import { BreadRoute } from '../../components/breadcrumbs/BreadRoute';
import * as t from './createEventTranslations';
import * as imgs from '../../img/images';

export const CreateEventPage: React.FC = () => {
  const formName = 'createNewEvent';
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control } = useForm();
  const {
    formState,
    eventCityId,
    eventCountryId,
    eventDescriptionId,
    eventEndId,
    eventNameId,
    eventOrganiserId,
    eventPlaceId,
    eventStartId,
    eventStreetAddressId,
    eventWebPageId,
    eventZipId,
    supportEmailId,
    supportInstructionId,
    supportPhoneId,
    eventCityValue,
    eventCountryValue,
    eventDescriptionValue,
    eventEndDateValue,
    eventNameValue,
    eventOrganiserValue,
    eventPlaceValue,
    eventStartDateValue,
    eventStreetAddressValue,
    eventWebPageValue,
    eventZipValue,
    supportEmailValue,
    supportInstructionValue,
    supportPhoneValue,
  } = useSelector(fState.selectCreateEventFormInputs);
  const { message } = useSelector(eState.getErrorMessage);
  const errorMsg = useNotify(message);
  const styles =
    'w-full pb-16 border border-gray-300 divide-y divide-gray-300 overflow-auto scroller relative shadow-xl rounded';
  const errorDivStyles =
    'absolute z-50 transform translate-y-1/2 translate-x-1/2 top-0 left-0 flex justify-center place-items-center  w-1/2 h-1/6 shadow-xl bg-white border-none rounded';
  const inputContainers = 'w-1/3';

  const create = () => {
    dispatch(allActions.formActions.setCreateEvent());
  };

  const { formatMessage } = useIntl();

  const handleInputChange = (event: any, id: string, type?: string) => {
    if (type === 'array') {
      const currentValue: string[] | string = formState[id];
      const newArray =
        currentValue === undefined
          ? []
          : typeof currentValue === 'string'
          ? [currentValue]
          : currentValue;
      dispatch(
        allActions.formActions.addToValue(
          id,
          newArray,
          event.target.value,
          formName
        )
      );
    } else {
      dispatch(
        allActions.formActions.updateValue(id, event.target.value, formName)
      );
    }
  };

  const resetDate = (id: string) => {
    dispatch(allActions.formActions.updateDateValue(id, null, formName));
  };

  const handleDateChange = (date: Date, id: string) => {
    dispatch(allActions.formActions.updateDateValue(id, date, formName));
  };

  const buttonInfo = {
    style:
      'bg-blue-400 hover:bg-brand-blue text-brand-white py-2 px-4 rounded m-5 w-20',
  };

  const validateStartDate = () => {
    if (!eventStartDateValue) {
      return 'any';
    }
    if (eventEndDateValue) {
      return eventStartDateValue < eventEndDateValue || `startdate`;
    }
    return;
  };

  return (
    <div className="h-screen w-11/12 mx-auto pr-4">
      <BreadRoute>
        <BreadCrumb label={'event-menu'} link={'/eventmenu'} />
        <BreadCrumb label={'create'} link={'/createevent'} lastChild={true} />
      </BreadRoute>
      <div className="flex h-1/4 min-h-1/4 justify-center">
        <div className="h-full flex flex-col justify-center">
          <div className="h-1/6">
            <span className="semibold text-3xl text-brand-dark_blue">
              <FormattedMessage {...t.messages.header} />
            </span>
          </div>
          <div className="h-1/6">
            <span className="text-xl text-brand-dark_blue">
              <FormattedMessage {...t.messages.paragraph} />
            </span>
          </div>
        </div>
        <div className="w-1/2 h-full">
          <img
            className="h-full min-h-full"
            src={`/${imgs.EVENT_CARD_CREATE}`}
            alt={`event_creating`}
          />
        </div>
      </div>
      <form className={styles} onSubmit={handleSubmit(create)}>
        <div className="flex justify-evenly">
          <FormInput
            id={eventNameId}
            question={formatMessage({ ...t.messages.eventname })}
            value={eventNameValue}
            handleInputChange={handleInputChange}
            containerStyle={inputContainers}
            type="text"
            register={register({ required: `any` })}
            errors={errors}
          />
          <FormTextArea
            id={eventDescriptionId}
            question={formatMessage({ ...t.messages.description })}
            value={eventDescriptionValue}
            handleInputChange={handleInputChange}
            containerStyle={inputContainers}
            register={register({ required: `any` })}
            errors={errors}
          />
          <FormInput
            id={eventOrganiserId}
            question={formatMessage({ ...t.messages.organiser })}
            value={eventOrganiserValue}
            handleInputChange={handleInputChange}
            containerStyle={inputContainers}
            type="text"
            register={register({ required: `any` })}
            errors={errors}
          />
        </div>
        <div className="flex justify-evenly divide-x divide-gray-200">
          <FormInput
            id={eventPlaceId}
            question={formatMessage({ ...t.messages.place })}
            value={eventPlaceValue}
            handleInputChange={handleInputChange}
            containerStyle={inputContainers}
            type="text"
            register={register({ required: `any` })}
            errors={errors}
          />
          <FormInput
            id={eventStreetAddressId}
            question={formatMessage({ ...t.messages.streetaddress })}
            value={eventStreetAddressValue}
            handleInputChange={handleInputChange}
            containerStyle={inputContainers}
            type="text"
            register={register({ required: `any` })}
            errors={errors}
          />
          <FormInput
            id={eventZipId}
            question={formatMessage({ ...t.messages.zip })}
            value={eventZipValue}
            handleInputChange={handleInputChange}
            containerStyle={inputContainers}
            type="text"
            register={register({ required: `any` })}
            errors={errors}
          />
          <FormInput
            id={eventCityId}
            question={formatMessage({ ...t.messages.city })}
            value={eventCityValue}
            handleInputChange={handleInputChange}
            containerStyle={inputContainers}
            type="text"
            register={register({ required: `any` })}
            errors={errors}
          />
          <FormInput
            id={eventCountryId}
            question={formatMessage({ ...t.messages.country })}
            value={eventCountryValue}
            handleInputChange={handleInputChange}
            containerStyle={inputContainers}
            type="text"
            register={register({ required: `any` })}
            errors={errors}
          />
        </div>
        <div className="flex justify-center">
          <FormDate
            id={eventStartId}
            question={formatMessage({ ...t.messages.start })}
            value={eventStartDateValue}
            handleInputChange={handleDateChange}
            containerStyle={inputContainers}
            register={{
              validate: () => validateStartDate(),
            }}
            required={true}
            reset={resetDate}
            errors={errors}
            control={control}
          />
          <FormDate
            id={eventEndId}
            question={formatMessage({ ...t.messages.end })}
            value={eventEndDateValue}
            handleInputChange={handleDateChange}
            containerStyle={inputContainers}
            register={{
              validate: () => {
                return eventEndDateValue !== null || `any`;
              },
            }}
            required={true}
            reset={resetDate}
            errors={errors}
            control={control}
          />
        </div>
        <div className="flex justify-evenly">
          <FormInput
            id={eventWebPageId}
            question={formatMessage({ ...t.messages.webpage })}
            value={eventWebPageValue}
            handleInputChange={handleInputChange}
            containerStyle={inputContainers}
            type="text"
            errors={errors}
          />
          <FormInput
            id={supportEmailId}
            question={formatMessage({ ...t.messages.supportemail })}
            value={supportEmailValue}
            handleInputChange={handleInputChange}
            containerStyle={inputContainers}
            type="text"
            register={register({
              required: `any`,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, //eslint-disable-line
                message: `email`,
              },
            })}
            errors={errors}
          />
          <FormInput
            id={supportPhoneId}
            question={formatMessage({ ...t.messages.supportphone })}
            value={supportPhoneValue}
            handleInputChange={handleInputChange}
            containerStyle={inputContainers}
            type="text"
            errors={errors}
          />
          <FormInput
            id={supportInstructionId}
            question={formatMessage({ ...t.messages.instructions })}
            value={supportInstructionValue}
            handleInputChange={handleInputChange}
            containerStyle={inputContainers}
            type="text"
            errors={errors}
          />
        </div>
        <ErrorFetching message={errorMsg} styles={errorDivStyles} />
        <button type="submit" className={buttonInfo.style}>
          <FormattedMessage {...t.messages.submit} />
        </button>
      </form>
    </div>
  );
};
