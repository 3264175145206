import React from 'react';

import { useForm } from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../actions/allActions';
import { AppState } from '../../reducers/combineReducer';
import { selectWysiwygEmailMappings } from '../certReducer';
import * as eState from '../../reducers/errorReducer';

import { emailMappingLabels } from '../wysiwygEmailMappings';
import { useNotify } from '../../hooks/useNotify';

import { useIntl } from 'react-intl';
import { messages } from '../translations/confirmsend';

import { EmailMapper } from './EmailMapper';
import Paragraph from '../../components/utils/Paragrahp';
import { Receivers } from './Receivers';
import { EmailPreview } from './EmailPreview';
import ErrorFetching from '../../components/modals/ErrorFetching';
import { EmailErrorsView } from './EmailErrors';
import { EmailSend } from './EmailSend';

interface Props {}

export const EmailSendContainer: React.FC<Props> = () => {
  const { mappings, personQuestionIds, amountOfReceivers, data, allErrors } =
    useSelector((state: AppState) => selectWysiwygEmailMappings(state));

  const { message } = useSelector(eState.getErrorMessage);
  const errorMsg = useNotify(message);
  const { formatMessage } = useIntl();
  const { register, errors, trigger } = useForm();
  const dispatch = useDispatch();

  const changeHardCoded = (id: string) => {
    dispatch(allActions.certActions.changeWysiwygEmailHardCoded(id));
  };

  const changeWysiwygEmailMappingValue = (
    event:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    dispatch(
      allActions.certActions.setWysiwygEmailMappingValue(id, event.target.value)
    );
  };

  const validate = (id: string) => {
    trigger(id);
  };

  const sendEmail = () => {
    dispatch(allActions.certActions.startMessageSend(data));
  };

  const errorDivStyles =
    'absolute z-50 transform translate-y-1/2 translate-x-1/2 top-0 left-0 flex justify-center place-items-center w-1/2 h-1/6 shadow-xl bg-white border-none rounded';

  return (
    <div className="flex w-full h-full divide-x divide-gray-200">
      <div className="w-1/2">
        <Paragraph message={messages.emailmappingheader} header={true} />
        {mappings.map((mapping) => (
          <EmailMapper
            key={mapping.id}
            mapping={mapping}
            label={formatMessage({
              id: emailMappingLabels[mapping.id],
              defaultMessage: mapping.id,
            })}
            switchHeader={formatMessage({
              id: 'certs.wysiwygEmailMapping.hardcoded.switch',
              defaultMessage: 'Overwrite',
            })}
            handleInputChange={changeWysiwygEmailMappingValue}
            setIsHardCoded={() => changeHardCoded(mapping.id)}
            register={register}
            errors={errors}
            validate={validate}
            options={personQuestionIds}
            disabled={false}
          />
        ))}
      </div>
      <div className="flex flex-col w-1/2 divide-y divide-gray-200">
        <Paragraph message={messages.emailpreview} header={true} />
        <Receivers
          amountOfReceivers={amountOfReceivers}
          type="email"
          divStyle="p-4"
        />
        <EmailPreview data={data} hasErrors={allErrors.numberOfErrors > 0} />
        <EmailErrorsView errors={allErrors} />
        <EmailSend
          sendEmail={sendEmail}
          hasErrors={allErrors.numberOfErrors > 0}
        />
      </div>
      <ErrorFetching message={errorMsg} styles={errorDivStyles} />
    </div>
  );
};
