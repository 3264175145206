import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CertErrorsPreview } from '../certReducer';
import { messages } from '../translations/confirmsend';

type Props = {
  validations: CertErrorsPreview[];
};

export const ErrorsValidationList: React.FC<Props> = ({ validations }) => {
  const message =
    validations.length > 0
      ? messages.validationerrors
      : messages.novalidationerrors;

  const emoji = validations.length > 0 ? '🔥' : '✅';

  return (
    <div>
      <span className="text-xl text-brand-dark_blue py-2">
        3. <FormattedMessage {...message} /> {emoji}
      </span>
      {validations.map((v, index) => {
        const emoji = '❗';
        const textColor = 'text-red-500';
        return (
          <div className="pt-4" key={`errorsvalidation_${index}`}>
            <span
              className={`mx-2 
              ${textColor}
            `}
            >
              {emoji}
              <FormattedMessage
                id={v.message}
                values={{
                  count: v.count,
                  name: v.id,
                  bold: (name: string) => <b>{name}</b>,
                }}
              />
            </span>
          </div>
        );
      })}
    </div>
  );
};
