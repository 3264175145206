import { axiosInstance } from './interceptors';
import {
  EventAndRegistrationQuestionsState,
  RegisterObject,
  RequestHeader,
  PostLink,
  EventSettingsResponse,
  ParticipantPatchRequestData,
  EventParticipant,
  SubEventDetailsResponse,
  CreateParticipantNoLink,
  CreateParticipantRequestWithLink,
  CancelParticipantResponse,
  CreateParticipantResponse,
  DeleteParticipantResponse,
  MoveParticipantsResponse,
  EventDetailsResponse,
} from '../types/types';
import * as config from './config';
import storeService from './storage';
import { ImportTarget } from '../events/eventRegistration/components/ImportModal';
import { GetEventsPayload } from '../events/listEvents/listEventsReducer';
import { EventStatusChangePayload } from '../events/eventReducer';
import { EventStatusChangeAction } from '../actions/eventActions';

const create = async (data: { [key: string]: string | string[] }) => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/event/new`;
  return await axiosInstance({ method: 'POST', url, data, headers });
};

const getEventState = async (id: string) => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/event/createstate/${id}`;
  return await axiosInstance({ url, headers });
};

const getAll = async (obj: GetEventsPayload) => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const eventResource = obj.type === 'unarchived' ? 'events' : 'archivedevents';
  const url = `${config.baseUrl}/event/${eventResource}/${obj.date}/${obj.limit}`;
  return await axiosInstance({ url, headers });
};

export type ChangeResponseState = 'archived' | 'unarchived' | 'deleted';
export type EventStatusChangeResponse = {
  eventId: string;
  eventState: ChangeResponseState;
};
const eventChangeResource: Record<EventStatusChangeAction, string> = {
  archive: 'archiveevent',
  restore: 'dearchiveevent',
  delete: 'deleteevent',
};
const changeEventStatus = async (
  data: EventStatusChangePayload
): Promise<{ data: EventStatusChangeResponse; status: number }> => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/event/${eventChangeResource[data.type]}`;
  return await axiosInstance({ method: 'POST', url, data, headers });
};

const saveRegister = async (data: RegisterObject, goto: string) => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/register/save`;
  const result = await axiosInstance({ method: 'POST', url, data, headers });
  return {
    data: result.data,
    status: result.status,
    url: goto,
    subEventId: data.subEventId,
  };
};

const saveEventRegistrationQuestions = async (
  data: EventAndRegistrationQuestionsState
) => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/form/save`;
  return await axiosInstance({ method: 'POST', url, data, headers });
};

const getEventDetails = async (
  id: string
): Promise<{ data: EventDetailsResponse; status: number }> => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/event/details/${id}`;
  return await axiosInstance({ url, headers });
};

const getParticipants = async (
  id: string
): Promise<{ data: EventParticipant[]; status: number }> => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/event/registered/${id}`;
  return await axiosInstance({ url, headers });
};

const postFormLink = async (data: PostLink) => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/event/link`;
  return await axiosInstance({ method: 'POST', url, data, headers });
};

const getLinkSuggestion = async () => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/event/link`;
  return await axiosInstance({ url, headers });
};

const getEventsLyyti = async (locale: string) => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/ext/customerevents`;
  const result = await axiosInstance({ url, headers });
  return { data: result.data, status: result.status, locale };
};

const getParticipantsLyyti = async (id: number) => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/ext/EventParticipants/${id}`;
  return await axiosInstance({ url, headers });
};

const getLyytikey = async () => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/ext/lyytikey`;
  return await axiosInstance({ url, headers });
};

const getDbKeys = async (): Promise<{
  data: EventSettingsResponse;
  status: number;
}> => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/event/settings`;
  return await axiosInstance({ url, headers });
};

const getParticipantDetails = async (
  subEventId: string,
  uuid: string,
  eventId: string
) => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/event/registereddetails/${subEventId}/${uuid}`;
  const result = await axiosInstance({ url, headers });
  return { data: result.data, status: result.status, eventId };
};

const saveParticipantChanges = async (
  data: ParticipantPatchRequestData,
  eventId: string
) => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/event/participant`;
  const result = await axiosInstance({ method: 'PATCH', url, data, headers });
  return { data: result.data, status: result.status, eventId };
};

const getSubEventDetails = async (
  subEventId: string
): Promise<{ data: SubEventDetailsResponse; status: number }> => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/event/subevent/${subEventId}`;
  return await axiosInstance({ url, headers });
};

const getSubEventDetailsImport = async (
  subEventId: string,
  keys: Record<ImportTarget, boolean>
): Promise<{
  data: SubEventDetailsResponse;
  status: number;
  keys: Record<ImportTarget, boolean>;
}> => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/event/subevent/${subEventId}`;
  const result = await axiosInstance({ url, headers });
  return { data: result.data, status: result.status, keys };
};
const createSubEvent = async (eventId: string) => {
  const Jwt = storeService.getString('jwt');
  const data = { eventId };
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/event/subevent`;
  return await axiosInstance({ method: 'POST', url, data, headers });
};

export type DeleteSubEvent = {
  subEventId: string;
};
export type DeleteSubEventResponse = DeleteSubEvent & { eventState: 'deleted' };
const deleteSubEvent = async (
  data: DeleteSubEvent
): Promise<{ data: DeleteSubEventResponse; status: number }> => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/event/deletesubevent`;
  return await axiosInstance({ method: 'POST', url, data, headers });
};

const createParticipant = async (
  data: CreateParticipantNoLink | CreateParticipantRequestWithLink,
  eventId: string
): Promise<{
  data: CreateParticipantResponse;
  status: number;
  eventId: string;
}> => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/visitor/new`;
  const result = await axiosInstance({ method: 'POST', data, url, headers });
  return { data: result.data, status: result.status, eventId };
};

const cancelParticipant = async (
  eventId: string,
  subEventId: string,
  uuid: string
): Promise<{
  data: CancelParticipantResponse;
  status: number;
  eventId: string;
}> => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/visitor/cancel`;
  const data = {
    partypantsUuid: uuid,
    partypantsSubEventId: subEventId,
  };
  const result = await axiosInstance({ method: 'DELETE', data, url, headers });
  return { data: result.data, status: result.status, eventId };
};

const deleteParticipant = async (
  eventId: string,
  subEventId: string,
  uuid: string
): Promise<{
  data: DeleteParticipantResponse;
  status: number;
  eventId: string;
}> => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/visitor/delete`;
  const data = {
    partypantsUuid: uuid,
    partypantsSubEventId: subEventId,
  };
  const result = await axiosInstance({ method: 'DELETE', data, url, headers });
  return { data: result.data, status: result.status, eventId };
};

const moveParticipant = async (
  eventId: string,
  from: string,
  to: string,
  uuid: string,
  moveGroup: boolean
): Promise<{
  data: MoveParticipantsResponse;
  status: number;
  eventId: string;
}> => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/visitor/changesubevent`;
  const data = {
    partypantsUuid: uuid,
    subEventId: from,
    newSubEventId: to,
    moveGroup: moveGroup,
  };
  const result = await axiosInstance({ method: 'POST', data, url, headers });
  return { data: result.data, status: result.status, eventId };
};

//TODO: Extract all participant-related requests to own service-module

const eventService = {
  create,
  changeEventStatus,
  createSubEvent,
  deleteSubEvent,
  getEventState,
  getLinkSuggestion,
  saveEventRegistrationQuestions,
  getAll,
  saveRegister,
  getEventDetails,
  getParticipants,
  postFormLink,
  getEventsLyyti,
  getParticipantsLyyti,
  getLyytikey,
  getDbKeys,
  getParticipantDetails,
  saveParticipantChanges,
  createParticipant,
  getSubEventDetails,
  getSubEventDetailsImport,
  cancelParticipant,
  deleteParticipant,
  moveParticipant,
};

export default eventService;
