import React from 'react';
import BreadCrumb from '../components/breadcrumbs/BreadCrumb';
import { BreadRoute } from '../components/breadcrumbs/BreadRoute';
import { DefinedMessages } from '../types/types';
import { AccountDataCard } from './AccountDataCard';
import { UserData } from './UserData';
import { CustomerData } from './CustomerData';

export const AccountPage: React.FC = () => {
  const messages: DefinedMessages = {
    userdata: {
      id: 'account.user.header',
      defaultMessage: 'user data',
    },
    customerdata: {
      id: 'account.customer.header',
      defaultMessage: 'customer data',
    },
  };

  return (
    <div className="w-11/12 h-screen mx-auto">
      <BreadRoute>
        <BreadCrumb label={'account-menu'} link={'/account'} lastChild={true} />
      </BreadRoute>
      <div className="flex w-11/12 mx-auto py-4">
        <AccountDataCard header={messages.userdata}>
          <UserData />
        </AccountDataCard>
        <AccountDataCard header={messages.customerdata}>
          <CustomerData />
        </AccountDataCard>
      </div>
    </div>
  );
};

export default AccountPage;
