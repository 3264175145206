import React from 'react';
import * as DJS from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../actions/allActions';
import { AppState } from '../../reducers/combineReducer';
import { selectWysiwygState } from '../certReducer';
import { WysiwygType } from '../../types/types';

type Props = { type: WysiwygType };

export const WysiwygEditor: React.FC<Props> = ({ type }) => {
  const editorState = useSelector((state: AppState) =>
    selectWysiwygState(state, type)
  );

  const dispatch = useDispatch();

  const convertToHtml = (editorState: DJS.EditorState) => {
    const raw = DJS.convertToRaw(editorState.getCurrentContent());
    return draftToHtml(raw, { trigger: '#', separator: ' ' }, false);
  };

  const setWysiwygState = (editorState: DJS.EditorState) => {
    const htmlRepresentation = convertToHtml(editorState);
    dispatch(
      allActions.certActions.setWysiwygHtml({
        htmlRepresentation,
        editorState,
        type,
      })
    );
  };

  return (
    <div className="h-full w-full">
      <Editor
        editorState={editorState}
        onEditorStateChange={setWysiwygState}
        wrapperClassName="h-full overflow-hidden"
      />
    </div>
  );
};
