import React from 'react';
import { FormCheckBox } from './FormCheckBox';
import { FormRadio } from './FormRadio';
import { FormSelect } from './FormSelect';
import { FormTextArea } from './FormTextArea';
import { FormInput } from './FormInput';
import { FormInputInfo } from '../../types/types';
import { returnRequiredRule } from '../../utils/formFunctions';

type Props = {
  obj: FormInputInfo;
  register: any;
  errors: any;
  handlerFunction: (event: any, id: string, type?: string) => void;
  index: number;
  divider: boolean;
  changeAmount?: (key: string, direction: number) => void;
  values: Record<string, string | string[]>;
  formReserve?: (event: any, id?: string) => void;
  validate: (id: string) => void;
};

export const FormElement: React.FC<Props> = ({
  obj,
  register,
  errors,
  handlerFunction,
  index,
  divider,
  changeAmount,
  values,
  formReserve,
  validate,
}) => {
  const border = divider ? 'border-t-2 border-fuchsia-600 pt-8' : '';
  const val = values[obj.id];
  const value = typeof val === 'string' ? val : '';
  const valArr = Array.isArray(val) ? val : [];
  const repeatable = obj.repeatable ? true : false;
  const repeatableHeader = obj.repeatable ? obj.repeatable.header : null;
  const repeatableLast = obj.repeatable
    ? obj.repeatable.last
      ? {
          key: obj.repeatable.amount,
          last: true,
          amount: Number(values[obj.repeatable.amount]) as number,
        }
      : {
          key: obj.repeatable.amount,
          last: false,
          amount: Number(values[obj.repeatable.amount]) as number,
        }
    : { key: '', last: false, amount: 1 };
  const link = obj.link ? obj.link : false;
  const reg = obj.required
    ? returnRequiredRule(obj.required, register, values)
    : null;
  const reservation = obj.reservation ? formReserve : null;
  if (obj.type === 'dropdown') {
    return (
      <FormSelect
        question={obj.question}
        key={obj.id}
        id={obj.id}
        value={value}
        options={obj.options}
        handleInputChange={handlerFunction}
        divider={border}
        register={reg}
        errors={errors}
        link={link}
        repeatableHeader={repeatableHeader}
        repeatableKey={repeatableLast}
        cursiveLabel={obj.description || null}
        changeAmount={changeAmount}
        validate={obj.required ? validate : null}
        index={index}
      />
    );
  } else if (obj.type === 'textarea') {
    return (
      <FormTextArea
        question={obj.question}
        key={obj.id}
        id={obj.id}
        value={value}
        handleInputChange={handlerFunction}
        divider={border}
        register={reg}
        errors={errors}
        link={link}
        repeatableHeader={repeatableHeader}
        repeatableKey={repeatableLast}
        cursiveLabel={obj.description || null}
        changeAmount={changeAmount}
        validate={obj.required ? validate : null}
        index={index}
      />
    );
  } else if (obj.type === 'radio' || obj.type === 'product') {
    return (
      <FormRadio
        question={obj.question}
        key={obj.id}
        id={obj.id}
        options={obj.options}
        handleInputChange={handlerFunction}
        divider={border}
        register={reg}
        errors={errors}
        link={link}
        repeatableHeader={repeatableHeader}
        repeatableKey={repeatableLast}
        checked={value}
        cursiveLabel={obj.description || null}
        changeAmount={changeAmount}
        validate={obj.required ? validate : null}
        index={index}
      />
    );
  } else if (obj.type === 'checkbox') {
    return (
      <FormCheckBox
        question={obj.question}
        key={obj.id}
        id={obj.id}
        options={obj.options}
        handleInputChange={handlerFunction}
        divider={border}
        register={reg}
        errors={errors}
        link={link}
        repeatableHeader={repeatableHeader}
        repeatableKey={repeatableLast}
        checked={valArr}
        cursiveLabel={obj.description || null}
        changeAmount={changeAmount}
        validate={obj.required ? validate : null}
        index={index}
      />
    );
  } else if (obj.type === 'accept') {
    return (
      <FormCheckBox
        divStyle="flex flex-row-reverse justify-end items-center text-xs xl:w-11/12 xl:m-auto xl:text-base"
        question={obj.question}
        key={obj.id}
        id={obj.id}
        options={obj.options}
        handleInputChange={handlerFunction}
        divider={border}
        register={reg}
        errors={errors}
        link={link}
        repeatableHeader={repeatableHeader}
        repeatableKey={repeatableLast}
        checked={valArr}
        cursiveLabel={obj.description || null}
        changeAmount={changeAmount}
        validate={obj.required ? validate : null}
        index={index}
      />
    );
  } else {
    return (
      <FormInput
        question={obj.question}
        key={obj.id}
        id={obj.id}
        value={value}
        type={obj.type}
        handleInputChange={handlerFunction}
        divider={border}
        repeatable={repeatable}
        register={reg}
        errors={errors}
        link={link}
        repeatableHeader={repeatableHeader}
        repeatableKey={repeatableLast}
        cursiveLabel={obj.description || null}
        changeAmount={changeAmount}
        onBlur={reservation}
        validate={obj.required ? validate : null}
        index={index}
      />
    );
  }
};
