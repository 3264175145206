import { ImportTarget } from '../events/eventRegistration/components/ImportModal';
import {
  GetEventsPayload,
  SubEventImport,
} from '../events/listEvents/listEventsReducer';
import {
  CreateEventObject,
  DraggableFormItems,
  EventDetailsResponse,
  FormInputInfo,
  PostLink,
  RegisterObject,
} from '../types/types';
import { extractId } from '../utils/functions';

const changeValue = (key: string, value: string) => {
  return {
    type: 'CHANGEVALUE',
    payload: { key, value },
  };
};

const changeCreateState = (key: string, value: boolean) => {
  return {
    type: 'CHANGECREATESTATE',
    payload: { key, value },
  };
};

const changeRegistrationInfo = (
  key: string,
  value: string,
  nestedKey: string
) => {
  return {
    type: 'CHANGEREGISTRATIONINFO',
    payload: { nestedKey, key, value },
  };
};

const changePageStyling = (key: string, value: string, nestedKey: string) => {
  return {
    type: 'CHANGEPAGESTYLING',
    payload: { nestedKey, key, value },
  };
};

const setRegistrationTemplate = (
  key: 'ticketTemplates' | 'mailerTemplates',
  value: string
) => {
  return {
    type: 'SETREGISTRATIONTEMPLATE',
    payload: { key, value },
  };
};

const setMailerQuestions = (key: string, value: string) => {
  return {
    type: 'SETMAILERQUESTIONS',
    payload: { key, value },
  };
};

const updateRegistrationInfo = (key: string, value: string) => {
  return {
    type: 'UPDATEREGISTRATIONINFO',
    payload: { key, value },
  };
};

const changeRegistrationInfoDate = (key: string, value: Date) => {
  return {
    type: 'CHANGEREGISTRATIONINFODATE',
    payload: { key, value },
  };
};

const setRegistrationQuestions = (value: DraggableFormItems) => {
  return {
    type: 'SETREGISTRATIONQUESTIONS',
    payload: value,
  };
};

const getEvent = (id: string) => {
  return { type: 'START_EVENTSTATE_FETCH', payload: id };
};

export type EventStatusChangeAction = 'archive' | 'restore' | 'delete';
const changeEventStatus = (eventId: string, type: EventStatusChangeAction) => {
  return { type: 'START_CHANGE_EVENT_STATUS', payload: { eventId, type } };
};

const getAllEvents = (payload: GetEventsPayload) => {
  return { type: 'START_EVENTS_FETCH', payload };
};

const saveRegister = (data: RegisterObject) => {
  return { type: 'START_REGISTRATION_SELECT_REQUEST', payload: data };
};

const startDraggableFormFetch = (form: string, language: string) => {
  return {
    type: 'START_DEFAULT_FORM_FETCH',
    payload: { form, language },
  };
};

const createEvent = (data: CreateEventObject) => {
  return {
    type: 'START_EVENT_CREATE_REQUEST',
    payload: data,
  };
};

const startSaveRegistrationQuestions = (
  data: FormInputInfo[],
  subEventId: string,
  subEventFormId: string,
  subEventState: string
) => {
  return {
    type: 'START_SAVE_REGISTRATION_QUESTIONS_REQUEST',
    payload: { data, subEventId, subEventFormId, subEventState },
  };
};

const startGetEventDetails = (id: string) => {
  return {
    type: 'START_GET_EVENT_DETAILS_REQUEST',
    payload: id,
  };
};

const setEventDetails = (
  details: EventDetailsResponse,
  subEventId?: string
) => {
  const payload = subEventId ? { details, subEventId } : { details };
  return {
    type: 'SET_EVENT_DETAILS',
    payload: payload,
  };
};

const getEventDetails = (id: string, subEventId?: string) => {
  const payload = subEventId ? { id, subEventId } : { id };
  return {
    type: 'GET_EVENT_DETAILS',
    payload: payload,
  };
};

const startPostLinkForm = (data: PostLink) => {
  return {
    type: 'START_POST_LINK_FORM',
    payload: data,
  };
};

const startGetLinkSuggestion = () => {
  return {
    type: 'START_GET_LINK_SUGGESTION',
    payload: undefined,
  };
};

const getAllLyytiEvents = (locale: string) => {
  return {
    type: 'START_LYYTIEVENTS_FETCH',
    payload: locale,
  };
};

const getLyytiParticipants = (event: string) => {
  return {
    type: 'START_LYYTIPARTICIPANTS_FETCH',
    payload: event,
  };
};

const getSubEventDetails = (subEventId: string) => {
  return {
    type: 'START_SUBEVENT_DETAILS_FETCH',
    payload: subEventId,
  };
};

const createSubEvent = (eventId: string) => {
  return {
    type: 'START_CREATE_SUBEVENT_REQUEST',
    payload: eventId,
  };
};

const deleteSubEvent = (subEventId: string) => {
  return {
    type: 'START_DELETE_SUBEVENT_REQUEST',
    payload: subEventId,
  };
};

const fetchSubEventDataInOrder = (eventId: string, subEventId: string) => {
  return {
    type: 'FETCH_SUBEVENTDATA_IN_ORDER',
    payload: { eventId, subEventId },
  };
};

const fetchEventdataInOrder = (eventId: string) => {
  return {
    type: 'FETCH_EVENTDATA_IN_ORDER',
    payload: { eventId },
  };
};

const mapToImportTarget = (key: string): ImportTarget => {
  return key === 'perustiedot' || key === 'basic'
    ? 'basic'
    : key === 'tyylit' || key === 'styling'
    ? 'styling'
    : 'questions';
};

const importRegistrationState = (
  subEvent: string,
  subEvents: SubEventImport[],
  importTargets: string[]
) => {
  const keys = importTargets.reduce((acc, item) => {
    const key = mapToImportTarget(item);
    acc[key] = true;
    return acc;
  }, {});
  const id = extractId(subEvent);
  const sub = subEvents.find((s) => s.subEventId === id);
  const subEventId = sub ? sub.subEventId : '';
  return {
    type: 'START_IMPORT_REGISTRATIONSTATE',
    payload: { subEventId, keys },
  };
};

const getNewParticipantForm = (subEventId: string) => {
  return {
    type: 'START_NEW_PARTICIPANT_FORM_FETCH',
    payload: subEventId,
  };
};

const formActions = {
  getEvent,
  changeEventStatus,
  fetchSubEventDataInOrder,
  importRegistrationState,
  fetchEventdataInOrder,
  getAllEvents,
  saveRegister,
  createEvent,
  createSubEvent,
  deleteSubEvent,
  startDraggableFormFetch,
  startSaveRegistrationQuestions,
  startGetLinkSuggestion,
  startPostLinkForm,
  changeValue,
  changeCreateState,
  changeRegistrationInfo,
  changePageStyling,
  changeRegistrationInfoDate,
  setRegistrationQuestions,
  startGetEventDetails,
  updateRegistrationInfo,
  setEventDetails,
  getEventDetails,
  getAllLyytiEvents,
  getLyytiParticipants,
  getSubEventDetails,
  getNewParticipantForm,
  setRegistrationTemplate,
  setMailerQuestions,
};

export default formActions;
