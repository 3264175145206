import React from 'react';
import { messages } from '../translations';
import { VscChevronLeft, VscChevronRight } from 'react-icons/vsc';
import { FormattedMessage } from 'react-intl';

type Props = {
  amountOfItems: number;
  currentItem: number;
  changeItem: (step: number) => void;
};
export const InvoicePreviewButtons: React.FC<Props> = ({
  amountOfItems,
  currentItem,
  changeItem,
}) => {
  const bottomButtonStyle =
    'flex items-center text-brand-dark_blue hover:text-blue-900';
  const showPrev = currentItem > 0 ? bottomButtonStyle : 'invisible';
  const showNext =
    currentItem < amountOfItems - 1 ? bottomButtonStyle : 'invisible';

  return (
    <div className="flex justify-between pt-4">
      <button className={showPrev} onClick={() => changeItem(-1)}>
        <VscChevronLeft />
        <FormattedMessage {...messages.prevprev} />
      </button>
      <span className={bottomButtonStyle}>
        {currentItem + 1} / {amountOfItems}
      </span>
      <button className={showNext} onClick={() => changeItem(1)}>
        <FormattedMessage {...messages.prevnext} />
        <VscChevronRight />
      </button>
    </div>
  );
};
